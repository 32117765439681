import React, { useEffect, useState } from "react";
import { Box, Divider, IconButton, TextField, Typography, } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ActivateByCode } from "../../Services/AuthService";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import AuthService from "../../Services/AuthService";
import BluetoothIcon from '@mui/icons-material/Bluetooth';

const Root = styled(Box)(({ theme }) => ({
  height: "100%",
  padding: 0,

  "& .mainContainer": {
    "& .blutoothIcon": {
      position: "absolute",
      right: 0,
      top: 8,
      "& svg": {
        fontSize: 26,
        color: theme.palette.type === 'light' ? "blue" : "blue"
      }
    },
    backgroundImage: theme.palette.type === 'light' ? 'white' : `url(${window.location.href}images/background.png)`,
    backgroundSize: "cover",
    overflow: "hidden",
    backgroundPosition: "center",
    display: "flex",
    justifyContent: "center",
    height: "100vh ",

    "& .content": {
      display: "inline-flex",
      flexDirection: "column",
      gap: "22px",
      alignItems: "center",
      height: "100%",
      justifyContent: "center",
      "& .text": {
        padding: "0 5px",
        textAlign: "center",
        "& .header": {
          fontSize: 42,
          color: theme.palette.other.main,
        },
        "& .pera": {
          fontSize: 18,
          fontWeight: 600,
          color: theme.palette.other.darkgray,
        },
      },
      "& fieldset": {
        border: "none",
        outline: "none",
      },
      "& .tagField": {
        borderColor: theme.palette.success.light,
        color: theme.palette.background.black,
        maxWidth: "70%",
        borderRadius: 30,
        background: "#ffff",
        marginBottom: 0,
        border: "1px solid grey",
        padding: "4px 0px",
        WebkitAppearance: "none",
        MozAppearance: "textfield",
        margin: 0,
        "& .MuiInputBase-input ": {
          textAlign: "center",
          fontSize: 14,
          fontWeight: 600,
        },
      },

      "& .btn": {
        borderRadius: 30,
        background: theme.palette.success.light,
        borderColor: theme.palette.success.light,
        color: theme.palette.background.black,
        maxWidth: "70%",
        padding: "15px 0px",
        fontSize: 18,
        fontWeight: 600,
      },
      '& img[alt="heartpng"] ': {
        display: "flex",
        width: 105,
        animation: " heartbeat 1.2s infinite",
      },

      "@keyframes heartbeat": {
        "0%": {
          transform: "scale(1)",
        },
        "25%": {
          transform: "scale(0.9)",
        },
        "60%": {
          transform: "scale(1.1)",
        },
        "100%": {
          transform: "scale(1)",
        }
      },

    },
  },
}));

const DialogBox = styled(Dialog)(({ theme }) => ({
  "& .mainContainer": {
    "& .content": {
      "& .tagField": {
        "& .MuiInputBase-root": {
          "& .MuiInputBase-input": {
            border: "1px solid red",
            WebkitAppearance: 'none',
            margin: 0,
          },
          'MuiInputBase-input::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
          'MuiInputBase-input::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
        }
      }

    }

  },
  "& .titleBox": {
    marginTop: 10,
    padding: "0 16px 24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .css-ypiqx9-MuiDialogContent-root": {
      padding: "20px 8px",
    },
    "& .dialogTitle": {
      fontSize: 26,
      fontWeight: 500,
      padding: 0,
      marginTop: 5,
    },
  },
  "& .dialogBtn": {
    width: "80%",
    borderRadius: "40px",
    padding: "16px 14px",
    margin: "20px 0px",
    background: theme.palette.success.light,
    borderColor: theme.palette.success.light,
    color: theme.palette.background.black,
    fontSize: 18,
    fontWeight: 600,
    "&:hover": {
      background: theme.palette.success.light,
      borderColor: theme.palette.success.light,
      color: theme.palette.background.black,
    },
  },
  "& .titleBoxDialogTwo": {
    display: "flex",
    justifyContent: "end",
    padding: "4px 8px",
    "& .MuiSvgIcon-root": {
      color: theme.palette.background.black,
      fontSize: 30,
    },
  },
  "& .routeBox": {
    margin: "20px auto",
    display: "flex",
    width: "100%",
    minWidth: "80%",
    gap: 10,
    border: "1px solid #ffcccc",
    backgroundColor: theme.palette.primary.contrastText,
    padding: "10px",
    borderRadius: 10,
    justifyContent: "space-between",
    cursor: "pointer",
    "& .routeText": {
      display: "flex",
      flexDirection: "column",
    },

    "& .routetextColor": {
      fontSize: 16,
      fontWeight: 400,
      color: theme.palette.primary.dark,
    },
    "& .textColor": {
      fontSize: 16,
      fontWeight: 300,
      color: theme.palette.primary.dark,
    },
    "& .radioBtn": {
      "& .MuiFormControlLabel-root": {
        margin: 0,
        marginTop: 2,
      },
      "& .MuiSvgIcon-root": {
        color: "#ffcccc",
      },
    },
    "& .routeBoxInner": {
      display: "flex",
      gap: 18,
      alignItems: "center",
      marginLeft: 18,
    },
  },

  "& .active": {
    backgroundColor: "#edaeae",
  },

  "& .routeTextDialogTwo": {
    textAlign: "center",
    fontSize: 20,
    fontStyle: "italic",
  },
}));

export default function TokenActivation() {
  const [dialogText, setDialogText] = useState()
  const [openDialogTwo, setOpenDialogTwo] = React.useState(false);
  const [tagData, setTagData] = useState(0);
  let navigate = useNavigate();
  const hostName = window.location.hostname;
  useEffect(() => {
    console.log(hostName)

    if (hostName == "volunteer.myactivecenter.com" || hostName == "volunteers.myactivecenter.com" || hostName == "app.myseniorcenter.net" || hostName == "mow.myseniorcenter.net" || hostName == "localhost" || hostName == "qa1.xtecsoft.com") {
      navigate("/phoneverification")
    }
    console.log(hostName, "<-- hostName")
    let Token = AuthService.getAuthDataByName("token") || "";
    if (Token) {

      const lastVisitedPage = localStorage.getItem("lastVisitedPage");
      const path = lastVisitedPage.split('/').pop();
      if (path === "bluetooth") {
        navigate("/")
      } else {
        if (path === "") {
          navigate(`/ride`);
        } else {
          navigate(`/${path}`);
        }
      }
    }
    // eslint-disable-next-line
  }, [])
  // ======== Dialog 1 =======
  const handleClickOpen = async () => {
    let Token = AuthService.getAuthDataByName("token") || "";
    if (Token) {
      if (tagData <= 0) {
        setOpenDialogTwo(true);
        setDialogText("Please Enter Tag.")
      } else {
        let isValid = AuthService.isAuthenticated(tagData);
        if (isValid === "Valid") {
          setOpenDialogTwo(true);
          setDialogText("Tag is already used.");
        } else if (isValid === "notValid") {
          setOpenDialogTwo(true);
          setDialogText("Tag is not valid.");
        }
      }
    }
    else {
      if (tagData <= 0) {
        setOpenDialogTwo(true);
        setDialogText("Please Enter Tag.")
      } else {
        let ActivatedData = await ActivateByCode(tagData);
        if (ActivatedData) {
          let ActivateData = ActivatedData.data;
          AuthService.setUserData("token", tagData);
          AuthService.setUserData("gsid", ActivateData.gsid);
          AuthService.setUserData("deviceTagID", ActivateData.tagId);
          AuthService.setUserData("deviceSecret", ActivateData.deviceSecret);
          // Redirect based on hostname
          if (hostName === 'mow.myseniorcenter.net') {
            navigate("/meal")
          } else if (hostName === 'mobile.myseniorcenter.net') {
            navigate("/activties")

          }
          else if (hostName === 'qa1.xtecsoft.com') {
            navigate("/phoneverification")
          }
          else {
            navigate("/ride")
          }
        } else {
          setOpenDialogTwo(true);
          setDialogText("This tag is not valid. Please enter a valid tag.")
        }
      }
    }
  };
  // ======== Dialog  =======
  const handleCross2 = () => {
    setOpenDialogTwo(false);
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleClickOpen();
    }
  }
  const getFallbackImage = (e) => {
    console.log(e);
  }
  const goToBluetooth = () => {
    navigate(`/bluetooth`);
  }
  return (
    <Root p={2}>
      <Box className="mainContainer">
        <IconButton onClick={goToBluetooth} className="blutoothIcon">
          <BluetoothIcon />
        </IconButton>
        <Box className="content">
          <Box> <img src={`${window.location.href}images/logo.png`} alt="heartpng" onError={getFallbackImage} /> </Box>
          <Box className="text">
            <Typography className="header" variant="h2"> Welcome! </Typography>
            <Typography className="pera" variant="p"> Please enter activation code. Contact your Center. </Typography>
          </Box>
          <TextField className="tagField" type="number" onKeyDown={handleKeyDown} fullWidth autoComplete='off' id="outlined-basic" label="" onChange={(e) => { setTagData(e.target.value); }} placeholder="Enter Code" variant="outlined" />
          <Button className="btn" fullWidth variant="contained" onClick={handleClickOpen} > Activate </Button>
        </Box>
      </Box>
      {/* Dialog  */}
      <DialogBox
        open={openDialogTwo}
        onClose={handleCross2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box className="titleBoxDialogTwo">
          <IconButton onClick={handleCross2}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <DialogContent>
          <Box className="routeBoxInner">
            <Box className="routeTextDialogTwo"> <Typography variant="p"> {dialogText} </Typography> </Box>
          </Box>
        </DialogContent>
      </DialogBox>
    </Root>
  );
}
