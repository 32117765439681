import React from "react";
import rg4js from 'raygun4js';
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import * as Raygun from 'raygun4js';
// Lazy Components
import Msc from '../Pages/Msc'
import SubmitForm from '../Pages/Meal/SubmitForm'
import Home from '../Pages/Home'
import Ride from '../Pages/Rides/Ride'
import NewRide from '../Pages/Rides/newRide'
import Meal from '../Pages/Meal/Meal'
import Activities from '../Pages/Activities/Activities'
import ActivityDetails from '../Pages/Activities/ActivityDetails'
import RouteSenior from '../Pages/Meal/RouteSeniors'
import Scanner from '../Components/Scanner'
import SignatureArea from '../Pages/Meal/Signature'
import Senior from '../Pages/Meal/Senior'
import NotFound from '../Components/NotFound'
import TokenActivation from '../Pages/Access/TokenActivation'
import SelecteVehicle from '../Pages/Activation/SelectVehicle'
import SelecteDriver from '../Pages/Activation/SelectDriver'
import BluetoothScanner from '../Components/BluetoothScanner'
import Settings from '../Pages/Settings'
import ChangeProfile from '../Pages/ChangeProfile'
import Notification from '../Pages/Notifications'
import ChangeTheme from '../Pages/ChangeTheme'
import SeniorInfo from '../Pages/SeniorInfo'
import EditInfo from '../Pages/EditInfo'
import NewScanner from "../Components/NewScanner";
import SelectCenter from "../Pages/Meal/SelectCenter";
// import CheckPWA from "../Pages/CheckPWA";
import PhoneVerification from "../Pages/OtpVerification/PhoneVerification";
import OtpVerify from "../Pages/OtpVerification/OtpVerify";
import Volunteer2 from "../Pages/Volunteer/Volunteer2";
import { SeniorProfile } from "../Pages/Volunteer/SeniorProfile";
import { NoAccess } from "../Pages/NoAccess/NoAccess";
import { TokenExpired } from "../Pages/NoAccess/TokenExpired";

import Welcome from "../Pages/Welcome/Welcome";
import SelectItems from "../Pages/Welcome/SelectItems"
import Schedule from "../Pages/Welcome/ScheduleTime"
import ScheduleDays from "../Pages/Welcome/ScheduleDays"
import ReviewSchedule from "../Pages/Welcome/ReviewSchedule"

import Protected from "./Protected";
import SeniorList from "../Pages/App/SeniorList";
import SeniorDetails from "../Pages/App/SeniorDetails";
import Cache from "../Pages/Cache";
import Photos from "../Pages/App/Photos";
export default function RoutesDefinition(props) {
    const navigate = useNavigate();
    const location = useLocation();
    //Ragyun
    // const errorPayload = {
    //     type: 'manual',
    //     customData: {
    //         message: 'Pwa',
    //     },
    // };
    // rg4js('apiKey', '6E4UtRiVzMNMEQykopPWg');
    // rg4js('enableCrashReporting', true);
    // rg4js('enablePulse', true);
    // rg4js('send', errorPayload);
    // React.useEffect(function () {
    //     rg4js('trackEvent', { type: 'pageView', path: location.pathname });

    // }, [location]);

    //----------------------
    // React.useEffect(() => {
    //     // Reload on Navigiator
    //     let el = document.querySelector(".connectivity > p > a");
    //     el.addEventListener("click", () => navigate("/ride"))
    // }, [navigate]);
    React.useEffect(() => {
        if (location.pathname === "/senior-detail" || location.pathname === "/app-senior") {
            return
        } else {
            localStorage.removeItem("searchTerm")
        }
    }, [navigate]);
    React.useEffect(() => {
        localStorage.setItem("lastVisitedPage", window.location.href)
        if (location.pathname === "/verfiybytoken") {
            const isButtonVisiblemenu = location.pathname === "/verfiybytoken";
            props.handlestateFromChildMenu(isButtonVisiblemenu)
        } else if (location.pathname === "/theme") {
            const isButtonVisiblemenu = location.pathname === "/theme";
            props.handlestateFromChildMenu(isButtonVisiblemenu)
        }
        else if (location.pathname === "/edit") {
            const isButtonVisiblemenu = location.pathname === "/edit";
            props.handlestateFromChildMenu(isButtonVisiblemenu)
        }
        else if (location.pathname === "/notify") {
            const isButtonVisiblemenu = location.pathname === "/notify";
            props.handlestateFromChildMenu(isButtonVisiblemenu)
        }
        else if (location.pathname === "/phoneverification") {
            const isButtonVisiblemenu = location.pathname === "/phoneverification";
            props.handlestateFromChildMenu(isButtonVisiblemenu)
        }
        else if (location.pathname === "/") {
            const isButtonVisiblemenu = location.pathname === "/";
            props.handlestateFromChildMenu(isButtonVisiblemenu)
        }
        else if (location.pathname === "/otpverify") {
            const isButtonVisiblemenu = location.pathname === "/otpverify";
            props.handlestateFromChildMenu(isButtonVisiblemenu)
        }

        else if (location.pathname === "/noaccess") {
            const isButtonVisiblemenu = location.pathname === "/noaccess";
            props.handlestateFromChildMenu(isButtonVisiblemenu)
        }
        else if (location.pathname === "/token-expired") {
            const isButtonVisiblemenu = location.pathname === "/token-expired";
            props.handlestateFromChildMenu(isButtonVisiblemenu)
        }

        else {
            props.handlestateFromChildMenu(false)
        }
    }, [location])
    React.useEffect(() => {
        localStorage.setItem("lastVisitedPage", window.location.href)
    }, [location])

    return (
        <>
            {/* not showing /home /msc /nrfachanbaltee /edit*/}
            <Routes>
                <Route index path="/verfiybytoken" element={<TokenActivation />} />
                <Route index path="/" element={<PhoneVerification />} />
                <Route exact path="/otpverify" element={<OtpVerify />} />

                <Route exact path="/activties" element={<Protected mode="app" name="Activities"><Activities /></Protected>} />
                <Route exact path="/activity-details" element={<Protected mode="app" name="Activity Details"><ActivityDetails /></Protected>} />
                <Route exact path="/driver" element={<Protected mode="driver" name="Driver"><SelecteDriver /></Protected>} />
                <Route exact path="/photos" element={<Protected mode="app" name="Photos"><Photos /></Protected>} />
                <Route exact path="/home" element={<Home />} />

                <Route exact path="/msc" element={<Msc />} />
                <Route exact path="/ride" element={<Protected mode="driver" name="Ride"><Ride drawer={props.rightDrawer} /></Protected>} />

                {/* meal welcome routes */}
                <Route index path="/welcome" element={<Welcome />} />
                <Route index path="/item" element={<SelectItems />} />
                <Route index path="/time" element={<Schedule />} />
                <Route index path="/days" element={<ScheduleDays />} />
                <Route index path="/review" element={<ReviewSchedule />} />
                {/* meal welcome routes */}

                {/* <Route exact path="/select-center" element={<Protected mode="meals" name="Senior" ><SelectCenter /></Protected>} /> */}
                <Route exact path="/select-center" element={<SelectCenter />} />
                <Route exact path="/cache" element={<Cache />} />
                <Route exact path="/routesenior" element={<Protected mode="meals" name="Senior" ><RouteSenior /></Protected>} />
                <Route exact path="/meal" element={<Protected mode="meals" name="Meal"><Meal /></Protected>} />
                <Route exact path="/senior" element={<Protected mode="meals" name="Senior"><Senior /></Protected>} />
                <Route exact path="/signature" element={<Protected mode="meals" name="Signature"><SignatureArea /></Protected>} />
                <Route exact path="/submitform" element={<Protected mode="meals" name="Submit Form"><SubmitForm /></Protected>} />
                <Route exact path="/info" element={<Protected mode="meals" name="Senior Info"><SeniorInfo /></Protected>} />
                {/* <Route exact path="/ride" element={<NewRide drawer={props.rightDrawer} />} /> */}

                <Route exact path="/app-senior" element={<Protected mode="app" name="Senior List"><SeniorList /></Protected>} />
                <Route exact path="/senior-detail" element={<Protected mode="app" name="Senoir Detail"><SeniorDetails /></Protected>} />

                <Route exact path="/nrfachanbaltee" element={<NewRide drawer={props.rightDrawer} />} />
                <Route exact path="/scanner" element={<Scanner />} />
                <Route exact path="/vehicle" element={<Protected mode="driver" name="Vehicle"><SelecteVehicle /></Protected>} />
                <Route exact path="/bluetooth" element={<BluetoothScanner />} />
                <Route exact path="/theme" element={<ChangeTheme handleTheme={props.handleTheme} isDarkMode={props.isDarkMode} />} />
                <Route exact path="/settings" element={<Settings />} />
                <Route exact path="/changeprofile" element={<ChangeProfile />} />
                <Route exact path="/notify" element={<Notification />} />
                <Route exact path="/edit" element={<EditInfo />} />
                <Route exact path="/newscan" element={<Protected mode="app" name="Scanner"><NewScanner /></Protected>} />
                {/* <Route exact path="/phoneverification" element={<PhoneVerification />} /> */}
                <Route exact path="/volunteer" element={<Protected mode="volunteer" name="Volunteer"><Volunteer2 /></Protected>} />
                <Route exact path="/seniorprofile" element={<Protected mode="volunteer" name="Senior Profile"><SeniorProfile /></Protected>} />
                <Route exact path="/noaccess" element={<NoAccess />} />
                <Route exact path="/token-expired" element={<TokenExpired />} />
                {/* <Route exact path="/check" element={<CheckPWA />} /> */}
                <Route path='*' element={<NotFound />}></Route>
            </Routes>
            <div id="check-render"></div>
        </>
    );
}

