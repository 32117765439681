import { GetRequest, PostFormsRequest, PostRequest } from "./Apibase";
import { SyncApplicationWithServer } from "./RidesServices";

// Basic Reload
var isOnline = window.navigator.onLine
isOnline = true
window.addEventListener('online', async () => {
    isOnline = true;
    await SyncApplicationWithServer();
});
window.addEventListener('offline', () => {
    isOnline = false
});

let GetSeniorsBySite = async () => {
    if (isOnline) {
        let response = await PostRequest(`/api/mobile/seniors/GetSeniorsBySite`, null, true, false, true);
        if (response.success)
            return response;
    } else {
        console.log("Fail to getSeniorsBySite")
    }
}
let getSeniorDetailByID = async (id) => {
    if (isOnline) {
        let response = await PostRequest(`/api/mobile/seniors/GetSeniorByID?seniorID=${id}`, null, true, false, true);
        if (response.success)
            return response;
    } else {
        console.log("Fail to GetSeniorByID")
    }
}
let GetAllKeytags = async () => {
    if (isOnline) {
        let response = await PostRequest(`/api/mobile/seniors/GetAllKeytags`, null, true, false, true);
        if (response.success)
            return response;
    } else {
        console.log("Fail to GetAllKeytags")
    }
}
let GetSeniorByKeytag = async (id) => {
    if (isOnline) {
        let response = await PostRequest(`/api/mobile/seniors/GetSeniorByKeytag?keytag=${id}`, null, true, false, true);
        if (response.success)
            return response;
    } else {
        console.log("Fail to GetSeniorByKeytag")
    }
}
let GetActivitiesByDay = async (date) => {
    if (isOnline) {
        let response = await PostRequest(`/api/mobile/events/GetActivitiesByDay?date=${date}`, null, true, false, true);
        if (response.success)
            return response;
    } else {
        console.log("Fail to GetActivitiesByDay")
    }
}
let RegisterSenior = async (data) => {
    if (isOnline) {
        let response = await PostRequest(`/api/mobile/events/RegisterSenior?seniorID=${data.seniorID}&eventID=${data.eventID}&seatID=${data.seatID}`, data, true, false, true);
        if (response.success)
            return response;
    } else {
        console.log("Fail to RegisterSenior")
    }
}
let SearchActivities = async (data) => {
    if (isOnline) {
        let response = await PostRequest(`/api/mobile/events/SearchActivities?search=${data.search}`, null, true, false, true);
        if (response.success)
            return response;
    } else {
        console.log("Fail to SearchActivities")
    }
}
let GetRoster = async (eventID) => {
    if (isOnline) {
        let response = await GetRequest(`/api/mobile/events/GetRoster?eventID=${eventID}`, true, true, true,);
        if (response.success)
            return response;
    } else {
        console.log("Fail to GetRoster")
    }
}
let AddCard = async (data) => {
    if (isOnline) {
        let response = await PostRequest(`/api/mobile/seniors/AddCard`, data, true, false, true);
        if (response.success)
            return response;
    } else {
        console.log("Fail to AddCard")
    }
}
export {
    GetSeniorsBySite,
    getSeniorDetailByID,
    GetSeniorByKeytag,
    GetAllKeytags,
    GetActivitiesByDay,
    RegisterSenior,
    SearchActivities,
    GetRoster,
    AddCard
}
