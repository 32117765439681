import React, { useEffect, useState } from 'react';
import { AppBar, Box, Button, FormControl, Typography, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
const Root = styled(Box)(({ theme }) => ({
    height: '100%',
    padding: 0,

    '& .header-cont': {
        // width: "100%",
        width: "device-width",
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        "& .MuiToolbar-root": {
            padding: "0px 15px 0px 45px"
        },
        "& header": {
            display: "flex",
            height: 48,
            background: theme.palette.primary.contrastText,
            fontSize: 12,
            alignItems: "center",
            zIndex: "99",
            '& img[alt="Logo"]': {
                width: 34,
                filter: 'invert(1)',
            },
            "& .headerNav": {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                "& .pageMainTitle": {
                    fontSize: 15,
                    fontWeight: 600,
                    whiteSpace: "nowrap"
                },
                "& .headerText": {
                    display: "flex",
                    color: '#1f0757',
                    fontSize: 13,
                    textShadow: '#1f0757',
                    fontWeight: 600,
                    whiteSpace: "nowrap"
                },
                "& .refreshingIcon": {
                    color: "Grey"
                },
                "& .reloadBtn": {
                    padding: "0px 0px 4px 0px"
                }

            },

        },
    },
    '& .mainContainer': {
        backgroundImage: `url(${require('../../Assets/loginBackgroundImg/img1.png')})`,
        backgroundSize: "cover",
        overflow: "hidden",
        backgroundPosition: "center",
        height: "calc(100vh - 48px)",
        marginTop: 48,
        overflowY: "auto",
        "& .routeBoxDays": {
            margin: "20px auto",
            display: "flex",
            width: "95%",
            minWidth: "80%",
            gap: 8,
            border: "none",
            backgroundColor: theme.palette.primary.contrastText,
            padding: "14px 10px",
            borderRadius: 10,
            flexDirection: "column",
            justifyContent: "space-between",
            // alignItems: "center",
            cursor: "pointer",
            boxShadow: "1px 2px 7px 2px #cecccc",
            "& .routeText": {
                display: "flex",
                flexDirection: "column",
            },

            "& .selectroutetextColor": {
                fontSize: 20,
                fontWeight: 600,
                color: "#00807c",
            },
            "& .routetextColor": {
                fontSize: 20,
                fontWeight: 400,
                color: "#757575",
            },

            "& .selectRadioColor": {
                "& .MuiFormControlLabel-root": {
                    margin: 0,
                    marginTop: 2,
                },
                "& .MuiSvgIcon-root": {
                    color: "#00807c",
                },

            },
            "& .radioBtn": {
                "& .MuiFormControlLabel-root": {
                    margin: 0,
                    marginTop: 2,
                },
                "& .MuiSvgIcon-root": {
                    color: "#00807c",
                },
            },
            "& .routeBoxInner": {
                display: "flex",
                gap: 12,
                alignItems: "center",
                marginLeft: 0,
            },
            "& .timeBox": {
                // border: "1px solid #00807c",
                padding: "6px",
                borderRadius: "5px",
                display: "inline-block",
                marginRight: 8,
                "& .MuiFormControlLabel-root": {
                    marginRight: 0,
                    "& .MuiButtonBase-root": {
                        padding: "0px 0px 0px 3px!important",
                    },
                },
            },
            "& .selectedtimeBox": {
                // border: "1px solid #00807c",
                padding: "6px",
                borderRadius: "5px",
                display: "inline-block",
                marginRight: 8,
                // backgroundColor: "#00807c",
                color: "#00807c",
                "& .MuiFormControlLabel-root": {
                    marginRight: 0,
                    "& .MuiButtonBase-root": {
                        padding: "0px 0px 0px 3px!important",
                        "& .MuiSvgIcon-root": {
                            color: "#00807c",
                        },
                    },
                },
            },

        },

        "& .selectedtimeBox2": {
            width: "95%",
            minWidth: "80%",
            margin: "auto",
            // margin: "10px",
            "& .MuiFormControlLabel-root": {
                marginRight: 0,
                "& .MuiButtonBase-root": {
                    padding: "4px 8px 4px 8px!important",
                    "& .MuiSvgIcon-root": {
                        color: "#00807c",
                    },
                },
            },
        },
        "& .nextBtn": {
            width: "80%",
            borderRadius: "40px",
            padding: "16px 14px",
            margin: "20px 0px",
            background: theme.palette.button.main,
            borderColor: theme.palette.button.main,
            color: theme.palette.background.black,
            fontSize: 18,
            fontWeight: 600,
            "&:hover": {
                background: theme.palette.button.main,
                borderColor: theme.palette.button.main,
                color: theme.palette.background.black,
            },
        },
        "& .routeBox": {
            margin: "20px auto",
            display: "flex",
            width: "95%",
            minWidth: "80%",
            gap: 8,
            border: "none",
            backgroundColor: theme.palette.primary.contrastText,
            padding: "14px 10px",
            borderRadius: 10,
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
            boxShadow: "1px 2px 7px 2px #cecccc",
            "& .routeText": {
                display: "flex",
                flexDirection: "column",
            },

            "& .routetextColor": {
                fontSize: 20,
                fontWeight: 600,
                color: "#00807c",
            },

            "& .selectRadioColor": {
                "& .MuiFormControlLabel-root": {
                    margin: 0,
                    marginTop: 2,
                },
                "& .MuiSvgIcon-root": {
                    color: "#00807c",
                },

            },
            "& .radioBtn": {
                "& .MuiFormControlLabel-root": {
                    margin: 0,
                    marginTop: 2,
                },
                "& .MuiSvgIcon-root": {
                    color: "#00807c",
                },
            },
            "& .routeBoxInner": {
                display: "flex",
                gap: 12,
                alignItems: "center",
                marginLeft: 0,
            },
        },

    },
    '& img[alt="heartpng"]': {
        width: 50,
    },
}));

function ReviewSchedule() {
    const [selectedItems, setSelectedItems] = React.useState(null);
    const [state, setState] = useState([]);
    const navigate = useNavigate()
    useEffect(() => {
        let selectedItems = JSON.parse(localStorage.getItem("selectedItems"));
        let Days = JSON.parse(localStorage.getItem("FinalSchedule"));
        if (selectedItems) {
            setSelectedItems(selectedItems);
        }
        if (Days) {
            let filteredDays = Days?.filter(e => e.Selected === true)
            setState(filteredDays)
        }
    }, [])
    //
    const handleNext = () => {
        navigate('/meal')
    }
    return (
        <Root p={2} >
            <Box className="header-cont">
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static">
                        <Toolbar sx={{ width: "100%", maxWidth: "100%", }}>
                            <Box className="headerNav">
                                <Box
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2, display: "inline-flex", alignItems: "center" }}
                                >
                                    {/* {logo ?
                                        <img src={logo} alt="Logo" style={{ marginRight: 10 }} />
                                        : */}
                                    <img src={require('../../Assets/icons/msc_heart.png')} alt="Logo" style={{ marginRight: 10 }} />
                                    {/* } */}
                                    <Box>
                                        <Typography color="black" className="pageMainTitle" id="pageTitle">
                                            {/* {appName} */}
                                            My Senior Center
                                        </Typography>
                                        <Typography variant="h6" component="span" className="headerText">Volunteer Items </Typography>
                                    </Box>
                                </Box>
                                {/* <IconButton className="reloadBtn" onClick={() => GetRideData()} >
                                    {responceRide ? <CircularProgress size={20} className='refreshingIcon' /> : <RefreshIcon size={20} />}
                                </IconButton> */}
                            </Box>
                        </Toolbar>
                    </AppBar>
                </Box>
            </Box>
            <Box className="mainContainer">
                <Box sx={{ height: "calc(100vh - 153px)", overflowY: "auto" }}>
                    <Box>
                        <Typography variant='subtitle1' sx={{
                            width: "95%",
                            minWidth: "80%",
                            margin: "auto",
                            marginTop: "14px"
                        }}>Volunteer Items</Typography>
                    </Box>
                    {selectedItems?.length === 0 ? (
                        <Box sx={{ height: 50, textAlign: "center" }}>
                            <Typography variant="subtitle1" sx={{ marginTop: 4 }}> No Data Found </Typography>
                        </Box>
                    ) : (
                        selectedItems?.map((e, i) => (
                            <Box
                                className={`routeBox`} key={i} onClick={() => setSelectedItems(e)}
                            >
                                <Box className="routeBoxInner">
                                    <Box className="image"> <img width={50} src={require("../../Assets/images/driver-icon.png")} alt="heartpng" /> </Box>
                                    <Box className="routeText">
                                        <Typography className="routetextColor" variant="h1"> {e} </Typography>
                                    </Box>
                                </Box>
                                <Box className={`${true ? " selectRadioColor" : 'radioBtn'} `}>
                                    <FormControl sx={{ marginBottom: 0 }}>
                                        <Checkbox checked={true} />

                                    </FormControl>
                                </Box>
                            </Box>
                        ))
                    )}
                    <Typography variant='subtitle1' sx={{
                        width: "95%",
                        minWidth: "80%",
                        margin: "auto",
                        marginTop: "14px"
                    }}> Days available & Best time of Day</Typography>
                    {
                        state?.length > 0 ?
                            state?.map((e, i) => (
                                <Box className="routeBoxDays" key={i} >
                                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                        <Box className="routeBoxInner">
                                            {/* <Box className="image"> <img width={50} src={require("../../Assets/images/driver-icon.png")} alt="heartpng" /> </Box> */}
                                            <Box className="routeText">
                                                <Typography className={e.Selected ? "selectroutetextColor" : "routetextColor"} variant="h1"> {e.Day} </Typography>
                                            </Box>
                                        </Box>
                                        <Box className={e.Selected ? "selectRadioColor" : "radioBtn"}>
                                            <FormControl sx={{ marginBottom: 0 }}>
                                                <Checkbox checked={e.Selected} />
                                            </FormControl>
                                        </Box>
                                    </Box>
                                    {e.Selected && (
                                        <Box >
                                            <Typography variant="subtitle2">Best time of Day</Typography>
                                            {e.Option?.map((ele, ind) => (
                                                <Box key={ind} className={ele.Selected ? "selectedtimeBox" : "timeBox"}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={ele.Selected}

                                                            />
                                                        }
                                                        label={<Typography variant="body2">{ele.Name}</Typography>}
                                                    />
                                                </Box>
                                            ))}
                                        </Box>
                                    )}

                                </Box>
                            ))
                            :
                            <Box sx={{ height: 50, textAlign: "center" }}>
                                <Typography variant="subtitle1" sx={{ marginTop: 4 }}> No Data Found </Typography>
                            </Box>
                    }
                    <Box className="selectedtimeBox2">
                        <FormControlLabel control={<Checkbox checked={true} />} label={<Typography variant='subtitle2'>  Do you agree? <a style={{ color: "#000" }} href=''>With Terms & Conditions</a> .</Typography>} />
                    </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button className="nextBtn" onClick={handleNext} > Next </Button>
                </Box>
            </Box>
        </Root >
    )
}

export default ReviewSchedule