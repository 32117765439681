import { Button, Typography } from '@mui/material'
import { Box, styled } from '@mui/system'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import constant from '../../Shell/Constant';
import AuthService from '../../Services/AuthService';

const Root = styled(Box)(({ theme }) => ({
    height: "100vh",
    backgroundImage: `url(${require('../../Assets/loginBackgroundImg/img1.png')})`,
    backgroundSize: "cover",
    overflow: "hidden",
    backgroundPosition: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "10px 20px",
    "& .backIcon": {
        position: "absolute",
        top: "15px",
        left: "20px"
    }

}))
export const TokenExpired = () => {
    const navigate = useNavigate();
    const host = window.location.hostname;
    const handleReconnect = () => {
        AuthService.signOut();
    }
    return (
        <>
            <Root>
                <img src={require("../../Assets/images/tokenExpired.png")} alt="" />
                <Typography textAlign={'center'} variant='subtitle1' mb={2}>Your Token Has Expired</Typography>
                <Button onClick={handleReconnect} variant='contained' sx={{ borderRadius: "50px", width: "250px", backgroundColor: "#4CAF50", "&:hover": { backgroundColor: "#4CAF50" } }}>Reconnect</Button>
            </Root>
        </>
    )
}
