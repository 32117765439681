import React, { useEffect } from "react";
import { Box, Typography, } from "@mui/material";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import AuthService, { GetAppName } from "../Services/AuthService";

const Root = styled(Box)(({ theme }) => ({
  overflow: "hidden",
  height: "device-height",
  viewportFit: "cover",
  '& .header-cont': {
    // width: "100%",
    width: "device-width",
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    "& header": {
      display: "flex",
      height: 48,
      background: "white",
      fontSize: 12,
      alignItems: "center",
      '& img[alt="Logo"]': {
        width: 34,
        filter: 'invert(1)',
      },
      "& .headerNav": {
        marginRight: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        "& .fuel": {
          color: "red",
          position: "absolute",
          width: "26px",
          right: "62px",
          top: "12px",
          cursor: "pointer"
        },
        "& .pageMainTitle": {
          fontSize: 15,
          fontWeight: 600,
          whiteSpace: "nowrap"
        },
        "& .headerText": {
          display: "flex",
          color: '#1f0757',
          fontSize: 13,
          textShadow: '#1f0757',
          fontWeight: 600,
          whiteSpace: "nowrap"
        }
      }
    },
  },

  '& .footer-cont': {
    width: "100%",
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    "& .footer": {
      display: "flex",
      justifyContent: "space-around",
      "& .active": {
        backgroundColor: "#f8e9f7",
        borderRadius: 10,

      },
      "& .pwa-nav-link": {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        fontSize: 12,
        textAlign: "center",
        fontWeight: 500,
        textTransform: "capitalize",
        lineHeight: 1,
        textDecoration: "none",
        color: "black",
        flex: 1,
        "& img": {
          width: 26
        }
      }
    },
  },




}));
export default function DefaultLayout(props) {
  // const [value, setValue] = React.useState(0);
  const [pathName, setPathName] = React.useState(0);
  const [appName, setAppName] = React.useState('');
  const [logo, setLogo] = React.useState('');
  // const navigationBottom = [
  //   { name: "MSC", href: "/msc", src: require("../Assets/images/heart-icon.png") },
  //   { name: "Home", href: "/home", src: require("../Assets/images/home-icon.png") },
  //   { name: "Meals", href: "/meal", src: require("../Assets/images/meal-icon.png") },
  //   { name: "Rides", href: "/ride", src: require("../Assets/images/ride-icon.png") },
  //   { name: "Activities", href: "/activties", src: require("../Assets/images/activities-icon.png") },
  //   { name: "Seniors", href: "/senior", src: require("../Assets/images/senior-icon.png") },
  // ];
  let location = useLocation();
  let pathnames = location.pathname;
  useEffect(() => {
    let tagid = AuthService.getAuthDataByName("deviceTagID")
    if (appName === "My Senior Center" && tagid !== null) {
      GetApplicationName(tagid);
    }
    HandleNavigation();
    // Check for Safari

    setLogo(AuthService.getAppDataByName("Logo"));
    let applicationName = AuthService.getAppDataByName("AppName");
    if (applicationName === null || applicationName === "") {
      setAppName("My Senior Center");

    } else {
      setAppName(AuthService.getAppDataByName("AppName"));

    }
  }, [appName])
  const GetApplicationName = async (id) => {
    await GetAppName(id)
      .then((res) => {
        let data = res.data
        if (Object.keys(data).length && data !== null) {
          AuthService.setAppData("AppName", res.data.Name)
          AuthService.setAppData("Logo", res.data.Logo)
        } else {
          AuthService.setAppData("AppName", "My Senior center")
        }
      })
      .catch((err) => {
        console.log(err)
        AuthService.setAppData("AppName", "My Senior center")
      })
  }
  setTimeout(() => {
    setLogo(AuthService.getAppDataByName("Logo"));
    setAppName(AuthService.getAppDataByName("AppName"));
  }, 1000)
  const HandleNavigation = () => {
    if (pathnames === "/msc") {
      setPathName("MSC")
    }
    else if (pathnames === "/") {
      setPathName("Home")
    }
    else if (pathnames === "/home") {
      setPathName("Home")
    }
    else if (pathnames === "/meal") {
      setPathName("Meals-Routes")
    }
    else if (pathnames === "/mealdetail") {
      setPathName("Meals")
    }
    else if (pathnames === "/ride") {
      setPathName("Rides")
    }
    else if (pathnames === "/driver") {
      setPathName("Rides>Select Driver")
    }
    else if (pathnames === "/vehicle") {
      setPathName("Rides>Select Vehicle")
    }
    else if (pathnames === "/signature") {
      setPathName("Signature")
    }
    else if (pathnames === "/submitform") {
      setPathName("Meals")
    }
    else if (pathnames === "/setting") {
      setPathName("Setting")
    }
    else if (pathnames === "/activties" || pathnames === "/bingo") {
      setPathName("Activities")
    }
    else if (pathnames === "/scanner") {
      setPathName("Scanner")
    }
    else if (pathnames === "/bluetooth") {
      setPathName("Bluetooth")
    }
    else if (pathnames === "/volunteer") {
      setPathName("Volunteer")
    }
    else if (pathnames === "/info" || pathnames === "/edit") {
      setPathName("Senior Information")
    }
    else {
      setPathName("Home")
    }
  }


  return (
    <Root>
      {/* Header */}
      <Box className="header-cont">
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static">
            <Toolbar sx={{ width: "100%", maxWidth: "100%", padding: "0px 15px 0px 45px !important" }}>
              <Box className="headerNav">

                <Box
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2, display: "inline-flex", alignItems: "center" }}>
                  {logo ? <img src={logo} alt="Logo" style={{ marginRight: 10 }} />
                    :
                    <img src={require('../Assets/icons/msc_heart.png')} alt="Logo" style={{ marginRight: 10 }} />}
                  <div>
                    {appName ? <Typography color="black" className="pageMainTitle" id="pageTitle">
                      {appName}
                    </Typography> : <Typography color="black" className="pageMainTitle" id="pageTitle">
                      My Senior Center
                    </Typography>}

                    {pathName === "Senior" ? <><Typography variant="h6" component="div" className="headerText">Senior </Typography></> : <Typography className="headerText">{pathName}</Typography>}
                  </div>
                </Box>


              </Box>
            </Toolbar>
          </AppBar>
        </Box>
      </Box>
      {/* Body */}
      {props.children}
      {/* footer */}
      {/* <Box className="footer-cont">
        <BottomNavigation
          className="footer"
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          style={{ paddingBottom: isSafari ? 15 : 0, height: isSafari ? 70 : 56 }}
        >
          {navigationBottom.map((item) => (
            <NavLink
              key={item.name}
              to={item.href}
              className={`pwa-nav-link ${(location.pathname.includes("routesenior") && item.name === "Meals") || (location.pathname.includes("signature") && item.name === "Meals") || (location.pathname.includes("submitform") && item.name === "Meals") || (location.pathname.includes("bingo") && item.name === "Activities") || (location.pathname.includes("scanner") && item.name === "Activities") || (location.pathname.includes("vehicle") && item.name === "Rides") || (location.pathname.includes("driver") && item.name === "Rides") ? "active" : ""}`}
            >
              <img src={item.src} alt="heart" />
              <span> {item.name}</span>

            </NavLink>
          ))}
        </BottomNavigation>
      </Box> */}

    </Root>
  );
}
