import AuthService from "../Services/AuthService";

const getElement = (item) => {
    let el = document.querySelector(item);
    return el;
}
const Helpers = {
    ApplyFixedScroll() {
        let targetElement = document.querySelector(".MuiDataGrid-virtualScroller")
        if (targetElement) {
            let fixedElements = getElement("header").clientHeight + getElement(".MuiDataGrid-columnHeaders").clientHeight + getElement(".MuiDataGrid-footerContainer").clientHeight + 20;
            let fixedSections = document.querySelectorAll("[data-fixed-section]");
            // Count Fixed Heights
            fixedSections.forEach(element => {
                fixedElements = fixedElements + element.clientHeight
            });
            targetElement.style.height = `calc(100vh - ${fixedElements}px)`;
            targetElement.style.overflowY = "auto";
        }
    },
    // calculate estimated time between two locations
    EstimateDrivingTime(lat1, lon1, lat2, lon2, averageSpeed) {
        // Function to calculate the Haversine distance between two points
        function haversineDistance(lat1, lon1, lat2, lon2) {
            const R = 6371; // Radius of the Earth in kilometers
            const dLat = degreesToRadians(lat2 - lat1);
            const dLon = degreesToRadians(lon2 - lon1);
            const a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(degreesToRadians(lat1)) * Math.cos(degreesToRadians(lat2)) *
                Math.sin(dLon / 2) * Math.sin(dLon / 2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            const distance = R * c; // Distance in kilometers
            return distance;
        }

        // Function to convert degrees to radians
        function degreesToRadians(degrees) {
            return degrees * (Math.PI / 180);
        }

        // Calculate the Haversine distance
        const distance = haversineDistance(lat1, lon1, lat2, lon2);

        // Calculate the estimated driving time
        const time = distance / averageSpeed; // Time in hours

        // Return the time in hours and minutes
        const timeInHours = Math.floor(time);
        const timeInMinutes = Math.round((time - timeInHours) * 60);

        return {
            hours: timeInHours,
            minutes: timeInMinutes
        };
    },
    // grouped data by date
    GroupByDate(data) {
        return data.reduce((acc, item) => {
            const date = item.time.split('T')[0];
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(item);
            return acc;
        }, {});
    },
    convertTo24HourFormat(time) {
        const [timePart, modifier] = time?.split(' ');
        let [hours, minutes] = timePart?.split(':');
        hours = parseInt(hours, 10);

        if (modifier === 'PM' && hours !== 12) {
            hours += 12;
        }
        if (modifier === 'AM' && hours === 12) {
            hours = 0;
        }

        return new Date(`1970-01-01T${String(hours).padStart(2, '0')}:${minutes}:00`);
    },
    // grouped data by date api
    // DataGroupByDate(data) {
    //     return data.reduce((acc, item) => {
    //         const date = item?.metaData?.RequestDate.split('T')[0];
    //         if (!acc[date]) {
    //             acc[date] = [];
    //         }
    //         acc[date].push(item);
    //         return acc;
    //     }, {});
    // },
    DataGroupByDate(data) {
        return data.reduce((acc, item) => {
            const date = item?.metaData?.RequestDate?.split('T')[0];
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(item);

            // Sort the array by PickupTime after adding a new item
            acc[date].sort((a, b) => {
                const timeA = this.convertTo24HourFormat(a.metaData?.PickupTime);
                const timeB = this.convertTo24HourFormat(b.metaData?.PickupTime);
                return timeA - timeB;
            });

            return acc;
        }, {});
    },

    SortObjectByDateKeys(obj, order) {
        const sortOrder = order ? 1 : -1;

        // Extract the keys and sort them
        const sortedKeys = Object.keys(obj).sort((a, b) => {
            return sortOrder * (new Date(a) - new Date(b));
        });

        // Create a new object with sorted keys
        const sortedObj = {};
        sortedKeys.forEach(key => {
            sortedObj[key] = obj[key];
        });

        return sortedObj;
    },
    sortGroupedDataByStatus(groupedData) {
        for (const date in groupedData) {
            groupedData[date].sort((a, b) => {
                if (a.status === 'accepted' && b.status !== 'accepted') {
                    return -1;
                }
                if (a.status !== 'accepted' && b.status === 'accepted') {
                    return 1;
                }
                return 0;
            });
        }
        return groupedData;
    },

    DynamicSort(array, field, order) {
        const sortOrder = order === 'desc' ? -1 : 1;

        array.sort((a, b) => {
            let valueA = a[field];
            let valueB = b[field];

            // Handle cases where the field may be null or undefined
            if (valueA === null || valueA === undefined) valueA = '';
            if (valueB === null || valueB === undefined) valueB = '';

            // Handle numeric or string comparison based on type
            if (typeof valueA === 'number' && typeof valueB === 'number') {
                return sortOrder * (valueA - valueB);
            } else {
                return sortOrder * valueA.toString().localeCompare(valueB.toString());
            }
        });

        return array;
    },
    FormatDate(date) {
        const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        const dayOfWeek = daysOfWeek[date.getDay()];
        const month = months[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();

        // Function to get the ordinal suffix for a day
        function getOrdinalSuffix(day) {
            if (day > 3 && day < 21) return 'th'; // Covers 11th, 12th, 13th, etc.
            switch (day % 10) {
                case 1: return 'st';
                case 2: return 'nd';
                case 3: return 'rd';
                default: return 'th';
            }
        }

        const dayWithSuffix = day + getOrdinalSuffix(day);

        return `${dayOfWeek}, ${month} ${dayWithSuffix}, ${year}`;
    },
    FormatToInternational(formattedNumber) {
        const numericValue = formattedNumber.replace(/\D/g, '');

        const internationalValue = `${numericValue}`;
        return internationalValue;
    },
    FormatToInternationalTest(formattedNumber) {
        const numericValue = formattedNumber.replace(/\D/g, '');

        const internationalValue = `+1${numericValue}`;
        return internationalValue;
    },
    DateFormator(date) {
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    },
    filterVolunteerData(data, searchTerm) {
        if (!searchTerm) return data;
        return data.filter(item =>
            item.SeniorName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.title.toLowerCase().includes(searchTerm.toLowerCase())
        );
    },
    isToday(date) {
        const today = new Date();
        return (
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()
        );
    },
    isTomorrow(date) {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        return (
            date.getDate() === tomorrow.getDate() &&
            date.getMonth() === tomorrow.getMonth() &&
            date.getFullYear() === tomorrow.getFullYear()
        );
    },
    isNextWeek(date) {
        const today = new Date();
        const nextWeek = new Date();
        nextWeek.setDate(today.getDate() + 7);
        return date > today && date <= nextWeek;
    },
    isNextMonth(date) {
        const today = new Date();
        const nextMonth = new Date();
        nextMonth.setMonth(today.getMonth() + 1);
        return date > today && date <= nextMonth;
    },
    isNextYear(date) {
        const today = new Date();
        const nextYear = new Date();
        nextYear.setFullYear(today.getFullYear() + 1);
        return date > today && date <= nextYear;
    },
    parseDate(dateString) {
        return new Date(dateString);
    },
    trackWelcome() {
        let selectedItems = JSON.parse(localStorage.getItem('selectedItems'))
        let selectedSchedule = JSON.parse(localStorage.getItem('selectedSchedule'))
        let FinalSchedule = JSON.parse(localStorage.getItem('FinalSchedule'))

        if (!selectedItems?.length) {
            return "/welcome"
        } else if (!selectedSchedule?.length) {
            return "/time"
        } else if (!FinalSchedule?.length) {
            return "/days"
        } else {
            return "/meal"
        }
    },
    // Helper function to format the time
    formatTime(date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // If hour is 0, set it to 12 (for 12 AM)
        minutes = minutes < 10 ? '0' + minutes : minutes; // Add leading 0 if needed
        return `${hours}:${minutes} ${ampm}`;
    },
    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [year, month, day].join('-');
    },
    getLocalStorageSize(key) {
        // Get the data stored under the key
        const data = localStorage.getItem(key);
        if (!data) {
            const kb = 0
            const mb = 0
            const bytes = 0
            return {
                bytes,
                kb,
                mb,
            };
        }
        const bytess = new Blob([data]).size;
        const kbs = bytess / 1024;
        const mbs = bytess / (1024 * 1024);
        const kb = kbs.toFixed(2)
        const mb = mbs.toFixed(2)
        const bytes = bytess.toFixed(2)
        return {
            bytes,
            kb,
            mb,
        };
    },
    getAppData() {
        try {
            let userInfo = AuthService.getAuthDataByName("userToken")
            if (userInfo) {
                let currentCenter = userInfo?.centerInfo[0]
                return currentCenter
            }
            return { centerName: "My Senior Center", centerLogURI: "Assets/icons/msc_heart.png" }
        } catch (error) {
            console.log(error)
        }
    },
    addSuffixToUrl(url) {
        const dotIndex = url.lastIndexOf(".");
        if (dotIndex === -1) return url; // No extension found
        return `${url.slice(0, dotIndex)}-T${url.slice(dotIndex)}`;
    },
    uniqueById(data) {
        const uniqueData = data.reduce((acc, current) => {
            acc[current.seniorID] = current;
            return acc;
        }, {});

        return Object.values(uniqueData);
    }

}

export default Helpers;