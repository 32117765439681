import React, { useEffect, useState } from 'react'
import { AppBar, Avatar, Badge, Box, Toolbar, Typography, Divider, Button, DialogActions, DialogTitle, Dialog, DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import Helpers from '../Shell/Helper';
import EditIcon from '@mui/icons-material/Edit';
import AuthService from '../Services/AuthService';
import CloseIcon from '@mui/icons-material/Close';



const Root = styled(Box)(({ theme }) => ({
    height: "100%",
    padding: 0,

    '& .header-cont': {
        // width: "100%",
        width: "device-width",
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        "& header": {
            display: "flex",
            height: 48,
            background: "white",
            fontSize: 12,
            alignItems: "center",
            '& img[alt="Logo"]': {
                width: 34,
                filter: 'invert(1)',
            },
            "& .headerNav": {
                // marginRight: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                "& .fuel": {
                    color: "red",
                    position: "absolute",
                    width: "26px",
                    right: "62px",
                    top: "12px",
                    cursor: "pointer"
                },
                "& .pageMainTitle": {
                    fontSize: 16,
                    fontWeight: 600,
                    whiteSpace: "nowrap",
                    width: "260px",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                },
                "& .headerText": {
                    display: "flex",
                    color: '#1f0757',
                    fontSize: 13,
                    textShadow: '#1f0757',
                    fontWeight: 600,
                    whiteSpace: "nowrap"
                },
                "& .refreshingIcon": {
                    color: "Grey"
                },
                "& .reloadBtn": {
                    padding: "0px 0px 4px 0px"
                }
            }
        },
    },

    "& .avatarContainer": {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "40px",
        // marginBottom: "30px",
        "& .name": {
            color: theme.palette.type == 'dark' ? '#fff' : "#000",
            fontWeight: 500
        },
        "& .smallIcon": {
            width: 30,
            height: 30,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            position: "absolute",
            border: `2px solid green`,
            top: "20px",
            right: 0,
            backgroundColor: "green"
        }

    },
    "& .detail_container": {
        backgroundColor: "#FFFFFF",
        padding: "8px",
        border: "1px solid #c4c4c4",
        borderRadius: "5px",
        // marginBottom: "10px",
        margin: "0 10px 10px",
        "& .items": {
            display: "flex",
            justifyContent: "space-between",
            padding: "8px 0px"
        },
    },
    "& .avatar-detail_container": {
        backgroundColor: "#FFFFFF",
        padding: "10px",
        border: "1px solid #c4c4c4",
        "& .avatars": {
            // gap: "10px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            // borderRadius: "5px",
            // marginBottom: "10px",
            height: "calc(100vh - 340px)",
            overflowY: "auto",
            "& .icons": {
                height: "80px", width: "80px", borderRadius: "50%", border: "4px solid #c4c4c4", margin: "10px",
                overflow: "hidden",
                "& img": {
                    objectFit: "contain",
                    width: "100%",
                    height: "100%"
                }
            },
        },

    },
    "& .mainContainer": {
        backgroundImage: `url(${require("../Assets/loginBackgroundImg/img1.png")})`,
        backgroundSize: "cover",
        overflow: "hidden",
        backgroundPosition: "center",
        height: "calc(100vh - 48px)",
        marginTop: 48,
        overflowY: "auto",
        "& .Btn": {
            position: "absolute",
            bottom: "0px",
            left: "50%",
            transform: 'translate(-50%)',
            width: "80%",
            borderRadius: "40px",
            padding: "16px 14px",
            margin: "20px 0px",

            color: theme.palette.background.black,
            fontSize: 18,
            fontWeight: 600,
            "&:hover": {

                color: theme.palette.background.black,
            },
        },
    },
    '& img[alt="mealpng"]': {
        width: 60,
    },

}))

function ChangeProfile() {
    const [appInfo, setAppInfo] = useState({});
    const [profileId, setProfileId] = useState(1);
    const [logoutAlert, setLogoutAlert] = useState(false);
    const [isSelectingAvatar, setIsSelectingAvatar] = useState(true);
    const avatarsData = Array.from({ length: 39 }, (_, index) => index + 1);

    //Initial Calls
    useEffect(() => {
        let appInfo = Helpers.getAppData()
        if (appInfo) {
            setAppInfo(appInfo);
        } else {
            setAppInfo({ centerName: "My Senior Center", centerLogURI: "/Assets/icons/msc_heart.png" });
        }
    }, [])

    const handleSelectProfile = (id) => {
        setProfileId(id);
        setIsSelectingAvatar(true)
    }

    const handleSelectAvatar = () => {
        setIsSelectingAvatar(false)
    }
    const handleLogOut = () => {
        setLogoutAlert(false)
        AuthService.signOut();
    }
    const handleRefresh = () => {
        setLogoutAlert(false)
        window.location.reload();
    }

    return (
        <Root p={2}>
            <Box className="header-cont">
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static">
                        <Toolbar
                            sx={{ width: "100%", maxWidth: "100%", padding: "0px 15px 0px 45px !important" }}>
                            <Box className="headerNav">
                                <Box
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2, display: "inline-flex", alignItems: "center" }}>
                                    {appInfo?.centerLogURI ?
                                        <img src={appInfo.centerLogURI}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = require("../Assets/icons/msc_heart.png");
                                            }}
                                            alt="Logo" style={{ marginRight: 10 }}
                                        /> : <img src={require(`../Assets/icons/msc_heart.png`)} alt="Logo" style={{ marginRight: 10 }}
                                        />}
                                    <div>
                                        <Typography color="black" className="pageMainTitle" id="pageTitle">
                                            {appInfo.centerName}
                                        </Typography>
                                        <Typography color="black" className="headerText" id="pageTitle">
                                            Profile
                                        </Typography>
                                    </div>

                                </Box>

                            </Box>
                        </Toolbar>
                    </AppBar>
                </Box>
            </Box>

            <Box className="mainContainer">
                <Box >
                    {/* {loader ?
                        <Stack mx={2} alignItems='center' mt={5} spacing={3}>
                        </Stack> : */}
                    <Box className="main_container">
                        {/* <IconButton sx={{ marginTop: "10px" }} onClick={() => { navigate('/app-senior') }}>
                                <BackIcon />
                            </IconButton> */}

                        <Box className="avatarContainer" mt={0} >
                            <Badge
                                onClick={handleSelectAvatar}
                                sx={{}}
                                overlap="circular"
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            >
                                <Avatar sx={{ width: '140px', height: '140px', border: "4px solid green" }} alt="Travis Howard" src={require(`../Assets/animal/${profileId}.png`)} />

                                <Box className="smallIcon">
                                    <EditIcon sx={{ color: "#fff" }} />
                                </Box>
                            </Badge>
                            <Typography className="" my={2} variant='subtitle1'>Jhon Wick</Typography>
                        </Box>
                        {isSelectingAvatar ?
                            <>
                                <Box className='detail_container'>
                                    <Box className="items"><Typography sx={{ fontWeight: 600 }} variant='body2' className='typo_text'>Email</Typography><Typography variant='body2' className='typo_text'>abc@gmail.com</Typography></Box>
                                    <Divider />
                                    <Box className="items"><Typography sx={{ fontWeight: 600 }} variant='body2' className='typo_text'>DOB</Typography><Typography variant='body2' className='typo_text'>1999-12-2</Typography></Box>
                                    <Divider />
                                    <Box className="items"><Typography sx={{ fontWeight: 600 }} variant='body2' className='typo_text'>Contact</Typography><Typography variant='body2' className='typo_text'>1253639235</Typography></Box>
                                    <Divider />
                                    <Box className="items"><Typography sx={{ fontWeight: 600 }} variant='body2' className='typo_text'>Address</Typography><Typography variant='body2' className='typo_text'>abs 12 xyz</Typography></Box>

                                </Box>
                                <Box>
                                    <Button className='Btn' color="error" onClick={() => setLogoutAlert(true)} variant='contained'>Logout</Button>
                                </Box>
                            </> :
                            <Box className='avatar-detail_container'>
                                <Typography variant='subtitle1'>Select your Avatar</Typography>
                                <Box className="avatars">
                                    {avatarsData?.map((item) => <Box sx={{ borderColor: profileId === item ? "green !important" : "" }} key={item} onClick={() => handleSelectProfile(item)} className='icons'>
                                        <img
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = require("../Assets/animal/1.png");
                                            }}
                                            src={require(`../Assets/animal/${item}.png`)} width={"100%"} alt="avatar" />
                                    </Box>
                                    )}
                                </Box>

                            </Box>}


                    </Box>
                    {/* } */}

                </Box>
            </Box>
            <Dialog
                open={logoutAlert}
                onClose={() => setLogoutAlert(false)}
                aria-labelledby="logout-alert-title"
                aria-describedby="logout-alert-description"
            >

                <DialogTitle id="logout-alert-title" sx={{ fontSize: "20px", paddingBottom: "8px", paddingLeft: "15px", paddingRight: "10px" }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", }}>
                        <Typography sx={{}}>Are you sure?</Typography>
                        <CloseIcon onClick={() => setLogoutAlert(false)} />
                    </Box>

                </DialogTitle>
                <DialogContent sx={{ paddingBottom: "10x", paddingLeft: "15px" }}>
                    <Typography variant='body2' id="logout-alert-description">
                        Logging out means you will need an activation key which could take 10 minutes or several days depending on your center's availability
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ paddingLeft: "15px", paddingRight: "15px", borderTop: "1px solid #ccc", display: 'flex', justifyContent: "space-between" }}>
                    <Button sx={{ backgroundColor: "#000524", borderRadius: 30, color: "white" }} variant="contained" onClick={handleLogOut} autoFocus>Logout</Button>

                    <Button sx={{ backgroundColor: "#4caf50", borderRadius: 30, color: "white", "&:hover": { backgroundColor: "#4caf50" } }} variant="contained" onClick={handleRefresh} autoFocus>Refresh Application</Button>
                </DialogActions>
            </Dialog>
        </Root>
    )
}

export default ChangeProfile