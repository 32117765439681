import React, { useEffect, useState } from 'react'
import { AppBar, Box, Divider, Toolbar, Typography } from '@mui/material'
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { GetAllSeniors } from '../Services/Api/MealService';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
const Root = styled(Box)(({ theme }) => ({
    height: '100%',
    padding: 0,
    '& .header-cont': {
        // width: "100%",
        width: "device-width",
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        "& header": {
            display: "flex",
            height: 48,
            background: "white",
            fontSize: 12,
            alignItems: "center",
            '& img[alt="Logo"]': {
                width: 34,
                // filter: 'invert(1)',
            },
            "& .headerNav": {
                // marginRight: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                "& .fuel": {
                    color: "red",
                    position: "absolute",
                    width: "26px",
                    right: "62px",
                    top: "12px",
                    cursor: "pointer"
                },
                "& .pageMainTitle": {
                    fontSize: 15,
                    fontWeight: 600,
                    whiteSpace: "nowrap"
                },
                "& .headerText": {
                    display: "flex",
                    color: '#1f0757',
                    fontSize: 13,
                    textShadow: '#1f0757',
                    fontWeight: 600,
                    whiteSpace: "nowrap"
                }
            }
        },
    },
    "& .MainTitle": {
        fontSize: 15,
        fontWeight: 600,
        whiteSpace: "nowrap"
    },
    "& .subTitle": {
        fontSize: 15,
        fontWeight: 500,
        whiteSpace: "nowrap"
    },
    "& .MuiTab-root.Mui-selected": {
        color: 'black'
    },
    '& .mainContainer': {
        backgroundImage: `url(${require('../Assets/loginBackgroundImg/img1.png')})`,
        backgroundSize: "cover",
        overflow: "hidden",
        backgroundPosition: "center",
        display: "flex",
        flexDirection: "column",
        // justifyContent: "space-between",
        overflowY: "auto",
        height: "calc(100vh - 48px)",
        marginTop: 48,
        "& .content": {
            padding: "10px",
            width: "100%",
            "& .infoCard": {
                padding: "10px",
                display: "flex",
                width: "100%",
                height: "fit-content",
                borderRadius: "5px",
                gap: "10px",
                marginBottom: "10px",
                backgroundColor: "#fff",
                "& .image": {
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#b99ec7d4",
                    "& img": {
                        filter: "invert(1)",
                    }
                },
                "& .themeChanger": {
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                },
                "& .personalInfo": {
                    cursor: "pointer",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px"
                }
            },
        },
        "& .InfoTabs": {
            "& .tabHeader": {
                fontSize: "12px",
                margin: 0,
                padding: 0,
            }
        },
        "& .tabContent": {
            marginBottom: "10px",
            borderRadius: "5px",
            backgroundColor: "white",
            padding: "10px",
            "& p": {
                fontSize: "16px",
                fontWeight: 400,
            }
        }
    },
}));
//tabs
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box className="mainTab" sx={{ p: 1, }}>
                    {children}
                </Box>
            )}
        </div>
    );
}
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
//main
export default function SeniorInfo() {
    const [seniors, setSeniors] = useState({
        seniorsList: [],
    });
    const [value, setValue] = useState(1);
    let { state } = useLocation();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const openEdit = (e, i, o) => {
        // navigate('/edit', {
        //     state: {
        //         type: e,
        //         firstInfo: i,
        //         lastInfo: o,
        //     }
        // })
    }
    useEffect(() => {
        let seniorsList = GetAllSeniors();
        let seniorsListData = seniorsList.data;
        if (state?.seniorID) {
            localStorage.setItem("selectedSenior", state.seniorID)
            let filteredData = seniorsListData.filter(item => item.SeniorID === state.seniorID);
            setSeniors({ ...seniors, seniorsList: filteredData })
        } else {
            let id = JSON.parse(localStorage.getItem("selectedSenior"))
            let filteredData = seniorsListData.filter(item => item.SeniorID === id);
            setSeniors({ ...seniors, seniorsList: filteredData })
        }
    }, [])
    return (
        <Root p={2} >
            {/* Header */}
            <Box className="header-cont">
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static">
                        <Toolbar
                            sx={{ width: "100%", maxWidth: "100%", padding: "0px 15px 0px 45px !important" }}>
                            <Box className="headerNav">
                                <Box
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2, display: "inline-flex", alignItems: "center" }}>

                                    <img src={require('../Assets/icons/msc_heart.png')} alt="Logo" style={{ marginRight: 10 }} />
                                    <div>
                                        <Typography color="black" className="pageMainTitle" id="pageTitle">
                                            My Senior Center
                                        </Typography>
                                        <Typography color="black" className="headerText" id="pageTitle">
                                            Senior Information
                                        </Typography>
                                    </div>
                                </Box>
                            </Box>
                        </Toolbar>
                    </AppBar>
                </Box>
            </Box>
            <Box className="mainContainer">
                {seniors?.seniorsList.map((e, i) => {
                    return <Box key={i} className="content">
                        <Box className='infoCard'>
                            <Box className="image">
                                <img src={require('../Assets/images/driver-icon.png')} sx={{}} alt="Logo" width="80%" height="80%" />
                            </Box>
                            <Box>
                                <Typography className="MainTitle" >
                                    {e.LastName}, {e.FirstName}
                                </Typography>
                                <Typography className="subTitle">Senior</Typography>
                            </Box>
                        </Box>
                        <Box className='infoCard'>
                            <Box className="personalInfo" >
                                <Box className="themeChanger" onClick={() => openEdit("name", e.LastName, e.FirstName)}>
                                    <Typography className="MainTitle" > Name</Typography>
                                    <Typography className="subTitle"> {e.LastName}, {e.FirstName}</Typography>
                                </Box>
                                <Divider />
                                <Box className="themeChanger" onClick={() => openEdit("city", e.Cty)}>
                                    <Typography className="MainTitle" >City </Typography>
                                    <Typography className="subTitle">{e.Cty}</Typography>
                                </Box>
                                <Divider />
                                <Box className="themeChanger" onClick={() => openEdit("state", e.State)}>
                                    <Typography className="MainTitle" > State </Typography>
                                    <Typography className="subTitle">{e.State}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                })}
                <Box className="InfoTabs">
                    <AppBar sx={{ background: "transparent" }} position="static">
                        <Tabs variant="scrollable"
                            TabIndicatorProps={{ style: { color: "red" } }}
                            scrollButtons="auto" sx={{ height: "40px ", }} value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab className='tabHeader' label="File Info" {...a11yProps(0)} />
                            <Tab className='tabHeader' label="Chat"   {...a11yProps(1)} />
                            <Tab className='tabHeader' label="Activities" {...a11yProps(2)} />
                            <Tab className='tabHeader' label="Rides"  {...a11yProps(3)} />
                            <Tab className='tabHeader' label="Meals"  {...a11yProps(4)} />
                            <Tab className='tabHeader' label="Groups"  {...a11yProps(5)} />
                        </Tabs>
                    </AppBar>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <Box className="tabContent" >
                        <Typography>File Info</Typography>
                    </Box>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <Box className="tabContent">
                        <Typography>Chat</Typography>
                    </Box>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <Box className="tabContent">
                        <Typography>Activities</Typography>
                    </Box>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                    <Box className="tabContent">
                        <Typography>Rides</Typography>
                    </Box>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={4}>
                    <Box className="tabContent">
                        <Typography>Meals</Typography>
                    </Box>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={5}>
                    <Box className="tabContent">
                        <Typography>Groups</Typography>
                    </Box>
                </CustomTabPanel>
            </Box>
        </Root>
    )
}

