import React, { useEffect, useRef, useState } from 'react'
import AuthService from '../../Services/AuthService';
import { Link, useNavigate } from 'react-router-dom';
import { Alert, AppBar, Avatar, Box, Button, CircularProgress, Divider, InputAdornment, Snackbar, TextField, Toolbar, Typography, styled } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Checkbox from '@mui/material/Checkbox';
import Helpers from '../../Shell/Helper';
import moment from 'moment';
import { AssignWorkRequests, GetWorkRequests } from '../../Services/Api/VolunteerService';
import dummyDataVolunteer from '../../Services/volunteer.json';
import DoneIcon from '@mui/icons-material/Done';
import SearchOffOutlinedIcon from '@mui/icons-material/SearchOffOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ReactTimeago from 'react-timeago';

const Root = styled(Box)(({ theme }) => ({
    '& .header-cont': {
        width: "100%",
        width: "device-width",
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        "& header": {
            display: "flex",
            height: 48,
            background: "white",
            fontSize: 12,
            alignItems: "center",
            '& img[alt="Logo"]': {
                width: 28,

            },
            "& .HeaderScan": {
                width: "100%",
                maxWidth: "100%",
                paddingLeft: "38px",
                paddingRight: "8px",
                [theme.breakpoints.down('md')]: {
                    padding: "0px 8px 0 40px",
                },
            },
            "& .headerNav": {
                // marginRight: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                position: "relative",
                "& .fuel": {
                    color: "red",
                    position: "absolute",
                    width: "26px",
                    right: "62px",
                    top: "12px",
                    cursor: "pointer"
                },
                "& .pageMainTitle": {
                    fontSize: 16,
                    fontWeight: 600,
                    whiteSpace: "nowrap",
                    width: "300px",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                },
                "& .calenderIcon": {
                    [theme.breakpoints.down('sm')]: {
                        display: "none"
                    },
                },
                "& .headerText": {
                    display: "flex",
                    color: 'grey',
                    fontSize: 13,
                    textShadow: '#1f0757',
                    fontWeight: 500,
                    whiteSpace: "nowrap"
                },
                "& .searchInput": {

                },

            }
        },
    },

    "& .MainTitle1": {
        fontSize: 14,
        fontWeight: 600,
        textShadow: "black",
        whiteSpace: "nowrap"
    },
    "& .MainTitle": {
        fontSize: 15,
        fontWeight: 600,
        whiteSpace: "nowrap"
    },
    "& .subTitle": {
        fontSize: 15,
        fontWeight: 500,
        whiteSpace: "nowrap"
    },
    '& .mainContainer': {
        // backgroundImage: `url(${require('../../Assets/loginBackgroundImg/img1.png')})`,
        // backgroundSize: "cover",
        // backgroundPosition: "center",
        backgroundColor: "#FBFBFD",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "flex-start",
        marginTop: "48px",
        height: "calc(100vh - 48px) !important",
        padding: "10px 16px 20px 16px",
        position: "relative",
        overflow: "auto !important",
        "& .upcoming_text": {
            fontSize: "17px",
            fontWeight: 600
        },
        "& .upComing_task": {
            border: "1px solid #c4c4c4",
            display: "flex",
            cursor: "pointer",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            backgroundColor: "white",
            margin: "20px auto",
            padding: "10px 10px 10px 50px",
            borderRadius: "5px",
            img: {
                position: "absolute",
                left: "-20px"
            },
            "& .view_text": {
                fontWeight: 600,
                fontSize: "16px"
            }
        },
        "& .title": {
            fontSize: 15,
            fontWeight: 600,
            whiteSpace: "nowrap"
        },
        "& .subTitle": {
            fontSize: 12,
            fontWeight: 400,
            whiteSpace: "nowrap"
        },
        "& .mainDate": {
            fontSize: "20px",
            color: "#31a85f",
        },
        "& .no_data_found": {
            height: "calc(100vh - 260px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // border: "3px solid red"
        },

        "& .seniorContainer": {
            width: "100% !important",
            // border: "1px solid black !important",
            height: "100%",
            paddingBottom: "20px",
            position: "relative",
            // overflow: "auto",
            "& .taskBtn": {
                backgroundColor: "#055A61",
                width: "100px"
            },
            "& .nottaskBtn": {
                width: "100px"
            },
            "& .filterButtonDiv": {
                // border: "1px solid black",

            },
            "& .filterButton": {
                // minWidth: "20px !important",
                position: "absolute",
                top: "16px",
                "& .filter": {
                    minWidth: "20px !important",
                },
                "& .filterIcon": {
                    fontSize: "30px",
                    color: "#202020 !important"
                }
            },
            "& .seniorCard": {
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                border: "1px solid #c4c4c4",
                backgroundColor: "white",
                padding: "8px 12px 8px 1px",
                borderRadius: "10px",
                // cursor: "pointer",
                "& .sec1": {
                    width: "calc(100% - 63px)",
                    display: "flex",
                    alignItems: "center",
                    paddingRight: "2px !important",
                    // border: "1px solid red",

                },
                "& .sec2": {
                    // border: "1px solid red",
                    width: "63px"
                },
                "& .wordBreak": {
                    fontSize: 12,
                    fontWeight: 400,
                    wordBreak: "break-word !important",


                },
                "& .seniorName": {
                    fontSize: 15,
                    fontWeight: 600,
                    wordBreak: "break-word !important",
                }

            },
            "& .shadow": {
                boxShadow: "0px 4px 4px 0px rgb(0 0 0 / 25%)",
                // boxShadow: "0px 4px 15px -8px rgb(0 0 0 / 50%)",
            }
        },
        "& .actionButtonContainer": {
            width: "100%",
            height: "90px",
            // border: "2px solid black",
            borderRadius: '10px 10px 0px 0px',
            position: "fixed",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
            borderTop: "1px solid #c4c4c4",
            alignItems: "center",
            "& .actionBtn": {
                width: "100%",
                margin: "0 14px",
                padding: "10px 10px",
                fontSize: "20px",
                borderRadius: "100px"
            },
            "& .danger": {
                backgroundColor: theme.palette.error.main,
                color: theme.palette.other.text,
            },
            "& .success": {
                backgroundColor: theme.palette.button.main,
                color: theme.palette.other.text,
            }
        },
    },
}))

// const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

// const dummyData = [
//     {
//         "id": 1,
//         "SeniorName": "John Mile",
//         "title": "Trador Joe's",
//         "address": { "lat": 40.712776, "long": -74.105994 },
//         "time": "2024-05-27T20:00:00+00:00"
//     },
//     {
//         "id": 2,
//         "SeniorName": "Alice Smith",
//         "title": "Super Mart",
//         "address": { "lat": 40.730610, "long": -73.935242 },
//         "time": "2024-05-28T15:30:00+00:00"
//     },
//     {
//         "id": 3,
//         "SeniorName": "Bob Johnson",
//         "title": "Corner Store",
//         "address": { "lat": 40.752726, "long": -73.977229 },
//         "time": "2024-05-29T09:00:00+00:00"
//     },
//     {
//         "id": 4,
//         "SeniorName": "Emma Brown",
//         "title": "Farmers Market",
//         "address": { "lat": 40.748817, "long": -73.985428 },
//         "time": "2024-05-28T12:45:00+00:00"
//     },
//     {
//         "id": 5,
//         "SeniorName": "Michael Davis",
//         "title": "Grocery Depot",
//         "address": { "lat": 40.706001, "long": -74.008800 },
//         "time": "2024-05-27T14:20:00+00:00"
//     },
//     {
//         "id": 6,
//         "SeniorName": "Samantha White",
//         "title": "Organic Foods Inc.",
//         "address": { "lat": 40.730610, "long": -73.935242 },
//         "time": "2024-05-28T10:10:00+00:00"
//     },
//     {
//         "id": 7,
//         "SeniorName": "David Wilson",
//         "title": "Health Haven",
//         "address": { "lat": 40.712776, "long": -74.005974 },
//         "time": "2024-05-28T17:30:00+00:00"
//     },
//     {
//         "id": 8,
//         "SeniorName": "Olivia Garcia",
//         "title": "Fresh Produce Co.",
//         "address": { "lat": 40.758896, "long": -73.985130 },
//         "time": "2024-05-27T11:15:00+00:00"
//     },
//     {
//         "id": 9,
//         "SeniorName": "James Martinez",
//         "title": "Family Grocers",
//         "address": { "lat": 40.712776, "long": -74.005974 },
//         "time": "2024-05-28T09:45:00+00:00"
//     },
//     {
//         "id": 10,
//         "SeniorName": "Emily Rodriguez",
//         "title": "Market Square",
//         "address": { "lat": 40.748817, "long": -73.985428 },
//         "time": "2024-05-29T13:00:00+00:00"
//     },
//     {
//         "id": 11,
//         "SeniorName": "Sophia Hernandez",
//         "title": "Corner Market",
//         "address": { "lat": 40.706001, "long": -74.008800 },
//         "time": "2024-05-27T16:20:00+00:00"
//     },
//     {
//         "id": 12,
//         "SeniorName": "William Lopez",
//         "title": "Neighborhood Grocer",
//         "address": { "lat": 40.758896, "long": -73.985130 },
//         "time": "2024-05-28T14:45:00+00:00"
//     },
//     {
//         "id": 13,
//         "SeniorName": "Ethan Gonzalez",
//         "title": "Urban Farm Stand",
//         "address": { "lat": 40.730610, "long": -73.935242 },
//         "time": "2024-05-28T10:30:00+00:00"
//     },
//     {
//         "id": 14,
//         "SeniorName": "Ava Perez",
//         "title": "Veggie Mart",
//         "address": { "lat": 40.748817, "long": -73.985428 },
//         "time": "2024-05-27T12:00:00+00:00"
//     },
//     {
//         "id": 15,
//         "SeniorName": "Alexander Torres",
//         "title": "Produce Junction",
//         "address": { "lat": 40.712776, "long": -74.005974 },
//         "time": "2024-06-10T15:10:00+00:00"
//     },
//     {
//         "id": 16,
//         "SeniorName": "Mia Ramirez",
//         "title": "Organic Oasis",
//         "address": { "lat": 40.706001, "long": -74.008800 },
//         "time": "2024-05-29T11:30:00+00:00"
//     },
//     {
//         "id": 17,
//         "SeniorName": "Benjamin Flores",
//         "title": "Green Grocers",
//         "address": { "lat": 40.748817, "long": -73.985428 },
//         "time": "2024-06-12T14:40:00+00:00"
//     },
//     {
//         "id": 18,
//         "SeniorName": "Charlotte Gutierrez",
//         "title": "Fresh Picks",
//         "address": { "lat": 40.730610, "long": -73.935242 },
//         "time": "2024-06-13T09:20:00+00:00"
//     },
//     {
//         "id": 19,
//         "SeniorName": "Lucas Reed",
//         "title": "Organic Corner",
//         "address": { "lat": 40.758896, "long": -73.985130 },
//         "time": "2024-05-29T16:00:00+00:00"
//     },
//     {
//         "id": 20,
//         "SeniorName": "Harper Price",
//         "title": "Fruit Stand",
//         "address": { "lat": 40.748817, "long": -73.985428 },
//         "time": "2024-05-29T13:15:00+00:00"
//     },
//     {
//         "id": 21,
//         "SeniorName": "Liam Butler",
//         "title": "Nature's Bounty",
//         "address": { "lat": 40.712776, "long": -74.005974 },
//         "time": "2024-05-29T10:45:00+00:00"
//     }
// ]
const getCustomRelativeTime = (pickupDateTime) => {
    const now = moment();
    const diff = pickupDateTime.diff(now);

    if (diff < 0) {
        return `Left ${moment.duration(-diff).humanize()} ago`;
    }
    return `Leave in ${moment.duration(diff).humanize()}`;
};



const Volunteer2 = () => {
    const navigate = useNavigate();

    const [workRequestData, setWorkRequestData] = useState([]);
    const [checkedData, setCheckedData] = useState([]);
    const [acceptedWorkRequestData, setAcceptedWorkRequestData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loader, setLoader] = useState(false);
    const [acceptLoader, setAcceptLoader] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [myTask, setMyTask] = useState(false);
    const [toggleSearch, setToggleSearch] = useState(false);
    const [appInfo, setAppInfo] = useState({});
    const [length, setLength] = useState(0);
    const count = useRef();
    // for menu --------
    const [anchorEl, setAnchorEl] = React.useState(null);

    // useEffect(() => {
    //     let Token = AuthService.getAuthDataByName("token") || "";
    //     if (Token === "") {
    //         navigate("/");
    //     }// eslint-disable-next-line
    // }, [])
    //Initial calls
    useEffect(() => {
        let appInfo = Helpers.getAppData()
        if (appInfo) {
            setAppInfo(appInfo);
        } else {
            setAppInfo({ centerName: "My Senior Center", centerLogURI: "/Assets/icons/msc_heart.png" });
        }
    }, [])
    useEffect(() => {
        getWorkRequestData();
        checkExpiration();
        // getWorkRequestDataForLength();
        // console.log(data, "<-- dataByDate")

    }, [])

    const getWorkRequestData = async (dummy = false, status = true) => {
        if (dummy) {
            // console.log(dummyDataVolunteer.data)
            const data = Helpers.DataGroupByDate(dummyDataVolunteer.data);
            const filterAccepted = dummyDataVolunteer?.data?.filter((item) => item?.status == "accepted");
            setAcceptedWorkRequestData(filterAccepted)
            setCheckedData(filterAccepted)
            const sortedData = Helpers.SortObjectByDateKeys(data, true);
            // console.log(sortedData)
            setWorkRequestData(sortedData);
        } else {
            try {
                setLoader(true)
                const response = await GetWorkRequests(Helpers.DateFormator(new Date()), status);
                const data = Helpers.DataGroupByDate(response.data);

                // console.log(data);
                const sortedData = Helpers.SortObjectByDateKeys(data, true);
                // console.log(sortedData);
                // console.log("res", response)
                const filterAccepted = response?.data?.filter((item) => item?.status == "accepted");
                setAcceptedWorkRequestData(filterAccepted)
                setCheckedData(filterAccepted)

                setWorkRequestData(sortedData);
                // alert(JSON.stringify(response))
                setLoader(false)
            } catch (error) {
                // console.log(error, "<-- workRequested");
                setLoader(false)
                // alert(JSON.stringify(error))
            }
        }
    }
    const getWorkRequestDataForLength = async () => {
        try {
            // debugger
            const response = await GetWorkRequests(Helpers.DateFormator(new Date()), false);
            setLength(response?.data?.length);
            // console.log(response)
            setLoader(false)
        } catch (error) {
            // console.log(error, "<-- workRequestedLength");
            setLoader(false)
        }
    }

    const handleAcceptRides = async () => {
        // console.log(checkedData, "<-- handleAcceptRides")
        const openWorkRequest = checkedData?.filter((item) => item.status == "open");
        const WorkRequestIDs = openWorkRequest?.map((item) => item.metaData.ID);
        // console.log(WorkRequestIDs, "<--- workRequestIDs")
        count.current = WorkRequestIDs.length;
        try {
            setAcceptLoader(true);
            const response = await AssignWorkRequests({ WorkRequestIDs });
            if (response?.success) {
                // alert(response?.message)
                getWorkRequestData()
                setOpenAlert(true);
                setCheckedData([]);
                setAcceptLoader(false)
                setMyTask(!myTask);
            } else {
                alert(response?.message)
                setAcceptLoader(false)
            }
            setAcceptLoader(false)
        } catch (error) {
            console.log(error);
            setAcceptLoader(false)
        }
    }
    const checkExpiration = () => {
        const userToken = AuthService.getAuthDataByName("userToken")
        if (userToken) {
            const expireDate = userToken?.expiresUTC;
            if (new Date() > new Date(expireDate)) {
                AuthService.signOut();
                // console.log(userToken, "<--here");
            }
            // console.log(userToken);
        }
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setToggleSearch(!toggleSearch);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    // ----------------
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    };
    const handleCheck = (data) => {
        // console.log(data, "<--data handlecheck")

        const index = checkedData?.findIndex(s => s.id === data.id);
        if (index == -1) {
            setCheckedData([...checkedData, data]);
        } else {
            setCheckedData(checkedData?.filter(item => item.id !== data.id));
        }
        // console.log(checkedData, "<-- checkedData")
    }

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filterDataBySearchQuery = () => {
        if (!searchTerm) return workRequestData;

        const filteredData = {};
        Object.entries(workRequestData).forEach(([key, value]) => {
            const filteredValues = value?.filter(item => {
                return item?.metaData?.SeniorName?.toLowerCase()?.includes(searchTerm.toLowerCase())

            }
            );

            if (filteredValues.length > 0) {
                filteredData[key] = filteredValues;
            }
        });

        return filteredData;
    };

    const filteredWorkRequestData = filterDataBySearchQuery();

    const handleTask = () => {
        setMyTask(!myTask)
        if (myTask) {
            getWorkRequestData(false, true);
            // getWorkRequestDataForLength()
        } else {
            getWorkRequestData(false, false);
        }
    }

    const today = moment().format('YYYY-MM-DD');

    return (
        // <DefaultLayout>
        <Root>
            <Box className="header-cont" >
                <AppBar position="static">
                    <Toolbar
                        className='HeaderScan'
                        sx={{}}>
                        <Box className="headerNav">
                            <Box
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2, display: "inline-flex", alignItems: "center" }}>
                                {appInfo?.centerLogURI ?
                                    <img src={appInfo.centerLogURI}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = require("../../Assets/icons/msc_heart.png");
                                        }}
                                        alt="Logo" style={{ marginRight: 10 }}
                                    /> : <img src={require(`../../Assets/icons/msc_heart.png`)} alt="Logo" style={{ marginRight: 10, filter: 'invert(1)', }}
                                    />}
                                <div>
                                    <Typography color="black" className="pageMainTitle" id="pageTitle">
                                        {appInfo.centerName}
                                    </Typography>
                                    <Typography color="black" className="headerText" id="pageTitle">
                                        Volunteers
                                    </Typography>
                                </div>

                            </Box>

                        </Box>
                    </Toolbar>
                </AppBar>
            </Box>
            <Box className="mainContainer">
                {/* <Box>
                    <Typography variant='body2' className='title'>Bill Henderson</Typography>
                     <Typography variant='subtitle2' className='mainDate'>Tuesday, Feb 13th, 2024</Typography>
                    <Typography variant='subtitle2' className='mainDate'>{moment(new Date(), "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ").format('dddd, MMMM Do, YYYY')}</Typography>
                </Box> */}
                {/* search bar */}
                {
                    toggleSearch &&
                    // <Typography variant='body2' className='upcoming_text' mt={1}>Your Upcoming Task</Typography>
                    <Box className="upComing_task" onClick={handleTask}>
                        <img src={require("../../Assets/images/HandshakeHeart.png")} className='heart_image' />
                        <Typography variant='body2' className='view_text'>{myTask ? "Back To My Tasks" : `View Upcoming Tasks`}</Typography>
                    </Box>
                }
                {
                    toggleSearch &&
                    <Box width={"100%"} sx={{ zIndex: "0" }} height={50} mt={1}>
                        <TextField
                            sx={{
                                color: 'red',
                                zIndex: 2, fontSize: 16, fontWeight: 200,
                                "& .MuiOutlinedInput-root": {
                                    color: "black !important",
                                    borderRadius: "40px",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    backgroundColor: "#fff",
                                    "& Mui-focused": {
                                        border: "1px solid grey",
                                        outline: "1px solid grey",
                                    },
                                    "& fieldSet": {

                                    },
                                    "& .MuiInputBase-input": {
                                        padding: "14px 14px 14px 0px"
                                    }
                                },
                            }}
                            className='searchInput'
                            InputProps={{
                                startAdornment: <InputAdornment sx={{ cursor: 'pointer' }} position='start'> <SearchIcon sx={{ color: "#c4c4c4", fontSize: "30px", marginTop: "4px" }} /> </InputAdornment>
                            }}
                            placeholder='Search'
                            onChange={handleSearchChange}
                            value={searchTerm}

                        />
                    </Box>
                }
                {/* srollable container */}
                {
                    !toggleSearch &&
                    // <Typography variant='body2' className='upcoming_text' mt={1}>Your Upcoming Task</Typography>
                    <Box className="upComing_task" onClick={handleTask}>
                        <img src={require("../../Assets/images/HandshakeHeart.png")} className='heart_image' />
                        <Typography variant='body2' className='view_text'>{myTask ? "Back To My Tasks" : `View Upcoming Tasks`}</Typography>
                    </Box>
                }
                <Box className="seniorContainer">
                    <Box className="filterButtonDiv" display={"flex"} mt={toggleSearch ? 1.5 : 1} justifyContent={"flex-end"} alignItems={"center"}>
                        {/* <Button
                            onClick={handleTask}
                            variant='contained'
                            className={loader ? 'nottaskBtn' : 'taskBtn'}
                            disableElevation
                            disabled={loader}


                        >
                            {myTask ? 'All Task' : 'My Task'}
                        </Button> */}
                        <Box className='filterButton'>
                            <Button onClick={handleClick} className='filter' size='small'>
                                {
                                    toggleSearch ?
                                        <SearchOffOutlinedIcon className='filterIcon' />
                                        :
                                        <SearchOutlinedIcon className='filterIcon' />
                                }
                            </Button>
                        </Box>
                    </Box>
                    {/* filter */}

                    {/* <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <Typography variant='h6' sx={{ p: 1, px: 2 }}>The content of the Popover.</Typography>
                            <Typography variant='h6' sx={{ p: 1, px: 2 }}>The content of the Popover.</Typography>
                        </Popover> */}


                    {
                        loader ?
                            <Box mt={5} sx={{ textAlign: "center" }}>
                                <CircularProgress size={30} sx={{ color: "black" }} />
                            </Box>
                            :
                            Object.entries(filteredWorkRequestData)?.length > 0 ?
                                Object.entries(filteredWorkRequestData)?.map(([key, value], index) => {
                                    const isToday = key === today;

                                    return (
                                        <div key={index}>
                                            <Typography className="title" mt={2} mb={1} sx={{}}>
                                                <RelativeTime dateString={key} />
                                                &nbsp;
                                                &nbsp;
                                                <span style={{ fontWeight: 400 }}>
                                                    {moment(key).format('MMMM D, YYYY')}
                                                </span>
                                            </Typography>
                                            {value.map((value, i) => (
                                                <SeniorCard checkedData={checkedData} method={handleCheck} data={value} key={i} isToday={isToday} dateKey={key} />
                                            ))}

                                            <Box mt={3}>
                                                <Divider />
                                            </Box>
                                        </div>
                                    )
                                })
                                :
                                <div style={{ textAlign: "center", marginTop: "0px" }} className='no_data_found'>

                                    <img src={require('../../Assets/images/dataNotFound.png')} width={100} alt='NoDataFound' />
                                    <Typography sx={{ textAlign: "center", fontSize: "18px" }} mt={1} variant='h6'>No Volunteer Items Found</Typography>
                                    <Box mt={1}>
                                        <Link style={{ color: "#3B87F9" }}>Show Past History?</Link>
                                    </Box>
                                </div>
                    }
                    {
                        Object.entries(filteredWorkRequestData)?.length > 0 ?
                            <Box my={2} textAlign={"center"}>
                                <Link style={{ color: "#3B87F9" }}>Show Past History?</Link>
                            </Box> : ""
                    }
                    {/* {
                        loader ?
                            <Box mt={3} sx={{ textAlign: "center" }}>
                            <CircularProgress size={30} sx={{ color: "black" }} />
                            </Box>

                            :

                            (workRequestData?.length > 0) ? workRequestData?.map((value, key) => (
                                <div key={key}>
                                    <SeniorCard checkedData={checkedData} method={handleCheck} data={value} />
                                </div>
                            ))
                                :
                                <div style={{ textAlign: "center" }}>
                                    Empty
                                </div>

                    } */}
                    {
                        checkedData?.filter(item => item?.status == "open")?.length > 0 ?
                            <Box mt={5} pt={5} sx={{ marginTop: "60px" }}>
                                <Divider />
                            </Box>
                            : <></>
                    }
                </Box>
                {/* bottom Btn */}

                {/* <Box className="actionButtonContainer">
                    {
                        checkedData.length > 0 ?
                            <Button className='actionBtn success' onClick={() => console.log(checkedData)}>
                                Accept Rides
                            </Button>
                            :
                            
                        <Button className='actionBtn danger'>
                            Decline All
                        </Button>
                    }
                </Box> */}
                {
                    checkedData?.filter(item => item?.status == "open")?.length > 0 ?
                        <Box className="actionButtonContainer">


                            <Button
                                className='actionBtn success'
                                onClick={handleAcceptRides}
                                startIcon={acceptLoader ? <CircularProgress size={20} sx={{ color: "white" }} /> : ""}
                            >
                                Accept Rides
                            </Button>

                        </Box>
                        : <></>
                }
            </Box>

            <Snackbar
                open={openAlert}
                autoHideDuration={5000}
                onClose={handleCloseAlert}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                sx={{ marginTop: "41px" }}
            >
                <Alert
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%', backgroundColor: '#232425' }}
                    icon={false} // Remove the tick icon
                >
                    {count.current} {count.current > 1 ? "Rides" : "Ride"} Accepted Successfully!
                </Alert>
            </Snackbar>

        </Root >
        // </DefaultLayout>
    )
}

export default Volunteer2;

const SeniorCard = ({ method, checkedData, data, isToday, dateKey }) => {
    const navigate = useNavigate();
    const pickupDateTime = moment(`${dateKey} ${data?.metaData?.PickupTime}`, 'YYYY-MM-DD hh:mm A');
    const relativePickupTime = isToday ? getCustomRelativeTime(pickupDateTime) : data?.metaData?.PickupTime;

    return (
        <>
            <Box onClick={() => data?.status === "accepted" ? () => { } : method(data)} className={`${checkedData.some(s => s?.id === data?.id) ? 'seniorCard shadow' : 'seniorCard'}`} mb={1}>
                <Box className="sec1">
                    {
                        data?.status === "accepted" ?
                            <Box sx={{ margin: "2px 9px 0px 1px" }}>
                                {/* <DoneIcon sx={{ color: "green" }} /> */}
                            </Box>
                            :
                            <Checkbox
                                color='info'
                                checked={checkedData.some(s => s?.id === data?.id)}
                            />
                    }
                    <Avatar sx={{ width: "45px", height: "45px" }} alt="Remy Sharp" src={require('../../Assets/images/greyCircle.png')} />
                    <Box sx={{ marginLeft: "10px" }} onClick={() => navigate("/seniorprofile", { state: { data, checkedData, isToday, dateKey } })}>
                        <Typography className="seniorName">{data?.metaData?.SeniorName}</Typography>
                        <Typography className='wordBreak'>
                            {(data?.status === "accepted" && isToday) ? relativePickupTime : data?.metaData?.DropOffLocationName}
                        </Typography>
                        {
                            (data?.status === "accepted" && isToday) ?
                                <>
                                    <Typography className='wordBreak'>
                                        Pick: {data?.metaData?.PickUpLocationName}
                                    </Typography>
                                    <Typography className='wordBreak'>
                                        Drop: {data?.metaData?.DropOffLocationName}
                                    </Typography>
                                </>
                                : ""
                        }
                    </Box>
                </Box>
                <Box className="sec2">
                    <Typography className='subTitle' variant='body2' sx={{ textAlign: "right" }}>{data?.metaData?.PickupTime}</Typography>
                    <Typography sx={{ marginTop: "5px", textAlign: "right" }} color={"black"} variant="body2" className='subTitle'>{data?.metaData?.RideDurationMinutes} {data?.metaData?.RideDurationMinutes > 1 ? 'minutes' : 'minute'}</Typography>
                </Box>
            </Box>
        </>
    )
}


function RelativeTime({ dateString }) {
    const currentDate = new Date();
    const givenDate = new Date(dateString);

    // Resetting the time part to compare only the date
    currentDate.setHours(0, 0, 0, 0);
    givenDate.setHours(0, 0, 0, 0);

    const dayOfWeek = currentDate.getDay();
    const startOfWeek = new Date(currentDate);
    const endOfWeek = new Date(currentDate);

    // Set start of the week to Monday
    startOfWeek.setDate(currentDate.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1));
    startOfWeek.setHours(0, 0, 0, 0);

    // Set end of the week to Sunday
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999);

    const nextWeekStart = new Date(startOfWeek);
    nextWeekStart.setDate(startOfWeek.getDate() + 7);

    const nextWeekEnd = new Date(endOfWeek);
    nextWeekEnd.setDate(endOfWeek.getDate() + 7);

    const timeDiff = givenDate.getTime() - currentDate.getTime();
    const oneDay = 24 * 60 * 60 * 1000; // milliseconds in a day
    const oneWeek = 7 * oneDay;
    const oneMonth = 30 * oneDay; // Approximate value for a month

    if (timeDiff === 0) {
        return 'Today';
    } else if (timeDiff === oneDay) {
        return 'Tomorrow';
    } else if (givenDate >= startOfWeek && givenDate <= endOfWeek) {
        return 'This Week';
    } else if (givenDate >= nextWeekStart && givenDate <= nextWeekEnd) {
        return 'Next Week';
    } else if (timeDiff < oneMonth && timeDiff >= 0) {
        return 'This Month';
    } else if (timeDiff < 2 * oneMonth && timeDiff >= 0) {
        return 'Next Month';
    } else {
        return 'Future';
    }
}

