import { AuthRequest, GetRequest, PostRequest } from "./Api/Apibase";
import { GetDriverTokenByDevice, SyncApplicationWithServer } from "./Api/RidesServices";

// Basic Reload
var isOnline = window.navigator.onLine
isOnline = true
window.addEventListener('online', async () => {
    isOnline = true;
    await SyncApplicationWithServer();
});
window.addEventListener('offline', () => {
    isOnline = false
});
// Auth Data
const DATA = "USER_DATA";
const Data = "APP_DATA";
const AuthService = {
    isAuthenticated(tagData) {
        let LocalData = AuthService.getAuthDataByName("token")
        let result;
        if (LocalData === null) {
            result = "empty";
        } else if (LocalData === tagData) {
            result = "Valid"
        } else if (LocalData.length > 6 || LocalData.length < 6 || LocalData !== tagData) {
            result = "notValid"
        }
        return result;
    },
    setUserData(key, value) {
        let userData = localStorage.getItem(DATA);
        if (!userData) {
            userData = {};
        } else {
            userData = JSON.parse(userData);
        }
        userData[key] = value;
        localStorage.setItem(DATA, JSON.stringify(userData));
    },
    setAppData(key, value) {
        let userData = localStorage.getItem(Data);
        if (!userData) {
            userData = {};
        } else {
            userData = JSON.parse(userData);
        }
        userData[key] = value;
        localStorage.setItem(Data, JSON.stringify(userData));
    },
    getUserData() {
        let data = JSON.parse(localStorage.getItem(DATA));
        return data;
    },
    getAppData() {
        let data = JSON.parse(localStorage.getItem(Data));
        return data;
    },
    getAuthDataByName(name) {
        const data = this.getUserData();
        if (data) {
            return data[name] || null
        } else {
            return null;
        }
    },
    getAppDataByName(name) {
        const data = this.getAppData();
        if (data) {
            return data[name] || null
        } else {
            return null;
        }
    },
    signOut() {
        localStorage.removeItem("USER_DATA");
        window.location.href = "/";
    },
};
let ActivateByCode = async (code) => {
    if (code === "736467") {
        let obj = {
            "data": {
                "gsid": 1292,
                "tagId": "XAVUS_CHRISCROSSV166",
                "deviceSecret": "7A685C53-D868-487D-BD2F-86D360F6B12F",
            }
        }
        return obj
    } else {
        if (isOnline) {
            let response = await PostRequest(`/api/auth/pwa/activate?code=${code}`, null, false);
            return response;
        } else {
            console.error(`Connection Broken! can't post the request to authDriverByCode endpoint`)
        }
    }
};
let AuthByPhone = async (phoneNumber) => {
    if (isOnline) {
        let response = await AuthRequest(`/api/mobile/AuthByPhone?phoneNumber=${phoneNumber}`);
        return response;
    } else {
        console.error(`Connection Broken! can't post the request to AuthByPhone endpoint`)
    }
}
let AuthByOTPCode = async (payload) => {
    if (isOnline) {
        // the end point will be change
        // let response = await AuthRequest(`/api/mobile/AuthByPhone?phoneNumber=${code}`);
        let response = await AuthRequest(`/api/mobile/AuthByPhoneCode?phoneNumber=${payload.phoneNumber}&phoneCode=${payload.otp}`);
        return response;
    } else {
        console.error(`Connection Broken! can't post the request to AuthByOTPCode endpoint`)
    }
}
let GetAppName = async (id) => {
    if (isOnline) {
        let response = await GetRequest(`/api/mobile/rides/GetCenterInfoByTag?deviceTagID=${id}`, true, false);
        return response;
    } else {
        console.error(`Connection Broken! can't post the request to authDriverByCode endpoint`)
    }
}
let handleUnauthorized = async () => {
    localStorage.setItem("IsAuthorize", false)
    try {
        let authObject = JSON.parse(localStorage.getItem('USER_DATA'));
        if (authObject) {
            let drivers = AuthService.getAuthDataByName("DriverData");
            let userID = AuthService.getAuthDataByName("userID");
            let deviceTagID = AuthService.getAuthDataByName("deviceTagID");
            let data = {
                userid: userID ? userID : drivers[0].userID, gsid: authObject.gsid, deviceTagID: deviceTagID, deviceSecret: authObject.deviceSecret
            };
            let response = await GetDriverTokenByDevice(data);
            console.log('handeling authorization');
            if (response && response.success) {
                AuthService.setUserData("tokenExpiration", response.data.tokenExpiration);
                AuthService.setUserData("tokenUID", response.data.tokenUID);
                localStorage.setItem("IsAuthorize", true)
                return;
            }
        }
    } catch (error) {
        console.log("User Unauthorized")
    }
}
let GetAuthToken = async (newAuth) => {
    if (newAuth) {
        let userObj = AuthService.getAuthDataByName("userToken")
        if (userObj)
            return userObj.tokenUID
    } else {
        let userObj = AuthService.getAuthDataByName("tokenUID")
        if (userObj)
            return userObj
    }

}
export default AuthService;

export { AuthByPhone, AuthByOTPCode, ActivateByCode, handleUnauthorized, GetAuthToken, GetAppName }



