import { createTheme } from '@mui/material/styles';

export const lightTheme = createTheme({
    palette: {
        type: 'light',
        primary: {
            light: '#cccccc',
            main: '#c4c4c4',//main background rides
            dark: '#000524',
            contrastText: '#FFFFFF',
        },
        secondary: {
            light: '#3d5970',//live pessenger button color
            main: '#3aa7f7',//notify button
            dark: '#ff9800',// edit button
            contrastText: '#FFFFFF'
        },
        warning: {
            light: "#db4b4b",
            main: '#fbbe24',
            dark: '#00071e',
            contrastText: '#FFFFFF',
            hover: "#ffcd4d"
        },
        error: {
            light: "#db4b4b",
            main: '#db4b4b',
            dark: '#00071e',
            contrastText: '#FFFFFF',
            hover: "#ff5a5a"
        },
        sidebarLink: {
            main: '#0313cbfa', //links
            hover: '#a2a7bb',
            active: '#FFFFFF',
        },
        background: {
            white: '#000000',//background all
            black: '#FFFFFF',
            gray: '#f6f6f6'
        },
        button: {
            light: "#d2f3d3",
            main: "#4caf50", //all button color green
        },
        other: {
            gray: '#DADADA',
            darkgray: '#000000',
            main: "#000000",
            text: "#FFFFFF"
        },
        mix: {
            gradientPrimary: '#f4e0e9',
            gradientDark: 'radial-gradient( circle farthest-corner at 50.3% 44.5%,  rgba(116,147,179,1) 0%, rgba(62,83,104,1) 100.2% )',
            gradientLight: 'radial-gradient( circle 827px at 47.3% 48%,  rgba(255,255,255,1) 0%, rgb(160 160 160) 90% )'
        }
    },
    typography: {
        fontFamily: ['Poppins", Segoe UI"', 'sans-serif'].join(','),
        letterSpacing: '-0.5px',
        h1: {
            fontSize: "24px", //34PX
            letterSpacing: 1.5,
            textShadow: "0 0 1px",
        },
        h2: {
            fontSize: "22px", //32PX
            letterSpacing: 1.5,
            textShadow: "0 0 1px",
        },
        h3: {
            fontSize: "21px", //30PX
            letterSpacing: 1.5,
            textShadow: "0 0 1px",
        },
        h4: {
            fontSize: "20px", //28PX
            fontWeight: 600,
            letterSpacing: 1.5,
            textShadow: "0 0 1px",
        },
        h5: {
            fontSize: "19px", //24PX
            fontWeight: 600,
            letterSpacing: 1.5,
            textShadow: "0 0 1px",
        },
        h6: {
            fontSize: "12.8px", //22PX
            fontWeight: '600',
            lineHeight: 1
        },
        subtitle1: {
            fontSize: "16px",//20PX
            fontWeight: '600',
        },
        subtitle2: {
            fontSize: "16px", //16PX
        },
        body1: {
            fontSize: "14px",//16PX
            fontWeight: 'bold',
        },
        body2: {
            fontSize: "14px",//16PX
        },
        body3: {
            fontSize: "12px",//16PX
        },
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    // Grid Overides
                    '& .MuiDataGrid-filterForm': {
                        flexDirection: 'column',
                        '& > .MuiFormControl-root': {
                            width: '100%',
                            '&.MuiDataGrid-filterFormDeleteIcon': {
                                background: 'radial-gradient( circle 827px at 47.3% 48%,  rgba(255,255,255,1) 0%, rgb(160 160 160) 90% )',
                                border: '1px solid',
                                position: 'relative',
                                marginRight: 0,
                                marginBottom: 5,
                                '&:after': {
                                    content: '"Filter:"',
                                    position: 'absolute',
                                    top: 2,
                                    left: 6,
                                    fontWeight: '600',
                                },
                                '& .MuiButtonBase-root': {
                                    marginLeft: 'auto',
                                }
                            }
                        }
                    },
                }
            },
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    '& .MuiDataGrid-columnHeaders': {
                        background: 'radial-gradient( circle farthest-corner at 50.3% 44.5%,  #535878 0%, #292f4c 100.2% )',
                        color: '#fff',
                        '& .MuiDataGrid-columnHeader, & .MuiDataGrid-columnHeaderDraggableContainer': {
                            '&:focus': {
                                outline: 'none',
                            },
                            '& .MuiIconButton-root': {
                                color: '#fff',
                            }
                        },
                        '& .MuiDataGrid-columnSeparator': {
                            display: 'none'
                        },
                    },
                    '& .MuiDataGrid-virtualScroller': {
                        '& .MuiDataGrid-row': {
                            '& .MuiDataGrid-cell': {
                                '&:focus': {
                                    outline: 'none',
                                },
                            },
                            '& a': {
                                color: '#292f4c'
                            }
                        }
                    }
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    letterSpacing: '0.2px',
                    '&.anchor-link': {
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        '&:hover': {
                            textDecoration: 'none'
                        }
                    },
                    "&[data-error]": {
                        color: "#FF0000",
                        letterSpacing: 0,
                        lineHeight: 1.3,
                    },
                    '& *[data-color-success="true"]': {
                        color: '#2E7D32'
                    },
                    '& *[data-color-error="true"]': {
                        color: '#ff0000'
                    },
                    '& *[data-color-warning="true"]': {
                        color: '#ff943c'
                    },
                    '& *[data-color-info="true"]': {
                        color: '#0288D1'
                    },

                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: ['Poppins", Segoe UI"', 'sans-serif'].join(','),
                    textTransform: 'none',
                    '&[data-mw="150"]': {
                        minWidth: 150,
                    },
                    '&[data-btn-xs="true"]': {
                        minWidth: 0,
                        padding: "2px 6px",
                        fontSize: "0.8rem",
                    },

                },
            },

        },
        MuiFilledInput: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                    marginTop: 0,
                    width: '100%',
                    marginBottom: 24,
                    '& .MuiFormControl-root': {
                        marginBottom: 0
                    },
                    '& .MuiOutlinedInput-root': {
                        fontSize: 12,

                    },
                    '& .MuiFormHelperText-root.Mui-error': {
                        color: '#FF0000',
                        letterSpacing: 0,
                        lineHeight: 1.3,
                    }
                },
            },
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiFormHelperText: {
            defaultProps: {
                margin: 'dense',
            },
            styleOverrides: {
                root: {
                    fontSize: "0.875rem",
                    margin: 0
                }
            }
        },
        MuiIconButton: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiInputBase: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiInputLabel: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiListItem: {
            defaultProps: {
                dense: true,
            },
        },
        MuiOutlinedInput: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiFab: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiTable: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-input': {
                        fontSize: "12px",
                        fontWeight: "400",
                        padding: "4px 10px",
                    },
                },
            },
        },
        MuiToolbar: {
            defaultProps: {
                variant: 'dense',
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    '& .MuiTab-root': {
                        flex: 1,
                        textTransform: 'none',
                        color: '#194242',
                    },
                    '& .MuiTabs-indicator': {
                        backgroundColor: '#194242',
                    }
                }
            }
        },
        MuiTabPanel: {
            styleOverrides: {
                root: {
                    padding: '0',
                    '& .MuiPaper-root': {
                        padding: '6px 0',
                        backgroundColor: '#fff',
                        borderBottom: '1px solid',
                        borderColor: '#DADADA',
                        margin: 0,
                        '& .MuiAccordionSummary-root': {
                            padding: 0,
                            '& .MuiAccordionSummary-content': {
                                '& svg': {
                                    marginRight: 10
                                },
                                '&.Mui-expanded': {
                                    margin: '8px 0'
                                }
                            },
                            '&.Mui-expanded': {
                                minHeight: 45
                            }
                        },
                        '&::before': {
                            height: 0
                        }
                    }
                }
            }
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    color: '#194242',
                    '&.Mui-expanded': {
                        margin: 0
                    },
                    '& .MuiTypography-body1': {
                        fontSize: '0.875rem'
                    },
                    '& .MuiAccordionDetails-root': {
                        padding: 0,
                        '& div': {
                            display: 'flex',
                            alignItems: 'center',
                            padding: '10px 0',
                            cursor: 'pointer'
                        },
                        '& .MuiTypography-body2': {
                            flex: 2,
                            marginLeft: 20
                        }
                    }
                }
            }
        },
    },

});
export const darkTheme = createTheme({
    palette: {
        type: 'dark',
        primary: {
            light: '#499aa1',
            main: '#045a60',//main background rides
            dark: '#000524',
            contrastText: '#FFFFFF',
        },
        secondary: {
            light: '#60b6fd',//live pessenger button color
            main: '#3aa7f7',//notify button
            dark: '#ff9800',// edit button
            contrastText: '#FFFFFF'
        },
        warning: {
            light: "#db4b4b",
            main: '#fbbe24',
            dark: '#00071e',
            contrastText: '#FFFFFF',
            hover: "#ffcd4d"
        },
        error: {
            light: "#db4b4b",
            main: '#db4b4b',
            dark: '#00071e',
            contrastText: '#FFFFFF',
            hover: "#ff5a5a"
        },
        sidebarLink: {
            main: '#0313cbfa', //links
            hover: '#a2a7bb',
            active: '#FFFFFF',
        },
        background: {
            white: '#FFFFFF',//background all
            black: '#000000',
            gray: '#f6f6f6'
        },
        button: {
            light: "#d2f3d3",
            main: "#4caf50", //all button color green
            warning: "4caf50"
        },
        other: {
            gray: '#DADADA',
            darkgray: '#707070',
            main: "#00807C",
            text: "#045a60"
        },
        mix: {
            gradientPrimary: '#f4e0e9',
            gradientDark: 'radial-gradient( circle farthest-corner at 50.3% 44.5%,  rgba(116,147,179,1) 0%, rgba(62,83,104,1) 100.2% )',
            gradientLight: 'radial-gradient( circle 827px at 47.3% 48%,  rgba(255,255,255,1) 0%, rgb(160 160 160) 90% )'
        }
    },
    typography: {
        fontFamily: ['Poppins", Segoe UI"', 'sans-serif'].join(','),
        letterSpacing: '-0.5px',
        h1: {
            fontSize: "24px", //34PX
            letterSpacing: 1.5,
            textShadow: "0 0 1px",
        },
        h2: {
            fontSize: "22px", //32PX
            letterSpacing: 1.5,
            textShadow: "0 0 1px",
        },
        h3: {
            fontSize: "21px", //30PX
            letterSpacing: 1.5,
            textShadow: "0 0 1px",
        },
        h4: {
            fontSize: "20px", //28PX
            fontWeight: 600,
            letterSpacing: 1.5,
            textShadow: "0 0 1px",
        },
        h5: {
            fontSize: "19px", //24PX
            fontWeight: 600,
            letterSpacing: 1.5,
            textShadow: "0 0 1px",
        },
        h6: {
            fontSize: "12.8px", //22PX
            fontWeight: '600',
            lineHeight: 1
        },
        subtitle1: {
            fontSize: "16px",//20PX
            fontWeight: '600',
        },
        subtitle2: {
            fontSize: "16px", //16PX
        },
        body1: {
            fontSize: "14px",//16PX
            fontWeight: 'bold',
        },
        body2: {
            fontSize: "14px",//16PX
        },
        body3: {
            fontSize: "12px",//16PX
        },
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    // Grid Overides
                    '& .MuiDataGrid-filterForm': {
                        flexDirection: 'column',
                        '& > .MuiFormControl-root': {
                            width: '100%',
                            '&.MuiDataGrid-filterFormDeleteIcon': {
                                background: 'radial-gradient( circle 827px at 47.3% 48%,  rgba(255,255,255,1) 0%, rgb(160 160 160) 90% )',
                                border: '1px solid',
                                position: 'relative',
                                marginRight: 0,

                                marginBottom: 5,
                                '&:after': {
                                    content: '"Filter:"',
                                    position: 'absolute',
                                    top: 2,
                                    left: 6,
                                    fontWeight: '600',
                                },
                                '& .MuiButtonBase-root': {
                                    marginLeft: 'auto',
                                }
                            }
                        }
                    },
                }
            },
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    '& .MuiDataGrid-columnHeaders': {
                        background: 'radial-gradient( circle farthest-corner at 50.3% 44.5%,  #535878 0%, #292f4c 100.2% )',
                        color: '#fff',
                        '& .MuiDataGrid-columnHeader, & .MuiDataGrid-columnHeaderDraggableContainer': {
                            '&:focus': {
                                outline: 'none',
                            },
                            '& .MuiIconButton-root': {
                                color: '#fff',
                            }
                        },
                        '& .MuiDataGrid-columnSeparator': {
                            display: 'none'
                        },
                    },
                    '& .MuiDataGrid-virtualScroller': {
                        '& .MuiDataGrid-row': {
                            '& .MuiDataGrid-cell': {
                                '&:focus': {
                                    outline: 'none',
                                },
                            },
                            '& a': {
                                color: '#292f4c'
                            }
                        }
                    }
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    letterSpacing: '0.2px',
                    '&.anchor-link': {
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        '&:hover': {
                            textDecoration: 'none'
                        }
                    },
                    "&[data-error]": {
                        color: "#FF0000",
                        letterSpacing: 0,
                        lineHeight: 1.3,
                    },
                    '& *[data-color-success="true"]': {
                        color: '#2E7D32'
                    },
                    '& *[data-color-error="true"]': {
                        color: '#ff0000'
                    },
                    '& *[data-color-warning="true"]': {
                        color: '#ff943c'
                    },
                    '& *[data-color-info="true"]': {
                        color: '#0288D1'
                    },

                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: ['Poppins", Segoe UI"', 'sans-serif'].join(','),
                    textTransform: 'none',
                    '&[data-mw="150"]': {
                        minWidth: 150,
                    },
                    '&[data-btn-xs="true"]': {
                        minWidth: 0,
                        padding: "2px 6px",
                        fontSize: "0.8rem",
                    },

                },
            },

        },
        MuiFilledInput: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                    marginTop: 0,
                    width: '100%',
                    marginBottom: 24,
                    '& .MuiFormControl-root': {
                        marginBottom: 0
                    },
                    '& .MuiOutlinedInput-root': {
                        fontSize: 12,

                    },
                    '& .MuiFormHelperText-root.Mui-error': {
                        color: '#FF0000',
                        letterSpacing: 0,
                        lineHeight: 1.3,
                    }
                },
            },
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiFormHelperText: {
            defaultProps: {
                margin: 'dense',
            },
            styleOverrides: {
                root: {
                    fontSize: "0.875rem",
                    margin: 0
                }
            }
        },
        MuiIconButton: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiInputBase: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiInputLabel: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiListItem: {
            defaultProps: {
                dense: true,
            },
        },
        MuiOutlinedInput: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiFab: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiTable: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-input': {
                        fontSize: "12px",
                        fontWeight: "400",
                        padding: "4px 10px",
                    },
                },
            },
        },
        MuiToolbar: {
            defaultProps: {
                variant: 'dense',
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    '& .MuiTab-root': {
                        flex: 1,
                        textTransform: 'none',
                        color: '#194242',
                    },
                    '& .MuiTabs-indicator': {
                        backgroundColor: '#194242',
                    }
                }
            }
        },
        MuiTabPanel: {
            styleOverrides: {
                root: {
                    padding: '0',
                    '& .MuiPaper-root': {
                        padding: '6px 0',
                        backgroundColor: '#fff',
                        borderBottom: '1px solid',
                        borderColor: '#DADADA',
                        margin: 0,
                        '& .MuiAccordionSummary-root': {
                            padding: 0,
                            '& .MuiAccordionSummary-content': {
                                '& svg': {
                                    marginRight: 10
                                },
                                '&.Mui-expanded': {
                                    margin: '8px 0'
                                }
                            },
                            '&.Mui-expanded': {
                                minHeight: 45
                            }
                        },
                        '&::before': {
                            height: 0
                        }
                    }
                }
            }
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    color: '#194242',
                    '&.Mui-expanded': {
                        margin: 0
                    },
                    '& .MuiTypography-body1': {
                        fontSize: '0.875rem'
                    },
                    '& .MuiAccordionDetails-root': {
                        padding: 0,
                        '& div': {
                            display: 'flex',
                            alignItems: 'center',
                            padding: '10px 0',
                            cursor: 'pointer'
                        },
                        '& .MuiTypography-body2': {
                            flex: 2,
                            marginLeft: 20
                        }
                    }
                }
            }
        },
    },

});









