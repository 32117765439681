import React, { useState, useEffect } from 'react';
import { AppBar, Box, Button, FormControl, Typography, Toolbar, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useNavigate } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const Root = styled(Box)(({ theme }) => ({
    height: '100%',
    padding: 0,

    '& .header-cont': {
        // width: "100%",
        width: "device-width",
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        "& .MuiToolbar-root": {
            padding: "0px 15px 0px 45px"
        },
        "& header": {
            display: "flex",
            height: 48,
            background: theme.palette.primary.contrastText,
            fontSize: 12,
            alignItems: "center",
            zIndex: "99",
            '& img[alt="Logo"]': {
                width: 34,
                filter: 'invert(1)',
            },
            "& .headerNav": {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                "& .pageMainTitle": {
                    fontSize: 15,
                    fontWeight: 600,
                    whiteSpace: "nowrap"
                },
                "& .headerText": {
                    display: "flex",
                    color: '#1f0757',
                    fontSize: 13,
                    textShadow: '#1f0757',
                    fontWeight: 600,
                    whiteSpace: "nowrap"
                },
                "& .refreshingIcon": {
                    color: "Grey"
                },
                "& .reloadBtn": {
                    padding: "0px 0px 4px 0px"
                }

            },

        },
    },
    '& .mainContainer': {
        backgroundImage: `url(${require('../../Assets/loginBackgroundImg/img1.png')})`,
        backgroundSize: "cover",
        overflow: "hidden",
        backgroundPosition: "center",
        height: "calc(100vh - 48px)",
        marginTop: 48,
        overflowY: "auto",

        "& .nextBtn": {
            width: "80%",
            borderRadius: "40px",
            padding: "16px 14px",
            margin: "20px 0px",
            background: theme.palette.button.main,
            borderColor: theme.palette.button.main,
            color: theme.palette.background.black,
            fontSize: 18,
            fontWeight: 600,
            "&:hover": {
                background: theme.palette.button.main,
                borderColor: theme.palette.button.main,
                color: theme.palette.background.black,
            },
        },
        "& .routeBox": {
            margin: "20px auto",
            display: "flex",
            width: "95%",
            minWidth: "80%",
            gap: 8,
            border: "none",
            backgroundColor: theme.palette.primary.contrastText,
            padding: "14px 10px",
            borderRadius: 10,
            flexDirection: "column",
            justifyContent: "space-between",
            // alignItems: "center",
            cursor: "pointer",
            boxShadow: "1px 2px 7px 2px #cecccc",
            "& .routeText": {
                display: "flex",
                flexDirection: "column",
            },

            "& .selectroutetextColor": {
                fontSize: 20,
                fontWeight: 600,
                color: "#00807c",
            },
            "& .routetextColor": {
                fontSize: 20,
                fontWeight: 400,
                color: "#757575",
            },

            "& .selectRadioColor": {
                "& .MuiFormControlLabel-root": {
                    margin: 0,
                    marginTop: 2,
                },
                "& .MuiSvgIcon-root": {
                    color: "#00807c",
                },

            },
            "& .radioBtn": {
                "& .MuiFormControlLabel-root": {
                    margin: 0,
                    marginTop: 2,
                },
                "& .MuiSvgIcon-root": {
                    color: "#00807c",
                },
            },
            "& .routeBoxInner": {
                display: "flex",
                gap: 12,
                alignItems: "center",
                marginLeft: 0,
            },
            "& .timeBox": {
                // border: "1px solid #00807c",
                padding: "6px",
                borderRadius: "5px",
                display: "inline-block",
                marginRight: 8,
                "& .MuiFormControlLabel-root": {
                    marginRight: 0,
                    "& .MuiButtonBase-root": {
                        padding: "0px 0px 0px 3px!important",
                    },
                },
            },
            "& .selectedtimeBox": {
                // border: "1px solid #00807c",
                padding: "6px",
                borderRadius: "5px",
                display: "inline-block",
                marginRight: 8,
                // backgroundColor: "#00807c",
                color: "#00807c",
                "& .MuiFormControlLabel-root": {
                    marginRight: 0,
                    "& .MuiButtonBase-root": {
                        padding: "0px 0px 0px 3px!important",
                        "& .MuiSvgIcon-root": {
                            color: "#00807c",
                        },
                    },
                },
            }
        },
        "& .selectedtimeBox2": {
            // border: "1px solid #00807c",
            padding: "6px",
            borderRadius: "5px",
            marginRight: 8,
            // backgroundColor: "#00807c",
            // color: "#00807c",
            "& .MuiFormControlLabel-root": {
                marginRight: 0,
                "& .MuiButtonBase-root": {
                    padding: "4px 8px 4px 3px!important",
                    "& .MuiSvgIcon-root": {
                        color: "#00807c",
                    },
                },
            },
        }

    },
    '& img[alt="heartpng"]': {
        width: 50,
    },
}));


function ScheduleDays() {
    const [state, setState] = useState([]);
    //else create a new


    const navigate = useNavigate()
    var weekData = [{
        Day: 'Monday',
        Selected: false,
        Option: [{ Selected: false, Name: 'Mornings' }, { Selected: false, Name: 'Afternoons' }, { Selected: false, Name: 'Evenings' }]
    }
        , {
        Day: 'Tuesday',
        Selected: false,
        Option: [{ Selected: false, Name: 'Mornings' }, { Selected: false, Name: 'Afternoons' }, { Selected: false, Name: 'Evenings' }]
    }, {
        Day: 'Wednesday',
        Selected: false,
        Option: [{ Selected: false, Name: 'Mornings' }, { Selected: false, Name: 'Afternoons' }, { Selected: false, Name: 'Evenings' }]
    },
    {
        Day: 'Thursday',
        Selected: false,
        Option: [{ Selected: false, Name: 'Mornings' }, { Selected: false, Name: 'Afternoons' }, { Selected: false, Name: 'Evenings' }]
    },
    {
        Day: 'Friday',
        Selected: false,
        Option: [{ Selected: false, Name: 'Mornings' }, { Selected: false, Name: 'Afternoons' }, { Selected: false, Name: 'Evenings' }]
    },
    {
        Day: 'Saturday',
        Selected: false,
        Option: [{ Selected: false, Name: 'Mornings' }, { Selected: false, Name: 'Afternoons' }, { Selected: false, Name: 'Evenings' }]
    },
    {
        Day: 'Sunday',
        Selected: false,
        Option: [{ Selected: false, Name: 'Mornings' }, { Selected: false, Name: 'Afternoons' }, { Selected: false, Name: 'Evenings' }]
    }]
    useEffect(() => {
        const selectedOptions = JSON.parse(localStorage.getItem("selectedSchedule"));
        const finalData = JSON.parse(localStorage.getItem("FinalSchedule"));
        if (finalData?.length) {
            setState(finalData)
            return
        }
        if (selectedOptions?.length) {
            const updatedWeekData = weekData.map(dayObj => {
                return {
                    ...dayObj,
                    Option: dayObj.Option.map(option => {
                        if (selectedOptions.includes(option.Name)) {
                            return {
                                ...option,
                                Selected: true
                            };
                        }
                        return option;
                    })
                };
            });
            setState(updatedWeekData);
        } else {
            setState(weekData)
        }
    }, [])

    const handleSelection = (e, day) => {
        e.stopPropagation()

        const updatedStateData = state.map(dayObj => {
            if (dayObj.Day === day) {
                return {
                    ...dayObj,
                    Selected: !dayObj.Selected
                };
            }
            return dayObj;
        });

        setState(updatedStateData);
    };
    const handleSelectionTime = (e, day, time) => {
        e.stopPropagation();
        // Find the current day's object in the state
        const updatedStateData = state.map(dayObj => {
            if (dayObj.Day === day) {
                return {
                    ...dayObj,
                    Option: dayObj.Option.map(option => {
                        if (option.Name === time) {
                            return {
                                ...option,
                                Selected: !option.Selected
                            };
                        }
                        return option; // Return the original option if it doesn't match
                    })
                };
            }
            return dayObj;
        });

        setState(updatedStateData);
    };

    const handleNext = () => {
        localStorage.setItem("FinalSchedule", JSON.stringify(state))
        navigate("/review")
        // console.log(state)
    }
    return (
        <Root p={2} >
            <Box className="header-cont">
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static">
                        <Toolbar sx={{ width: "100%", maxWidth: "100%", }}>
                            <Box className="headerNav">
                                <Box
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2, display: "inline-flex", alignItems: "center" }}
                                >
                                    {/* {logo ?
                                        <img src={logo} alt="Logo" style={{ marginRight: 10 }} />
                                        : */}
                                    <img src={require('../../Assets/icons/msc_heart.png')} alt="Logo" style={{ marginRight: 10 }} />
                                    {/* } */}
                                    <Box>
                                        <Typography color="black" className="pageMainTitle" id="pageTitle">
                                            {/* {appName} */}
                                            My Senior Center
                                        </Typography>
                                        <Typography variant="h6" component="span" className="headerText">Schedule Preferences</Typography>
                                    </Box>
                                </Box>
                                {/* <IconButton className="reloadBtn" onClick={() => GetRideData()} >
                                    {responceRide ? <CircularProgress size={20} className='refreshingIcon' /> : <RefreshIcon size={20} />}
                                </IconButton> */}
                            </Box>
                        </Toolbar>
                    </AppBar>
                </Box>
            </Box>
            <Box className="mainContainer" >
                <Box sx={{ height: "calc(100vh - 153px)", overflowY: "auto" }} >
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "95%", margin: "10px auto" }}>
                        <IconButton onClick={() => { navigate('/time') }}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant='subtitle1' onClick={() => { navigate('/review') }} sx={{ color: "#757575", margin: "14px 13px 0px 0px", textAlign: "end" }}>Skip</Typography>
                    </Box>
                    <Box>
                    </Box>
                    {state?.items?.length === 0 ? (
                        <Box sx={{ height: 50, textAlign: "center" }}>
                            <Typography variant="subtitle1" sx={{ marginTop: 10 }}> Loading.....! </Typography>
                        </Box>
                    ) : (
                        state?.map((e, i) => (
                            <Box className="routeBox" key={i} >
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                    <Box className="routeBoxInner">
                                        {/* <Box className="image"> <img width={50} src={require("../../Assets/images/driver-icon.png")} alt="heartpng" /> </Box> */}
                                        <Box className="routeText">
                                            <Typography className={e.Selected ? "selectroutetextColor" : "routetextColor"} variant="h1"> {e.Day} </Typography>
                                        </Box>
                                    </Box>
                                    <Box className={e.Selected ? "selectRadioColor" : "radioBtn"}>
                                        <FormControl sx={{ marginBottom: 0 }}>
                                            <Checkbox onChange={(event) => handleSelection(event, e.Day)} checked={e.Selected} />
                                        </FormControl>
                                    </Box>
                                </Box>
                                {e.Selected && (
                                    <Box >
                                        <Typography variant="subtitle2">Best time of Day</Typography>
                                        {e.Option?.map((ele, ind) => (
                                            <Box key={ind} className={ele.Selected ? "selectedtimeBox" : "timeBox"}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={ele.Selected}
                                                            onChange={(event) => handleSelectionTime(event, e.Day, ele.Name)}
                                                        />
                                                    }
                                                    label={<Typography variant="body2">{ele.Name}</Typography>}
                                                />
                                            </Box>
                                        ))}
                                    </Box>
                                )}

                            </Box>
                        ))

                    )}
                    <Box sx={{
                        width: "95%",
                        minWidth: "80%",
                        margin: "auto",
                        marginTop: "14px"
                    }}>
                        <Typography variant='body2' sx={{ fontSize: "16px", fontWeight: 600, marginBottom: 3 }}>How often do you want to be contacted when new volunteer opportunities are available?</Typography>
                        <Box className="selectedtimeBox2">
                            <FormControlLabel control={<Checkbox checked={true} />} label={<Typography variant='subtitle2'> Allow center staff to schedule me</Typography>} />
                        </Box>

                        {/* <Button className="nextBtn" fullWidth onClick={handleNext} > Next </Button> */}
                    </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button className="nextBtn" onClick={handleNext} > Next </Button>
                </Box>

            </Box>
        </Root >
    )
}

export default ScheduleDays