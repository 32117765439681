import React, { forwardRef } from 'react';
import InputMask from 'react-input-mask';

const Mask = forwardRef((props, ref) => {
    const { value, onChange, ...otherProps } = props;

    return (
        <InputMask
            alwaysShowMask={false}
            mask="(999) 999-9999"
            value={value}
            onChange={onChange}
            {...otherProps}
        >
            {(inputProps) => <input {...inputProps} ref={ref} />}
        </InputMask>
    );
});

export default Mask;
