import React, { useEffect, useState } from 'react';
import { AppBar, Box, Divider, IconButton, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Helpers from '../../Shell/Helper';
import { GetRoster } from '../../Services/Api/AppService';

const Root = styled(Box)(({ theme }) => ({
    height: '100%',
    padding: 0,
    '& .header-cont': {
        // width: "100%",
        width: "device-width",
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        "& header": {
            display: "flex",
            height: 48,
            background: "white",
            fontSize: 12,
            alignItems: "center",
            '& img[alt="Logo"]': {
                width: 34,
                // filter: 'invert(1)',
            },
            "& .headerNav": {
                // marginRight: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                "& .fuel": {
                    color: "red",
                    position: "absolute",
                    width: "26px",
                    right: "62px",
                    top: "12px",
                    cursor: "pointer"
                },
                "& .pageMainTitle": {
                    fontSize: 15,
                    fontWeight: 600,
                    whiteSpace: "nowrap"
                },
                "& .headerText": {
                    display: "flex",
                    color: '#1f0757',
                    fontSize: 13,
                    textShadow: '#1f0757',
                    fontWeight: 600,
                    whiteSpace: "nowrap"
                }
            }
        },
    },
    '& .mainContainer': {
        backgroundImage: `url(${require('../../Assets/loginBackgroundImg/img1.png')})`,
        backgroundSize: "cover",
        overflow: "hidden",
        backgroundPosition: "center",
        height: "calc(100vh - 48px)",
        marginTop: 48,
        overflowY: "auto",
        "& .routeBox": {
            margin: "10px auto ",
            display: "flex",
            width: "100",
            minWidth: 310,
            gap: 10,
            padding: "5px",
            alignItems: "center",
            "& .image": {
                height: 50,
                width: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            },

            "& .routeText": {
                display: "flex",
                flexDirection: "column",
                gap: 3
            },
            "& .routetextColor": {
                fontSize: 20,
                fontWeight: 400,
                color: theme.palette.button.main
            },
            "& .textColor": {
                fontSize: 14,
                fontWeight: 400,
                color: theme.palette.other.darkgray,
                textShadow: "0 0 gray"
            }
        },
        "& .checkIconBox": {
            padding: "0 6px",
            margin: "0px auto ",
            display: "flex",
            width: "100",
            minWidth: 310,
            backgroundColor: theme.palette.primary.contrastText,
            boxShadow: "0px 3px 15px -6px rgb(0 0 0 / 50%)",
            borderRadius: 10,
            alignItems: "center",
            justifyContent: "space-between"
        },
        "& .buttons": {
            margin: "8px 10px",
            display: 'flex',
            justifyContent: "space-between",
            gap: "10px",

            "& .btnImg": {
                border: "1px solid lightGray",
                backgroundColor: "white",
                width: 140,
                alignItems: "center",
                display: 'flex',
                flexDirection: "column",
                justifyContent: 'center',
                borderRadius: 8,
                padding: "6px 5px",
                flex: 1
            }
        },
        "& .infoCard": {
            margin: "20px auto ",
            display: "flex",
            width: "100",
            minWidth: 310,
            gap: 10,
            backgroundColor: theme.palette.primary.contrastText,
            padding: "10px",
            boxShadow: "0px 3px 15px -6px rgb(0 0 0 / 50%)",
            borderRadius: 10,
            alignItems: "center",
            "& .image": {
                height: "50px",
                width: "50px",
                borderRadius: "50%",
                overflow: "hidden",
                "& img": {
                    objectFit: "contain",
                    width: "100%",
                    height: "100%"
                }
            },
        }
    },
    "& .MainTitle1": {
        fontSize: 14,
        fontWeight: 600,
        textShadow: "black",
        whiteSpace: "nowrap"
    },
    "& .MainTitle": {
        fontSize: 15,
        fontWeight: 600,
        // whiteSpace: "nowrap"
    },
    "& .subTitle": {
        fontSize: 15,
        fontWeight: 500,
        whiteSpace: "nowrap"
    },
    '& img[alt="heartpng"]': {
        width: 50,
    },
}));
export default function ActivityDetails() {
    const [appInfo, setAppInfo] = useState({});
    const [data, setData] = useState(
        {
            roster: []
        }
    )
    let { state } = useLocation()
    let navigate = useNavigate();

    //Initial calls
    useEffect(() => {
        let appInfo = Helpers.getAppData()
        if (appInfo) {
            setAppInfo(appInfo);
        } else {
            setAppInfo({ centerName: "My Senior Center", centerLogURI: "/Assets/icons/msc_heart.png" });
        }
        console.log(state)
    }, [])
    useEffect(() => {
        if (state && state?.rostersData?.eventID) {
            GetRooster();
        }
    }, [])
    const HandleBack = () => {
        navigate('/activties');
    }
    const HandleScan = () => {
        navigate("/newscan", { state: { selectedDate: state?.selectedDate || new Date() } })
    }
    //Get Roosters
    const GetRooster = async () => {
        try {
            let res = await GetRoster(state?.rostersData?.eventID)
            if (res.success) {
                setData({ ...data, roster: res.data })

            }
        } catch (error) {
            console.log(error)
        }
    }
    const getSeniorNameOrImgByID = (sId, isFor = null) => {
        try {
            const seniors = JSON.parse(localStorage.getItem('Seniors'));
            let selectedSenior = seniors?.find(e => e.seniorID === sId)
            if (isFor === "img") {
                if (selectedSenior) {
                    return selectedSenior?.photoURL
                } else {
                    return "https://s3.amazonaws.com/myseniorcenter-tenants/_common/female.png"
                }
            } else {
                if (selectedSenior) {
                    return `${selectedSenior?.firstName} ${selectedSenior?.lastName}`
                } else {
                    return sId
                }
            }

        } catch (error) {
            console.log(error)
        }
    }
    return (
        <Root p={2} >
            {/* Header */}
            <Box className="header-cont">
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static">
                        <Toolbar
                            sx={{ width: "100%", maxWidth: "100%", padding: "0px 15px 0px 45px !important" }}>
                            <Box className="headerNav">
                                <Box
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2, display: "inline-flex", alignItems: "center" }}>
                                    {appInfo?.centerLogURI ?
                                        <img src={appInfo.centerLogURI}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = require("../../Assets/icons/msc_heart.png");
                                            }}
                                            alt="Logo" style={{ marginRight: 10 }}
                                        /> : <img src={require(`../../Assets/icons/msc_heart.png`)} alt="Logo" style={{ marginRight: 10 }}
                                        />}
                                    <div>
                                        <Typography color="black" className="pageMainTitle" id="pageTitle">
                                            {appInfo.centerName}
                                        </Typography>
                                        <Typography color="black" className="headerText" id="pageTitle">
                                            Activites
                                        </Typography>
                                    </div>

                                </Box>

                            </Box>
                        </Toolbar>
                    </AppBar>
                </Box>
            </Box>
            <Box className="mainContainer">
                <Box sx={{ margin: "4px 10px", cursor: 'pointer' }} >
                    <Box className="routeBox " >
                        <Box className="image">
                            <img src={`https://d2ap19ik6wt59k.cloudfront.net/icons/${state?.rostersData?.icon}s.png`} alt='icon' width="100%" />
                        </Box>
                        <Box className="routeText">
                            <Typography className='routetextColor' variant='h1'>{state?.rostersData?.eventName}</Typography>
                            <Typography className='textColor' variant='h5'><span style={{ fontWeight: 600 }}>Time: </span>{state?.rostersData?.startTime} - {state?.rostersData?.endTime}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Divider />
                {/* <Box sx={{ margin: "8px 10px", cursor: 'pointer' }} >
                    <Box className="checkIconBox">
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <IconButton sx={{ color: 'green' }}> <CheckCircleIcon /> </IconButton>
                            <Typography variant='subtitle2'>Benjamin New Year</Typography>
                        </Box>
                        <Typography variant='body3'>12:47 PM</Typography>
                    </Box>
                </Box> */}

                <Box className="buttons">
                    <Box className="btnImg" onClick={HandleBack}>
                        <img src={require('../../Assets/images/back-icon.png')} alt="heartpng" />
                        <Typography variant='subtitle2'>Back</Typography>
                    </Box>
                    <Box className="btnImg" onClick={HandleScan}>
                        <img src={require('../../Assets/images/scan-icon.png')} alt="heartpng" />
                        <Typography variant='subtitle2'>Scan</Typography>
                    </Box>
                </Box>
                {data?.roster?.length ?
                    data?.roster?.map((e, index) => (
                        <Box key={index} sx={{ margin: "4px 10px", cursor: 'pointer' }} >
                            <Box className="infoCard">
                                <Box className="image">
                                    <img onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = getSeniorNameOrImgByID(e.SeniorID, "img");
                                    }} src={Helpers.addSuffixToUrl(getSeniorNameOrImgByID(e.SeniorID, "img"))} alt='icon' width="100%" />
                                </Box>
                                <Box className="routeText">
                                    <Typography className="subTitle"><span style={{ fontWeight: 600 }}>{getSeniorNameOrImgByID(e.SeniorID)} </span></Typography>
                                    {/* <Typography className="subTitle" ><span style={{ fontWeight: 600 }}>Status: </span>  {e.CheckinStatus} </Typography>
                                    <Typography className="subTitle"><span style={{ fontWeight: 600 }}>Checkin Time: </span>{e.CheckinTime} </Typography> */}
                                </Box>
                            </Box>
                        </Box>
                    )) : <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        {/* <img src={require('../../Assets/images/no-list-found.png')} width={"30%"} alt='nodata' /> */}
                        <Typography sx={{ textAlign: "center", fontWeight: 600 }} variant='body2'>No roster  for the selected events.</Typography>
                    </Box>
                }
            </Box>
        </Root>
    )
}