import React, { useEffect, useState } from 'react';
import { Box, AppBar, Toolbar, Typography, Backdrop, CircularProgress, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Helpers from '../../Shell/Helper';
import SwipeableCalendar from '../../Components/SwipeableCalendar';
import { GetActivitiesByDay } from '../../Services/Api/AppService';
import SearchOn from '@mui/icons-material/SearchOutlined';
const Root = styled(Box)(({ theme }) => ({
  height: '100%',
  padding: 0,
  '& .header-cont': {
    // width: "100%",
    width: "device-width",
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    "& header": {
      display: "flex",
      height: 48,
      background: "white",
      fontSize: 12,
      alignItems: "center",
      '& img[alt="Logo"]': {
        width: 34,
        // filter: 'invert(1)',
      },
      "& .headerNav": {
        // marginRight: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        "& .fuel": {
          color: "red",
          position: "absolute",
          width: "26px",
          right: "62px",
          top: "12px",
          cursor: "pointer"
        },
        "& .pageMainTitle": {
          fontSize: 15,
          fontWeight: 600,
          whiteSpace: "nowrap"
        },
        "& .headerText": {
          display: "flex",
          color: '#1f0757',
          fontSize: 13,
          textShadow: '#1f0757',
          fontWeight: 600,
          whiteSpace: "nowrap"
        }
      }
    },
  },
  '& .mainContainer': {
    backgroundImage: `url(${require('../../Assets/loginBackgroundImg/img1.png')})`,
    backgroundSize: "cover",
    overflow: "hidden",
    backgroundPosition: "center",
    height: "calc(100vh - 48px)",
    marginTop: 48,
    overflowY: "auto",
    "& .routeTextNull": {
      height: "80vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    "& .routeBox": {
      margin: "20px auto ",
      display: "flex",
      width: "100",
      minWidth: 310,
      gap: 10,
      backgroundColor: theme.palette.primary.contrastText,
      padding: "10px",
      boxShadow: "0px 3px 15px -6px rgb(0 0 0 / 50%)",
      borderRadius: 10,
      alignItems: "center",
      "& .image": {
        width: "50px",
        height: "50px",
        // borderRadius: "50%",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // backgroundColor: "#b99ec7d4",

      },
    },
    "& .input": {
      padding: "15px", borderRadius: '5px', backgroundColor: "white",
      margin: "0px 12px",
      width: "94%",
      border: "1px solid #022c2f",
      outline: "none",
    },
  },
  "& .MainTitle1": {
    fontSize: 14,
    fontWeight: 600,
    textShadow: "black",
    whiteSpace: "nowrap"
  },
  "& .MainTitle": {
    fontSize: 15,
    fontWeight: 600,
    // whiteSpace: "nowrap"
  },
  "& .subTitle": {
    fontSize: 15,
    fontWeight: 500,
    whiteSpace: "nowrap"
  },
  '& img[alt="heartpng"]': {
    width: 50,
  },
}));
export default function Activities() {

  const [appInfo, setAppInfo] = useState({});
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [filterEvents, setFilterEvents] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showEventSearch, setShowEventSearch] = useState(false)
  const [selectedEvents, setSelectedEvents] = useState();
  const [state, setState] = useState({
    activites: [],
    seniorId: [],
    seniorsList: null,
  })
  let navigate = useNavigate();
  //Initial calls
  useEffect(() => {
    let appInfo = Helpers.getAppData()
    if (appInfo) {
      setAppInfo(appInfo);
    } else {
      setAppInfo({ centerName: "My Senior Center", centerLogURI: "/Assets/icons/msc_heart.png" });
    }
  }, [])
  useEffect(() => {
    GetEvents()
  }, [selectedDate]);

  const handleChangeSelectedDate = (date) => {
    setSelectedDate(date)
  }
  const handleToggler = () => {
    setShowEventSearch(!showEventSearch)
  }
  const GetEvents = async () => {
    setLoader(true)
    let date = Helpers.formatDate(selectedDate);
    try {
      let res = await GetActivitiesByDay(date)
      if (res.success) {
        setLoader(false)
        setEvents(res.data)
        setSelectedEvents(res.data[0])
        setFilterEvents(res.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoader(false)
    }
  }
  //Events search
  const handleFilterEventByName = async (e) => {
    let { value } = e.target
    const searchTerms = value.toLowerCase();
    let filteredValues = events?.filter(e => e?.eventName?.toLowerCase().includes(searchTerms))
    if (filteredValues) {
      setFilterEvents(filteredValues)
    } else {
      setFilterEvents(events)
    }
  }

  return (

    <Root p={2} >
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* Header */}
      <Box className="header-cont">
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static">
            <Toolbar
              sx={{ width: "100%", maxWidth: "100%", padding: "0px 15px 0px 45px !important" }}>
              <Box className="headerNav">
                <Box
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2, display: "inline-flex", alignItems: "center" }}>
                  {appInfo?.centerLogURI ?
                    <img src={appInfo.centerLogURI}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = require("../../Assets/icons/msc_heart.png");
                      }}
                      alt="Logo" style={{ marginRight: 10 }}
                    /> : <img src={require(`../../Assets/icons/msc_heart.png`)} alt="Logo" style={{ marginRight: 10 }}
                    />}
                  <div>
                    <Typography color="black" className="pageMainTitle" id="pageTitle">
                      {appInfo.centerName}
                    </Typography>
                    <Typography color="black" className="headerText" id="pageTitle">
                      Activites
                    </Typography>
                  </div>
                </Box>
              </Box>
            </Toolbar>
          </AppBar>
        </Box>
      </Box>
      {/* Activities */}
      <Box className="mainContainer">
        <Grid mt={2} container alignItems='center'>
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
            <SwipeableCalendar handleChangeSelectedDate={handleChangeSelectedDate} />
          </Grid>
          <Grid item textAlign="end" xs={2}>
            <SearchOn onClick={handleToggler} />
          </Grid>
        </Grid>
        {showEventSearch ? <input className='input' onChange={handleFilterEventByName} inputprops={{ style: { fontSize: 12, fontWeight: 600, } }} type='text' placeholder='Search Event List' /> : ""}
        {filterEvents?.length ?
          filterEvents?.map((e, index) => (
            <Box key={index} sx={{ margin: "4px 10px", cursor: 'pointer' }} onClick={() => { navigate('/activity-details', { state: { rostersData: e, selectedDate: selectedDate } }) }}>
              <Box className="routeBox">
                <Box className="image">
                  <img src={`https://d2ap19ik6wt59k.cloudfront.net/icons/${e.icon}s.png`} alt='icon' width="100%" />
                </Box>
                <Box className="routeText">
                  <Typography className="MainTitle" > {e.eventName} </Typography>
                  <Typography className="subTitle">{e.room}</Typography>
                  <Typography className="subTitle"><span style={{ fontWeight: 600 }}>Time: </span>{e.startTime} - {e.endTime}</Typography>
                </Box>
              </Box>
            </Box>
          )) : <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%" }}>
            {/* <img src={require('../../Assets/images/no-list-found.png')} width={"30%"} alt='nodata' /> */}
            <Typography sx={{ textAlign: "center", fontWeight: 600 }} variant='body2'>No events scheduled for the selected date.</Typography>
          </Box>
        }
      </Box>
    </Root>
  )
}