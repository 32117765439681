import React, { useEffect, useState } from 'react'
import { AppBar, Avatar, Badge, Box, Divider, IconButton, Stack, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import Helpers from '../../Shell/Helper';
import BackIcon from '@mui/icons-material/ArrowBack';
const Root = styled(Box)(({ theme }) => ({
    height: "100%",
    padding: 0,

    '& .header-cont': {
        // width: "100%",
        width: "device-width",
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        "& header": {
            display: "flex",
            height: 48,
            background: "white",
            fontSize: 12,
            alignItems: "center",
            '& img[alt="Logo"]': {
                width: 34,
                filter: 'invert(1)',
            },
            "& .headerNav": {
                // marginRight: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                "& .fuel": {
                    color: "red",
                    position: "absolute",
                    width: "26px",
                    right: "62px",
                    top: "12px",
                    cursor: "pointer"
                },
                "& .pageMainTitle": {
                    fontSize: 16,
                    fontWeight: 600,
                    whiteSpace: "nowrap",
                    width: "260px",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                },
                "& .headerText": {
                    display: "flex",
                    color: '#1f0757',
                    fontSize: 13,
                    textShadow: '#1f0757',
                    fontWeight: 600,
                    whiteSpace: "nowrap"
                },
                "& .refreshingIcon": {
                    color: "Grey"
                },
                "& .reloadBtn": {
                    padding: "0px 0px 4px 0px"
                }
            }
        },
    },
    "& .avatarContainer": {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "40px",
        "& .name": {
            color: theme.palette.type == 'dark' ? '#fff' : "#000",
            fontWeight: 500
        },
        "& .cameraIcon": {
            width: "30px",
            height: "30px",
            backgroundColor: theme.palette.type == 'dark' ? '#2196f3' : "grey",
            color: "#fff",
            borderRadius: '50%',
            padding: "5px"
        }
    },
    "& .detail_container": {
        backgroundColor: "#FFFFFF",
        padding: "8px",
        border: "1px solid #c4c4c4",
        borderRadius: "5px",
        marginBottom: "10px",
        "& .items": {
            display: "flex",
            justifyContent: "space-between",
            padding: "8px 0px"
        },
    },
    "& .mainContainer": {
        backgroundImage: `url(${require("../../Assets/loginBackgroundImg/img1.png")})`,
        backgroundSize: "cover",
        overflow: "hidden",
        backgroundPosition: "center",
        height: "calc(100vh - 48px)",
        marginTop: 48,
        overflowY: "auto",
    },
    '& img[alt="mealpng"]': {
        width: 60,
    },
    "& .mainCotainer2": {
        position: "fixed",
        left: "50%",
        top: 2,
        width: "100%",
        zIndex: 1,
        height: "48px",
        transform: "translateX(-50%)",
        "& .headerNav": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            backgroundColor: "white",
            height: 47,
            borderBottom: "1px solid #00000033",
            "& .pageMainTitle": {
                fontSize: 16,
                fontWeight: 600,
                whiteSpace: "nowrap",
                width: "260px",
                overflow: "hidden",
                textOverflow: "ellipsis"
            },
            "& .headerText": {
                display: "flex",
                color: '#1f0757',
                fontSize: 13,
                textShadow: '#1f0757',
                fontWeight: 600,
                whiteSpace: "nowrap"
            },
            "& img": {
                width: 26,
                filter: "invert(1)",
            },
        },
    },
}))
function SeniorDetails() {
    const [seniorByIdDetail, setSeniorByIdDetail] = React.useState({});
    const [tags, setTags] = React.useState([]);
    const [loader, setLoader] = useState(false);
    const [appInfo, setAppInfo] = useState({});
    let navigate = useNavigate()
    let location = useLocation();
    let id = location.state;
    //Initial Calls

    useEffect(() => {
        let appInfo = Helpers.getAppData()
        if (appInfo) {
            setAppInfo(appInfo);
        } else {
            setAppInfo({ centerName: "My Senior Center", centerLogURI: "/Assets/icons/msc_heart.png" });
        }
    }, [])
    useEffect(() => {
        let Seniors = JSON.parse(localStorage.getItem("Seniors"))
        let currentSenior = Seniors.find(e => e.seniorID === id)
        setSeniorByIdDetail(currentSenior)
        getTagsBySeniorID()
    }, [])
    //Get Senior by Tags
    const getTagsBySeniorID = () => {
        let allTags = JSON.parse(localStorage.getItem("KayTags"))
        let currentSeniorTags = allTags?.filter(t => t.seniorID === id)
        setTags(currentSeniorTags);
    }

    return (
        <Root p={2}>
            <Box className="header-cont">
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static">
                        <Toolbar
                            sx={{ width: "100%", maxWidth: "100%", padding: "0px 15px 0px 45px !important" }}>
                            <Box className="headerNav">
                                <Box
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2, display: "inline-flex", alignItems: "center" }}>
                                    {appInfo?.centerLogURI ?
                                        <img src={appInfo.centerLogURI}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = require("../../Assets/icons/msc_heart.png");
                                            }}
                                            alt="Logo" style={{ marginRight: 10 }}
                                        /> : <img src={require(`../../Assets/icons/msc_heart.png`)} alt="Logo" style={{ marginRight: 10 }}
                                        />}
                                    <div>
                                        <Typography color="black" className="pageMainTitle" id="pageTitle">
                                            {appInfo.centerName}
                                        </Typography>
                                        <Typography color="black" className="headerText" id="pageTitle">
                                            Seniors
                                        </Typography>
                                    </div>

                                </Box>

                            </Box>
                        </Toolbar>
                    </AppBar>
                </Box>
            </Box>
            <Box
                className="mainCotainer2"
                sx={{ width: "100%", maxWidth: "100%", padding: "0px 15px 0px 45px" }}
            >

            </Box>
            <Box className="mainContainer">
                <Box sx={{ margin: "4px 10px" }}>
                    {loader ?
                        <Stack mx={2} alignItems='center' mt={5} spacing={3}>
                        </Stack> :
                        <Box className="main_container">
                            <IconButton sx={{ marginTop: "10px" }} onClick={() => { navigate('/app-senior') }}>
                                <BackIcon />
                            </IconButton>
                            <Box className="avatarContainer" mt={0}>
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                >
                                    <Avatar sx={{ width: '140px', height: '140px' }} alt="Travis Howard" src={seniorByIdDetail.photoURL} />
                                </Badge>
                                <Typography my={2} className='name' variant={'h2'}>{seniorByIdDetail.firstName} {seniorByIdDetail.lastName}</Typography>
                            </Box>
                            <Typography ml={.5} mb={1} sx={{ fontWeight: 600 }} variant='body2' >Personal Info</Typography>
                            <Box className='detail_container'>
                                <Box className="items"><Typography sx={{ fontWeight: 600 }} variant='body2' className='typo_text'>First Name</Typography><Typography variant='body2' className='typo_text'>{seniorByIdDetail?.firstName ? seniorByIdDetail.firstName : "----"}</Typography></Box>
                                <Divider />
                                <Box className="items"><Typography sx={{ fontWeight: 600 }} variant='body2' className='typo_text'>Last Name</Typography><Typography variant='body2' className='typo_text'>{seniorByIdDetail?.lastName ? seniorByIdDetail.lastName : "----"}</Typography></Box>
                                <Divider />
                                <Box className="items"><Typography sx={{ fontWeight: 600 }} variant='body2' className='typo_text'> City</Typography><Typography variant='body2' className='typo_text'>{seniorByIdDetail?.city ? seniorByIdDetail.city : "----"}</Typography></Box>
                                <Divider />
                                <Box className="items"><Typography sx={{ fontWeight: 600 }} variant='body2' className='typo_text'> Senior ID</Typography><Typography variant='body2' className='typo_text'>{seniorByIdDetail?.seniorID ? seniorByIdDetail.seniorID : "----"}</Typography></Box>
                            </Box>
                            <Typography ml={.5} mb={1} sx={{ fontWeight: 600 }} variant='body2' >Tags</Typography>
                            {tags?.length ?
                                <Box className='detail_container'>
                                    {tags?.map((item, index) => (
                                        <Box key={index}>
                                            <Box className="items"><Typography sx={{ fontWeight: 600 }} variant='body2' className='typo_text'>Tag {index + 1}</Typography><Typography variant='body2' className='typo_text'>{item?.cardID ? item.cardID : "----"}</Typography></Box>
                                        </Box>
                                    ))}

                                </Box> : <Typography sx={{ fontWeight: 600 }} textAlign='center' variant='body2' className='typo_text'>No Tags Found</Typography>}
                        </Box>}

                </Box>
            </Box>

        </Root>
    )
}

export default SeniorDetails