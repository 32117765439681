import React, { useEffect, useState } from 'react';
import AuthService from '../../Services/AuthService';
import { GetRoutes, GetAllSeniors } from '../../Services/Api/MealService';
import { AppBar, Box, Button, Grid, Radio, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import img1 from "../../Assets/images/mw1.png"
import img2 from "../../Assets/images/mw2.png"
import img3 from "../../Assets/images/mw3.png"
import defaultImg from "../../Assets/heartBlack.png"

const Root = styled(Box)(({ theme }) => ({
    height: '100%',
    padding: 0,
    '& .header-cont': {
        // width: "100%",
        width: "device-width",
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        "& header": {
            display: "flex",
            height: 48,
            background: "white",
            fontSize: 12,
            alignItems: "center",
            '& img[alt="Logo"]': {
                width: 34,
                filter: 'invert(1)',
            },
            "& .headerNav": {
                // marginRight: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                "& .fuel": {
                    color: "red",
                    position: "absolute",
                    width: "26px",
                    right: "62px",
                    top: "12px",
                    cursor: "pointer"
                },
                "& .pageMainTitle": {
                    fontSize: 15,
                    fontWeight: 600,
                    whiteSpace: "nowrap"
                },
                "& .headerText": {
                    display: "flex",
                    color: '#1f0757',
                    fontSize: 13,
                    textShadow: '#1f0757',
                    fontWeight: 600,
                    whiteSpace: "nowrap"
                }
            }
        },
    },
    '& .mainContainer': {
        backgroundImage: `url(${require('../../Assets/loginBackgroundImg/img1.png')})`,
        backgroundSize: "cover",
        overflow: "hidden",
        backgroundPosition: "center",
        backgroundColor: "#f8e9f7",
        height: "calc(100vh - 48px)",
        marginTop: 48,
        overflowY: "auto",
        "& .title": {
            fontSize: "18px"
        },
        "& .center_card": {
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
            padding: "20px",
            textAlign: "center",
            borderRadius: "10px",
            boxShadow: "0px 4px 4px 0px rgb(0 0 0 / 25%)",
            cursor: "pointer",
            "& .center_img_container": {
                width: "40px",
                height: "40px",
                "& img": {
                    width: "100%",
                    height: "100%",
                    objectFit: "contain"
                }
            },
            "& .center_name_selected": {
                color: "#00807C",
                fontWeight: 600,
                fontSize: "16px"
            },
            "& .center_name": {
                fontSize: "16px"
            },
            "& .radion_btn": {
                position: "absolute",
                top: "0",
                right: "0"

            }
        },
        "& .actionButtonContainer": {
            width: "100%",
            height: "90px",
            // border: "2px solid black",
            borderRadius: '10px 10px 0px 0px',
            position: "fixed",
            bottom: "10px",
            left: 0,
            // backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
            // borderTop: "1px solid #c4c4c4",
            alignItems: "center",
            "& .actionBtn": {
                width: "90%",
                margin: "0 14px",
                padding: "10px 10px",
                fontSize: "20px",
                borderRadius: "100px"
            },
            "& .success": {
                backgroundColor: theme.palette.button.main,
                color: theme.palette.other.text,
            }
        },

    },
    '& img[alt="heartpng"]': {
        width: 50,
    },
}));

let centerDetail = [
    {
        centerLogURI: img1,
        centerName: "Acton Senior Center",
    },
    {
        centerLogURI: img2,
        centerName: "Abc Senior Center",
    },
    {
        centerLogURI: img3,
        centerName: "Xyz Senior Center",
    },
    {
        centerLogURI: img1,
        centerName: "Test Site",
    },

]
export default function SelectCenter() {

    const [state, setState] = useState({

    })
    const [selectedValue, setSelectedValue] = useState({});
    const [centerData, setCenterData] = useState([]);
    let navigate = useNavigate();
    useEffect(() => {
        // debugger
        let centerData = JSON.parse(localStorage.getItem("centerData"));
        let userToken = AuthService.getAuthDataByName("userToken");

        if (centerData) {
            setCenterData(centerData);
        }
        if (userToken) {
            setSelectedValue(userToken)
        }
    }, [])
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };
    const handleClick = (value) => {
        // debugger
        setSelectedValue(value);
        localStorage.setItem('alreadyVisited', false)
        // localStorage.setItem("selectedCenter", JSON.stringify(value));
        AuthService.setUserData("userToken", value);
        AuthService.setUserData("gsid", value?.centerInfo[0]?.gsid);
        // AuthService.setUserData("centerInfo", value?.centerInfo[0]);
    }
    // const handleNext = () => {
    //     navigate("/meal")
    // }

    return (

        <Root p={2} >
            {/* Header */}
            <Box className="header-cont">
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static">
                        <Toolbar
                            sx={{ width: "100%", maxWidth: "100%", padding: "0px 15px 0px 45px !important" }}>
                            <Box className="headerNav">
                                <Box
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2, display: "inline-flex", alignItems: "center" }}>

                                    <img src={require('../../Assets/icons/msc_heart.png')} alt="Logo" style={{ marginRight: 10 }} />
                                    <div>
                                        <Typography color="black" className="pageMainTitle" id="pageTitle">
                                            My Senior Center
                                        </Typography>
                                        <Typography color="black" className="headerText" id="pageTitle">
                                            Selecting Center
                                        </Typography>
                                    </div>
                                </Box>

                            </Box>
                        </Toolbar>
                    </AppBar>
                </Box>
            </Box>
            <Box className="mainContainer" px={2}>


                <Grid container spacing={1} mt={2}>

                    {centerData?.map((item, key) => (
                        <Grid item xs={6} key={key}>
                            <Box className="center_card" onClick={() => handleClick(item)}>
                                <Box className="center_img_container">
                                    <img src={item?.centerInfo[0]?.centerLogURI || defaultImg} onError={(e) => {
                                        console.log('Image failed to load, switching to default image.');
                                        e.target.src = defaultImg;
                                    }} alt="centerImg" />
                                </Box>
                                <Typography className={selectedValue?.centerInfo?.[0].centerName === item?.centerInfo[0].centerName ? 'center_name_selected' : 'center_name'} variant='subtitle2' mt={1}>{item?.centerInfo[0]?.centerName}</Typography>
                            </Box>
                        </Grid>
                    ))
                    }



                </Grid>


                <Box className="actionButtonContainer">
                    <Button
                        className='actionBtn success'
                        onClick={() => navigate("/settings")}
                    // startIcon={acceptLoader ? <CircularProgress size={20} sx={{ color: "white" }} /> : ""}
                    >
                        Back
                    </Button>

                </Box>


            </Box>
        </Root>

    )
}