import { GetRequest, PostRequest } from "./Apibase";
// Basic Reload
var isOnline = window.navigator.onLine
isOnline = true
window.addEventListener('online', async () => {
    isOnline = true;
    await SyncApplicationWithServer();
});
window.addEventListener('offline', () => {
    isOnline = false
});
// Ride Servicess
let GetVehicleRoute = async (gsid, vehicleUID, date, forceCache = false) => {
    if (isOnline && !forceCache) {
        let response = await GetRequest(`/api/mobile/rides/GetVehicleRoute?gsid=${gsid}&VehicleUID=${vehicleUID}&date=${date}`, true, false, true);
        return response;
    }
    else {
        return { success: true, data: [] }
    }
}
let GetDriversByDevice = async (data) => {
    if (isOnline) {
        let response = await PostRequest(`/api/mobile/rides/getDriversByDevice?gsid=${data.gsid}&deviceTagID=${data.deviceTagID}&deviceSecret=${data.deviceSecret}`, null, false);
        return response;
    } else {
        console.error(`Connection Broken! can't post the request to getDriversByDevice endpoint`)
    }
}
let GetVehiclesByDevice = async (data) => {
    if (isOnline) {
        let response = await PostRequest(`/api/mobile/rides/getVehiclesByDevice?gsid=${data.gsid}&deviceTagID=${data.deviceTagID}&deviceSecret=${data.deviceSecret}`, null, false);
        return response;
    } else {
        console.error(`Connection Broken! can't post the request to getVehiclesByDevice endpoint`)
    }
}
let GetDriverTokenByDevice = async (data) => {
    if (isOnline) {
        let response = await PostRequest(`/api/mobile/rides/GetDriverTokenByDevice?userID=${data.userid}&gsid=${data.gsid}&deviceTagID=${data.deviceTagID}&deviceSecret=${data.deviceSecret}`, null, false);
        return response;
    } else {
        console.error(`Connection Broken! can't post the request to GetDriverTokenByDevice endpoint`)
    }
}
let SaveVehicleGPS = async (gsid, data) => {
    if (isOnline) {
        let response = await PostRequest(`/api/mobile/rides/SaveVehicleGPS?gsid=${gsid}`, data, true, false, true);
        return response;
    } else {
        // UpdateOfflineQueue('add', { type: 'SaveVehicleGPS', requestObj: { gsid: gsid, data: data } })
    }
}
let SaveRideStep = async (data, gsid) => {
    let response;
    if (isOnline) {
        response = await PostRequest(`/api/mobile/rides/saveRideStep?gsid=${gsid}`, data, true, false, true);
    } else {
        UpdateOfflineQueue('add', { type: 'SaveRideStep', requestObj: { gsid: gsid, data: data } })
        response = { success: true }
    }
    // let cache = AuthService.getUserDataByName('RideData')
    // cache.data.find(r => r.stopID === data.StopID).stopStatus = data.StopStatus;
    // AuthService.setUserData('RideData',cache);
    return response;
}
let SendMessages = async (data, gsid) => {
    let response;
    if (isOnline) {
        response = await PostRequest(`/api/connectone/message/send?gsid=${gsid}`, data, true, false, true);
    } else {
        console.error(`Connection Broken! can't post the request to SendMessages endpoint`)
    }
    return response;
}
let AddVehicleGallons = async (data, gsid) => {
    let response;
    if (isOnline) {
        response = await PostRequest(`/api/ride/vehicles/AddVehicleGallons`, data, true, true, true);
    } else {
        console.error(`Connection Broken! can't post the request to SendMessages endpoint`)
    }
    return response;
}
let GetLiveMatrix = async (liveMatrixDTO, gsid) => {
    let response;
    if (isOnline) {
        response = await PostRequest(`/api/ride/matrix/RunLiveMatrix?gsid=${gsid}`, liveMatrixDTO, true, false, true);
    } else {
        console.error(`Connection Broken! can't post the request to SendMessages endpoint`)
    }
    return response;
}
let UpdateOfflineQueue = async (type, object) => {
    let offlineQueue = JSON.parse(localStorage.getItem('OfflineQueue'))
    if (!offlineQueue) {
        offlineQueue = []
    }
    if (type === 'add') {
        object.guid = GenerateGUID()
        object.date = Date.now()
        console.log(object)
        offlineQueue.push(object)
    }
    else if (type === 'remove') {
        offlineQueue = offlineQueue.filter(o => o.guid !== object.guid)
    }
    console.log('offlineQueue: ', offlineQueue)
    localStorage.setItem('OfflineQueue', JSON.stringify(offlineQueue))
}
const SyncApplicationWithServer = () => {
    return new Promise((resolve, reject) => {
        let offlineQueue = JSON.parse(localStorage.getItem("OfflineQueue"));
        let response;
        try {
            if (offlineQueue && offlineQueue.length > 0) {
                offlineQueue
                    .sort(function (a, b) {
                        return new Date(a.date) - new Date(b.date);
                    })
                    .forEach(async (o) => {
                        if (o.type === "SaveVehicleGPS") {
                            response = await SaveVehicleGPS(o.requestObj.gsid, o.requestObj.data);
                        } else if (o.type === "SaveRideStep") {
                            response = await SaveRideStep(o.requestObj.data, o.requestObj.gsid);
                        }
                        console.log("synchronization response:", o.type, response);
                        if (response.success) {
                            UpdateOfflineQueue("remove", o);
                        }
                    });
            }
            setTimeout(() => {
                resolve("synchronization succeeded");
            }, 1000);
        } catch (ex) {
            console.log("SyncApplicationWithServer Error:", ex);
            reject(ex);
        }
    });
};
let GenerateGUID = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (((c ^ crypto.getRandomValues(new Uint8Array(1))[0]) & 15) >> (c / 4)).toString(16)
    );
}
export {
    GetVehicleRoute,
    SaveVehicleGPS,
    GetVehiclesByDevice,
    GetDriversByDevice,
    SaveRideStep,
    GetDriverTokenByDevice,
    SyncApplicationWithServer,
    SendMessages,
    GetLiveMatrix,
    AddVehicleGallons,
}
