import React, { useEffect, useRef, useState } from 'react';
import { AddCard, GetActivitiesByDay, GetAllKeytags, GetRoster, GetSeniorByKeytag, getSeniorDetailByID, GetSeniorsBySite, RegisterSenior } from '../Services/Api/AppService';
import { AppBar, Button, CircularProgress, DialogActions, IconButton, Menu, MenuItem, Slide, Toolbar, Typography, Radio, RadioGroup, FormControlLabel, FormControl, FormGroup, Checkbox } from '@mui/material';
import { Dialog, DialogContent, DialogTitle, } from '@mui/material';
import SearchOn from '@mui/icons-material/SearchOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SwipeableCalendar from './SwipeableCalendar';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import Skeleton from '@mui/material/Skeleton';
import { keyframes } from '@emotion/react';
import Stack from '@mui/material/Stack';
import Helpers from '../Shell/Helper';
import { Box } from '@mui/system';
import AuthService from '../Services/AuthService';
import QuaggaBarcodeScanner from './Quagga';
// dialog transition animation
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const Root = styled(Box)(({ theme }) => ({
    '& .header-cont': {
        width: "100%",
        width: "device-width",
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        "& header": {
            display: "flex",
            height: 48,
            background: "white",
            fontSize: 12,
            alignItems: "center",
            '& img[alt="Logo"]': {
                width: 28,
                // filter: 'invert(1)',
            },
            "& .HeaderScan": {
                width: "100%",
                maxWidth: "100%",
                paddingLeft: "38px",
                paddingRight: "8px",
                [theme.breakpoints.down('md')]: {
                    padding: "0px 8px 0 40px",
                },
            },
            "& .headerNav": {
                // marginRight: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                "& .fuel": {
                    color: "red",
                    position: "absolute",
                    width: "26px",
                    right: "62px",
                    top: "12px",
                    cursor: "pointer"
                },
                "& .pageMainTitle": {
                    fontSize: 16,
                    fontWeight: 600,
                    whiteSpace: "nowrap"
                },
                "& .calenderIcon": {
                    [theme.breakpoints.down('sm')]: {
                        display: "none"
                    },
                },
                "& .headerText": {
                    display: "flex",
                    color: '#1f0757',
                    fontSize: 13,
                    textShadow: '#1f0757',
                    fontWeight: 600,
                    whiteSpace: "nowrap"
                }
            }
        },
    },
    "& .MainTitle1": {
        fontSize: 14,
        fontWeight: 600,
        textShadow: "black",
        whiteSpace: "nowrap"
    },
    "& .MainTitle": {
        fontSize: 15,
        fontWeight: 600,
        whiteSpace: "nowrap"
    },
    "& .subTitle": {
        fontSize: 15,
        fontWeight: 500,
        whiteSpace: "nowrap"
    },
    '& .mainContainer': {
        // backgroundImage: `url(${require('../Assets/loginBackgroundImg/img1.png')})`,
        // backgroundSize: "cover",
        // backgroundPosition: "center",

        display: "flex",
        // flexDirection: "column",
        alignItems: "start",
        justifyContent: "center",
        height: "100vh",
        padding: "50px 0 0",
        overflow: "hidden",
        "& .content": {
            width: "100%",
            padding: "0px 0px",
            height: "calc(100vh - 52px)",
            "& .infoCard": {
                padding: "10px",
                display: "flex",
                width: "100%",
                height: "fit-content",
                gap: "10px",
                marginBottom: "10px",
                backgroundColor: "#fff",
                borderBottom: "1px solid #ccc",
                "& .image": {
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#b99ec7d4",
                    "& img": {
                        objectFit: "contain",
                        width: "100%",
                        height: "100%"
                    }

                },

            },
        },
        "& .leftChild": {
            position: 'relative',
            width: "60%",
            [theme.breakpoints.down('sm')]: {
                width: '100vw',
            },
            "& .scannerContainer": {
                position: "relative",
                height: "65vh",
                backgroundColor: "#333",
                borderBottom: "1px solid #ccc",
                "& .resultText": {
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 10
                },
                "& .CodeScanner": {
                    width: "100%",
                    height: "100%",
                    objectFit: "fit-content",
                },
                [theme.breakpoints.down('sm')]: {
                    height: "95vh"
                },
            },
            "& .welcomeContainer": {
                height: "35vh",
                [theme.breakpoints.down('sm')]: {
                    display: "none"
                },
            },

            '& .cornerBorder': {
                position: "absolute",
                left: "50%",
                top: "50%",
                zIndex: 2,  // Ensure it appears above the container with opacity
                opacity: 1,
                width: "80%",
                height: "300px",
                transform: "translate(-50%, -50%)",
                background: `
      linear-gradient(to right, black 4px, transparent 4px) 0 0,
      linear-gradient(to right, black 4px, transparent 4px) 0 100%,
      linear-gradient(to left, black 4px, transparent 4px) 100% 0,
      linear-gradient(to left, black 4px, transparent 4px) 100% 100%,
      linear-gradient(to bottom, black 4px, transparent 4px) 0 0,
      linear-gradient(to bottom, black 4px, transparent 4px) 100% 0,
      linear-gradient(to top, black 4px, transparent 4px) 0 100%,
      linear-gradient(to top, black 4px, transparent 4px) 100% 100%`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '30px 100%',
            },
        },
        "& .rightChild": {
            width: "40%",
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            },
            overflowY: "auto",
            padding: "0 10px",
            borderLeft: "1px solid #ccc",

        },


        "& .ListIcons": {
            position: "absolute",
            right: 20,
            bottom: 20,
            height: "50px",
            width: "50px",
            backgroundColor: "white",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 6

        },
        "& .beepIcons": {
            position: "absolute",
            right: 20,
            bottom: 20,
            height: "50px",
            width: "50px",
            backgroundColor: "white",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 6
        },
        "& .smBeepIcon": {
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            },
        },
        "& .mdBeepIcon": {
            [theme.breakpoints.up('sm')]: {
                display: 'none'
            },
        },
        "& .cameraIcons": {
            position: "absolute",
            left: 20,
            bottom: 20,
            height: "50px",
            width: "50px",
            backgroundColor: "white",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 6
        },
        "& .settingIcons": {
            position: "absolute",
            left: 86,
            bottom: 20,
            height: "50px",
            width: "50px",
            backgroundColor: "white",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 6
        },
        "& .StopScanButton": {
            margintop: "20px"
        },
        "& .loader": {
            position: "absolute",
            top: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
        },
    },
}));
const DialogBox = styled(Dialog)(({ theme }) => ({
    "& .input": {
        padding: "15px", borderRadius: '5px', backgroundColor: "white",
        margin: "10px 0px",
        width: "98%",
        border: "1px solid #022c2f",
        outline: "none",
    },
    "& .content": {
        width: "100%",
        "& .infoCard": {
            padding: "15px",
            display: "flex",
            width: "100%",
            height: "fit-content",
            gap: "10px",
            // marginBottom: "10px",
            // backgroundColor: "#fff",
            borderBottom: "1px solid #ccc",
            "& .image": {
                width: "50px",
                height: "50px",
                // borderRadius: "50%",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // backgroundColor: "#b99ec7d4",
                "& img": {
                    objectFit: "contain",
                    width: "100%",
                    height: "100%"
                }

            },

        },
    },
    "& .routeBox": {
        margin: "20px auto ",
        display: "flex",
        width: "100",
        minWidth: 310,
        gap: 10,
        backgroundColor: theme.palette.primary.contrastText,
        padding: "10px",
        boxShadow: "0px 3px 15px -6px rgb(0 0 0 / 50%)",
        borderRadius: 10,
        "& .image": {
            height: "50px",
            width: "50px",
            borderRadius: "50%",
            overflow: "hidden",
            "& img": {
                objectFit: "contain",
                width: "100%",
                height: "100%"
            }
        },
        "& .routeText": {
            display: "flex",
            flexDirection: "column",
        },
        "& .routetextColor": {
            fontSize: 20,
            fontWeight: 400,
            color: theme.palette.other.main
        },
        "& .textColor": {
            fontSize: 16,
            fontWeight: 300,
            color: theme.palette.primary.dark
        }
    },
    "& .MainTitle1": {
        fontSize: 14,
        fontWeight: 600,
        textShadow: "black",
        whiteSpace: "nowrap"
    },
    "& .MainTitle": {
        fontSize: 15,
        fontWeight: 600,
        // whiteSpace: "nowrap"
    },
    "& .subTitle": {
        fontSize: 15,
        fontWeight: 500,
        whiteSpace: "nowrap"
    },
}));

const fadeIn = keyframes`
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
`;

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

// const { GS1_DATABAR, MAXICODE } = BarcodeFormat;

function NewScanner() {

    const [stateOne, setStateOne] = React.useState({
        eventsDialog: false,
        welcomePopup: false,
        seniorDataPopup: false,
        assignSeniorPopup: false,
        conformation: false,
        tag: "",
        setting: false,
        quagga: false,
        rosterDialog: false,
    });
    const [data, setData] = useState({
        keyTags: '',
        seniors: [],
        roster: []
    });

    let { state } = useLocation()
    const isScan = useRef(true);
    const number = useRef(0);
    const currentSeniorId = useRef(0);
    const seniorId = useRef(0);
    const seniorName = useRef('');
    const containerRef = useRef(null);
    // const processedSeniors = useRef(new Set());
    const [devices, setDevices] = useState([]);
    const [events, setEvents] = useState([]);
    const [filterEvents, setFilterEvents] = useState([]);
    const [selectedEvents, setSelectedEvents] = useState();
    const [selectedDate, setSelectedDate] = useState(state?.selectedDate || new Date());
    const [deviceId, setDeviceId] = useState(null);
    const [flipCamera, setFlipCamera] = useState(true)
    const [showEventSearch, setShowEventSearch] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const [isBeepAllow, setIsBeepAllow] = useState(true);
    const [loader, setLoader] = useState(false);
    const [syncLoader, setSyncLoader] = useState(false);
    const [seniorLoader, setSeniorLoader] = useState(false);
    const [seniorData, setSeniorData] = useState([]);
    const [isOnline, setIsOnline] = useState(window.navigator.onLine);

    const [animationTrigger, setAnimationTrigger] = useState(false);
    const [currentTime, setCurrentTime] = useState(Helpers.formatTime(new Date()));

    const [seniorListBySite, setSeniorListBySite] = useState([]);
    const [filteredSeniorData, setFilterSeniorData] = useState([]);
    const [visibleSeniors, setVisibleSeniors] = useState(50);
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
    const [focusMode, setFocusMode] = useState("continuous");
    const [zoom, setZoom] = useState(1); // Default zoom level
    const [selectedFormats, setSelectedFormats] = useState(["code_128"])
    const [applySetting, setApplySetting] = useState(false);
    const [isSettingChange, setIsSettingChange] = useState(false);

    let navigate = useNavigate()

    useEffect(() => {
        if (deviceId) {
            setIsLoading(false);
        }
    }, [deviceId]);
    useEffect(() => {
        GetEvents()
    }, [selectedDate]);

    useEffect(() => {
        const settings = JSON.parse(localStorage.getItem('scannerSettings'));
        if (settings) {
            setFocusMode(settings.focusMode);
            setZoom(settings.zoom);
            setSelectedFormats(settings.barcodeFormat);
        }

    }, []);
    useEffect(() => {
        const seniors = JSON.parse(localStorage.getItem('Seniors'));
        setSeniorListBySite(seniors);
        setFilterSeniorData(seniors);
    }, []);
    //Initial calls
    useEffect(() => {
        let isAlreadyVisited = JSON.parse(localStorage.getItem('alreadyVisited'));
        if (!isAlreadyVisited) {
            localStorage.setItem('alreadyVisited', true)
            getSeniorsList()
            getAllKey()
        }

    }, [])

    useEffect(() => {
        let scannedSeniors = localStorage.getItem("scannedSeniors");
        if (scannedSeniors) {
            setData({ ...data, seniors: JSON.parse(scannedSeniors) })
        }
        GetEvents()

    }, [])
    useEffect(() => {
        if (selectedEvents?.eventID) {
            GetRooster(selectedEvents.eventID)
        }
    }, [selectedEvents?.eventID])
    function mergeUniqueData(data1, data2) {
        const combinedData = [...data1, ...data2];
        const uniqueDataMap = new Map();
        combinedData.forEach(item => {
            uniqueDataMap.set(item.seniorID, item);
        });
        return Array.from(uniqueDataMap.values());
    }
    useEffect(() => {
        try {
            let Seniors = JSON.parse(localStorage.getItem("Seniors"));
            if (Seniors && data?.roster?.length) {
                const filteredSeniorByRoster = Seniors.filter(senior =>
                    data.roster.some(item => item.SeniorID === senior.seniorID)
                ).map(senior => ({
                    ...senior,
                    isSync: true // Set isSync to true for each senior
                }));
                let scannedSeniors = localStorage.getItem("scannedSeniors");
                if (scannedSeniors) {
                    let localData = JSON.parse(scannedSeniors)
                    let combinedData = mergeUniqueData(localData, filteredSeniorByRoster)
                    localStorage.setItem("scannedSeniors", JSON.stringify(combinedData))

                } else {
                    localStorage.setItem("scannedSeniors", JSON.stringify(filteredSeniorByRoster))
                }

            } else {
                // localStorage.removeItem("scannedSeniors");
            }
        } catch (error) {
            console.log(error);
        } finally {
            let scannedSeniors = localStorage.getItem("scannedSeniors");
            if (scannedSeniors) {
                setData({ ...data, seniors: JSON.parse(scannedSeniors) })
            }
        }
    }, [data.roster]);
    //Scroll function
    useEffect(() => {
        const handleScroll = () => {
            if (containerRef.current) {
                const bottom = containerRef.current.scrollHeight - containerRef.current.scrollTop === containerRef.current.clientHeight;
                if (bottom && visibleSeniors < seniorListBySite.length) {
                    setVisibleSeniors(prevVisibleSeniors => prevVisibleSeniors + 10);
                }
            }
        };

        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll); // Cleanup on unmount
            }
        };
    }, [visibleSeniors, seniorListBySite]);
    useEffect(() => {
        if (isOnline && data?.seniors?.length) {
            fetchDetailsSequentially();
        }
    }, [data?.seniors, isOnline, selectedEvents?.eventID]);

    useEffect(() => {
        setAnimationTrigger(true);
        const timeout = setTimeout(() => {
            setAnimationTrigger(false);
        }, 2000);
        return () => clearTimeout(timeout);
    }, [number.current]);

    useEffect(() => {
        const updateCurrentTime = () => {
            setCurrentTime(Helpers.formatTime(new Date()));
        };
        const now = new Date();
        const delay = (60 - now.getSeconds()) * 1000; // Time in milliseconds until the next minute
        const timeoutId = setTimeout(() => {
            updateCurrentTime();
            const intervalId = setInterval(updateCurrentTime, 60000);
            return () => clearInterval(intervalId);
        }, delay);
        updateCurrentTime();
        return () => clearTimeout(timeoutId);
    }, []);

    useEffect(() => {
        const getDevices = async () => {
            try {
                const devices = await navigator.mediaDevices.enumerateDevices();
                setDevices(devices);
                const videoDevices = devices.filter(
                    (device) => device.kind === "videoinput"
                );
                if (videoDevices.length > 0) {
                    setDeviceId(videoDevices[0].deviceId);
                }
            } catch (error) {
                console.error("Error enumerating devices:", error);
            }
        };

        getDevices();
    }, []);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 700);

        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);

    useEffect(() => {
        if (!debouncedSearchTerm) {
            setFilterSeniorData(seniorListBySite);
            return;
        }
        const filteredValues = seniorListBySite?.filter(({ firstName, lastName }) => {
            const fullName = `${firstName} ${lastName}`.toLowerCase();
            const searchTerms = debouncedSearchTerm.toLowerCase().split(" ");
            return searchTerms.every(term => fullName.includes(term));
        });
        setFilterSeniorData(filteredValues);
    }, [debouncedSearchTerm, seniorListBySite]);
    //Get Seniors List and KeyTags
    const getSeniorsList = async () => {
        setLoader(true)
        // setProgress(0)
        try {
            let res = await GetSeniorsBySite()
            localStorage.setItem('Seniors', JSON.stringify(res.data))
            // setProgress(100)
            setFilterSeniorData()
            setSeniorListBySite()
        } catch (error) {
            console.log(error)
            setLoader(false)
        } finally {
            // clearInterval(timer);
            const seniors = JSON.parse(localStorage.getItem('Seniors'));
            setSeniorListBySite(seniors);
            setFilterSeniorData(seniors);
            setLoader(false)
            getAllKey()

        }
    }
    const getAllKey = async () => {
        try {
            let res = await GetAllKeytags();
            localStorage.setItem('KayTags', JSON.stringify(res.data))
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false)
        }
    }

    const toggleFlipCamera = () => {
        setFlipCamera((prevState) => !prevState);
    };

    const handleCameraSettings = () => {
        setStateOne({ ...stateOne, setting: true })
    };
    const handleBeep = () => {
        setIsBeepAllow(!isBeepAllow)
    };
    ///
    const handleAudio = async () => {
        var audio = new Audio(require('../Assets/audio/scanner-beep.mp3'));
        await audio.play();
    }

    const getSeniorIdByTag = async (keyTags) => {
        let isAlreadyExist = false;
        let unKnownSenior = {
            "seniorID": keyTags,
            "firstName": "Unknown",
            "lastName": "Senior",
            "city": "Unknown",
            "photoURL": "https://s3.amazonaws.com/myseniorcenter-tenants/_common/female.png",
            "isSync": true
        }
        try {
            const allTags = JSON.parse(localStorage.getItem('KayTags')) || [];
            const seniorsCache = JSON.parse(localStorage.getItem('Seniors')) || [];

            // Helper function to update senior data and cache
            const updateSeniors = (newSenior) => {
                isAlreadyExist = data?.seniors?.some(item => item.seniorID === newSenior.seniorID);
                if (!isAlreadyExist) {
                    newSenior.gsid = AuthService.getAuthDataByName("userToken")?.centerInfo[0].gsid;
                    const updatedSeniors = [newSenior, ...data.seniors];
                    setData({ ...data, seniors: updatedSeniors });
                    localStorage.setItem('scannedSeniors', JSON.stringify(updatedSeniors));
                } else {
                    // alert("Senior already exists.");
                }
            };

            // Find selected senior by cardID
            const currentKeyTagObj = allTags.find(tag => tag.cardID === keyTags);
            if (!currentKeyTagObj) {
                setSeniorLoader(true);
                const res = await GetSeniorByKeytag(keyTags);
                setSeniorLoader(false);

                if (res.success && res.data?.[0]) {
                    setSeniorData(res.data[0]);
                    updateSeniors(res.data[0]);
                } else {
                    setSeniorData(unKnownSenior);
                    updateSeniors(unKnownSenior);
                }
            } else {
                const seniorId = currentKeyTagObj.seniorID;
                let senior = seniorsCache.find(item => item.seniorID === seniorId);

                if (!senior) {
                    setSeniorLoader(true);
                    const res = await getSeniorDetailByID(seniorId);
                    setSeniorLoader(false);

                    if (res.success && res.data?.[0]) {
                        senior = res.data[0];
                        setSeniorData(senior);
                        updateSeniors(senior);
                    } else {
                        setSeniorData(unKnownSenior);
                        updateSeniors(unKnownSenior);
                    }
                } else {
                    setSeniorData(senior);
                    updateSeniors(senior);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const RegisterSeniors = async (id, gsid) => {
        let authData = JSON.parse(localStorage.getItem("centerData"))
        const token = authData?.find(item => item.centerInfo.some(center => center.gsid === gsid));

        let payload = {
            seniorID: id,
            eventID: selectedEvents?.eventID,
            seatID: 0,
            isRegister: true,
            token: token?.tokenUID
        }
        if (currentSeniorId.current !== id) {
            try {
                let res = await RegisterSenior(payload);
                if (res.success) {
                    if (res.data && res.data[0].Error) {
                        alert(res.data[0].ErrorClean)
                        return
                    }
                    let senior = data?.seniors?.find(item => item.seniorID == id);
                    senior.isSync = true;
                    setData({ ...data, seniors: data.seniors.map(item => item.seniorID === id ? senior : item) });
                    localStorage.setItem('scannedSeniors', JSON.stringify(data.seniors.map(item => item.seniorID === id ? senior : item)));
                } else {
                    setSyncLoader(false)
                    alert("Failed to register senior")
                }
            } catch (error) {
                console.log(error);

            } finally {
                currentSeniorId.current = id
            }
        }
    }
    const fetchDetailsSequentially = async () => {
        for (const senior of data?.seniors) {
            if (!senior.isSync && selectedEvents?.eventID && senior.gsid) {
                setSyncLoader(true)

                await RegisterSeniors(senior.seniorID, senior.gsid);
                setTimeout(() => {
                    setSyncLoader(false)
                }, 2000)
            }
        }

    };
    const GetEvents = async () => {
        setLoader(true)
        let date = Helpers.formatDate(selectedDate);
        try {
            let res = await GetActivitiesByDay(date)
            if (res.success) {
                setLoader(false)
                setEvents(res.data)
                setSelectedEvents(res.data[0])
                setFilterEvents(res.data)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoader(false)
        }
    }
    const GetRooster = async (eventId) => {
        try {
            let res = await GetRoster(eventId)
            if (res.success) {
                setData({ ...data, roster: res.data })

            }
        } catch (error) {
            console.log(error)
        }
    }
    const handleSelectedEvents = async (eventId) => {
        //
        GetRooster(eventId)
        //
        setSelectedEvents(events.find(event => event.eventID === eventId))
        setStateOne({ ...stateOne, eventsDialog: false })
    }
    const handleEventsDialog = async () => {
        setStateOne({ ...stateOne, eventsDialog: true })
    }
    const handleClose = () => {
        setStateOne({ ...stateOne, eventsDialog: false })
        isScan.current = true
    }
    const handleCloseAssign = () => {
        setStateOne({ ...stateOne, assignSeniorPopup: false })
    }
    const handleCloseConformation = () => {
        setStateOne({ ...stateOne, conformation: false })
    }
    const handleCloseSetting = () => {
        setApplySetting(false)
        setStateOne({ ...stateOne, setting: false })
    }
    const handleApplySettings = () => {
        let newData = {
            focusMode: focusMode,
            zoom: zoom,
            barcodeFormat: selectedFormats,
        }
        localStorage.setItem('scannerSettings', JSON.stringify(newData));
        setApplySetting(true)
        setIsSettingChange(!isSettingChange)
        setStateOne({ ...stateOne, setting: false })
    }
    const handleToggler = () => {
        setShowEventSearch(!showEventSearch)
    }
    const handleWelcomClose = (e, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }
        setStateOne({ ...stateOne, welcomePopup: false })
        isScan.current = true
    }

    const handleAssignSeniors = async () => {
        setLoader(true)
        let payload = {
            cardID: stateOne.tag,
            seniorID: seniorId.current
        }
        try {
            let res = await AddCard(payload)
            if (res.success) {
                alert("Card added successfully")
            }

        } catch (error) {
            console.log(error)
        }
        finally {
            setLoader(false)
            getSeniorById(stateOne.tag, seniorId.current)
            handleCloseConformation()
        }
    }
    const getSeniorById = async (cId, id) => {
        let scannedSeniors = localStorage.getItem("scannedSeniors");
        let parseData = JSON.parse(scannedSeniors)
        try {
            let res = await getSeniorDetailByID(id)
            if (res.success) {
                let newSenior = res.data
                let filterData = parseData?.filter(x => x.seniorID !== cId)
                if (scannedSeniors && filterData?.length) {
                    newSenior[0].gsid = AuthService.getAuthDataByName("userToken")?.centerInfo[0].gsid;
                    let mergeData = [...filterData, ...newSenior]
                    localStorage.setItem("scannedSeniors", JSON.stringify(mergeData))
                    setData({ ...data, seniors: mergeData })
                } else {
                    newSenior[0].gsid = AuthService.getAuthDataByName("userToken")?.centerInfo[0].gsid;
                    localStorage.setItem("scannedSeniors", JSON.stringify(newSenior))
                    setData({ ...data, seniors: newSenior })
                }

            }
        } catch (error) {
            console.log(error)
        }
    }
    const handleOpenConf = (name, iid) => {
        seniorName.current = name
        seniorId.current = iid
        setStateOne({ ...stateOne, conformation: true })
    }
    //Events search
    const handleFilterEventByName = async (e) => {
        let { value } = e.target
        const searchTerms = value.toLowerCase();
        let filteredValues = events?.filter(e => e?.eventName?.toLowerCase().includes(searchTerms))
        if (filteredValues) {
            setFilterEvents(filteredValues)
        } else {
            setFilterEvents(events)
        }

    }

    //Handle Change'
    const handleFilterSeniorByName = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleChangeSelectedDate = (date) => {
        setSelectedDate(date)
    }

    const handleCheckboxChange = (event) => {
        const format = event.target.name;
        if (event.target.checked) {
            // Add the format if checked
            setSelectedFormats((prev) => [...prev, format]);
        } else {
            // Remove the format if unchecked
            setSelectedFormats((prev) => prev.filter((item) => item !== format));
        }
    }
    //Get Image and Name of Seniors By Id
    const getSeniorNameOrImgByID = (sId, isFor = null) => {
        try {
            const seniors = JSON.parse(localStorage.getItem('Seniors'));
            let selectedSenior = seniors?.find(e => e.seniorID === sId)
            if (isFor === "img") {
                if (selectedSenior) {
                    return selectedSenior?.photoURL
                } else {
                    return "https://s3.amazonaws.com/myseniorcenter-tenants/_common/female.png"
                }
            } else {
                if (selectedSenior) {
                    return `${selectedSenior?.firstName} ${selectedSenior?.lastName}`
                } else {
                    return sId
                }
            }

        } catch (error) {
            console.log(error)
        }
    }
    return (
        <Root >
            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={false}
            // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
                {/* <Typography ml={2} variant='body2'>loading Seniors</Typography> */}
            </Backdrop>
            {/* Header */}

            <Box className="header-cont">

                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static">
                        <Toolbar
                            className='HeaderScan'
                            sx={{}}>
                            <Box className="headerNav">
                                <Box
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <img className="calenderIcon" src={require('../Assets/images/changeEventIcon.png')} alt="Logo" />
                                        <Typography color="black" className="pageMainTitle" id="pageTitle"> {currentTime} </Typography>


                                    </Box>
                                    <Box sx={{ display: "flex", alignItems: "center", gap: '5px' }}>
                                        {/* <Box sx={{ width: "150px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}> */}
                                        {/* <Typography color="black" onClick={() => setState({ ...state, rosterDialog: true })} className="pageMainTitle" id="pageTitle"> Wish</Typography> */}
                                        <Typography sx={{ width: "150px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }} color="black" className="pageMainTitle" id="pageTitle"> {selectedEvents?.eventName} </Typography>
                                        {/* </Box> */}

                                        <img src={require('../Assets/images/events.png')} alt="Logo" onClick={handleEventsDialog} style={{ marginRight: 10 }} />
                                        <Box sx={{ display: { sm: "none" } }} onClick={handleBeep} className="beepIcons">
                                            {!isBeepAllow ? <img src={require('../Assets/images/no-audio.png')} width="30px" alt="cam" /> :
                                                <img src={require('../Assets/images/volumeIcon.png')} width="30px" alt="cam" />}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Toolbar>
                    </AppBar>
                </Box>
                <Box>
                    {/* <ConnectivityStatus /> */}
                </Box>
            </Box>
            <Box className="mainContainer">
                {isLoading ? <Box className="loader">
                    {/* <img src={require('../Assets/images/videoLoader.gif')} alt='logo' /> */}
                </Box> : ""}
                <Box className='leftChild'>
                    <Box className='scannerContainer'>
                        <QuaggaBarcodeScanner isSettingChange={isSettingChange} selectedFormats={selectedFormats} flipCamera={flipCamera} zoom={zoom} focusMode={focusMode} applySetting={applySetting} isBeepAllow={isBeepAllow} getSeniorIdByTag={getSeniorIdByTag} handleAudio={handleAudio} />
                        {/* <Box className="cornerBorder"></Box> */}

                        <Box onClick={handleBeep} className="beepIcons smBeepIcon">
                            {!isBeepAllow ? <img src={require('../Assets/images/no-audio.png')} width="30px" alt="cam" /> :
                                <img src={require('../Assets/images/volumeIcon.png')} width="30px" alt="cam" />}
                        </Box>

                        <Box onClick={() => setStateOne({ ...stateOne, seniorDataPopup: true })} className="beepIcons mdBeepIcon">
                            <img src={require('../Assets/images/list-icon.png')} width="30px" alt="cam" />
                        </Box>

                        <Box onClick={toggleFlipCamera} className="cameraIcons">
                            <img src={require('../Assets/images/camera.png')} width="30px" alt="cam" />
                        </Box>
                        <Box onClick={handleCameraSettings} className="settingIcons">
                            <img src={require('../Assets/images/settingIcon.png')} width="30px" alt="cam" />
                        </Box>


                    </Box>
                    <Box className='welcomeContainer'>
                        {seniorData?.firstName && number.current > 0 ? <Box className="content" sx={{ borderBottom: 0 }}>
                            <Box
                                className="infoCard"
                                sx={{
                                    borderBottom: "0 !important",
                                    marginBottom: "0px !important",
                                    marginTop: "25px !important",
                                    alignItems: "center",
                                    animation: `${fadeIn} 0.2s forwards`,
                                    animationDelay: animationTrigger ? "0s" : "0s",
                                }}
                            >
                                <Box className="image" >
                                    <img width={"100%"} onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = seniorData.photoURL;
                                    }} src={Helpers.addSuffixToUrl(seniorData.photoURL)} alt="senior" />
                                </Box>
                                <Box

                                >
                                    <Typography sx={{
                                        animation: `${animationTrigger ? bounce : ""} 0.5s ease-in-out, ${animationTrigger ? fadeOut : ""
                                            } 2s ease-in-out 3s forwards`,
                                    }} className="MainTitle" style={{ fontSize: "30px" }}>
                                        Welcome
                                    </Typography>
                                    <Typography
                                        className="MainTitle1"
                                        sx={{
                                            fontSize: "24px",
                                            animation: `${bounce} 0.5s ease-in-out`,
                                        }}
                                    >
                                        {seniorData?.firstName} {seniorData?.lastName}
                                    </Typography>
                                    <Typography className="subTitle" style={{ fontSize: "20px" }}>
                                        City: {seniorData?.city}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                            : <Box style={{ display: "flex", flexDirection: "column", height: "78%", justifyContent: "center", textAlign: "center", alignItems: "center", }}>
                                <img src={require(`../Assets/images/notfound.png`)} sx={{}} alt="imge" width="100px" height="100px" />
                                <Typography variant='h3' >Please Scan Barcode</Typography>

                            </Box>}
                    </Box>
                </Box>
                <Box className='rightChild' >
                    <Box className="content">
                        {data?.seniors?.length ?
                            data?.seniors?.map((data, index) => (
                                <SeniorInformation state={stateOne} setState={setStateOne} syncLoader={syncLoader} currentSeniorId={currentSeniorId.current} isOnline={isOnline} key={index} data={data} setData={setData} />
                            )) :
                            !seniorLoader ?
                                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%" }}>
                                    <img src={require('../Assets/images/no-list-found.png')} width={"75%"} alt='nodata' />
                                    <Typography sx={{ textAlign: "center", fontWeight: 600 }} variant='body2'>The list of seniors will be made available here once the scanning is completed.</Typography>
                                </Box>
                                : ""
                        }
                        {seniorLoader ? <Stack p={1} display='flex' flexDirection="row" alignItems="center" spacing={1}>
                            <Skeleton variant="circular" width={50} height={50} />
                            <Box sx={{ marginLeft: "5px !important", marginTop: "2px !important" }}>
                                <Skeleton variant="text" width={100} sx={{ fontSize: '1rem' }} />
                                <Skeleton variant="text" width={100} sx={{ fontSize: '1rem' }} />
                            </Box>
                        </Stack> : ""}
                    </Box>
                </Box>


            </Box>
            {/* popUp < sm screen for senior info */}
            <DialogBox
                fullScreen
                TransitionComponent={Transition}
                open={stateOne.seniorDataPopup}
                onClose={() => setStateOne({ ...stateOne, seniorDataPopup: false })}
                aria-labelledby="logout-alert-title"
                aria-describedby="logout-alert-description"
            >
                <DialogTitle id="logout-alert-title" sx={{ fontSize: "20px", paddingBottom: "8px", paddingLeft: "15px", paddingRight: "10px" }}>
                    <Box sx={{ display: "flex", justifyContent: "end", }}>
                        <CloseIcon onClick={() => setStateOne({ ...stateOne, seniorDataPopup: false })} />
                    </Box>
                    {/* <input className='input' onChange={handleFilter} inputprops={{ style: { fontSize: 12, fontWeight: 600 } }} type='text' placeholder='Search List' /> */}
                </DialogTitle>
                <DialogContent sx={{ paddingBottom: "10x", paddingLeft: "15px" }}>
                    <Box sx={{ height: "100%" }} className="content">
                        {data?.seniors?.length ?
                            data?.seniors?.map((data, index) => (
                                <SeniorInformation state={stateOne} setState={setStateOne} syncLoader={syncLoader} currentSeniorId={currentSeniorId.current} isOnline={isOnline} key={index} data={data} setData={setData} />
                            )) :
                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%" }}>
                                <img src={require('../Assets/images/no-list-found.png')} width={"50%"} alt='nodata' />
                                <Typography sx={{ textAlign: "center", fontWeight: 600 }} variant='body2'>The list of seniors will be made available here once the scanning is completed.</Typography>
                            </Box>
                        }
                        {seniorLoader ? <Stack p={1} display='flex' flexDirection="row" alignItems="center" spacing={1}>
                            <Skeleton variant="circular" width={50} height={50} />
                            <Box sx={{ marginLeft: "5px !important", marginTop: "2px !important" }}>
                                <Skeleton variant="text" width={100} sx={{ fontSize: '1rem' }} />
                                <Skeleton variant="text" width={100} sx={{ fontSize: '1rem' }} />
                            </Box>
                        </Stack> : ""}

                    </Box>
                </DialogContent>
            </DialogBox>

            {/* PopUp Event*/}
            <DialogBox
                fullScreen
                open={stateOne.eventsDialog}
                onClose={() => setStateOne({ ...stateOne, eventsDialog: false })}
                aria-labelledby="logout-alert-title"
                aria-describedby="logout-alert-description"
            >

                <DialogTitle id="logout-alert-title" sx={{ fontSize: "20px", paddingBottom: "8px", paddingLeft: "15px", paddingRight: "10px" }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", }}>
                        <Typography variant='subtitle1'>Events</Typography>
                        <SwipeableCalendar selectedDate={state?.selectedDate} handleChangeSelectedDate={handleChangeSelectedDate} />
                        <Box display='flex' gap={1}>
                            {/* {showEventSearch ? <SearchOff onClick={handleToggler} /> : */}
                            <SearchOn onClick={handleToggler} />
                            <CloseIcon onClick={handleClose} />
                        </Box>
                    </Box>

                    {showEventSearch ? <input className='input' onChange={handleFilterEventByName} inputprops={{ style: { fontSize: 12, fontWeight: 600, } }} type='text' placeholder='Search Event List' /> : ""}
                </DialogTitle>
                <DialogContent sx={{ paddingBottom: "10x", paddingLeft: "15px" }}>
                    <Backdrop
                        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                        open={loader}
                    // onClick={handleClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Box sx={{ hright: "100%" }} className="content">
                        {filterEvents?.length ?
                            filterEvents?.map((e, i) => {
                                return (
                                    <Box key={i} className='infoCard' sx={{ cursor: "pointer", backgroundColor: selectedEvents?.eventID === e.eventID ? "#dfd5e4d4" : "" }} onClick={() => handleSelectedEvents(e.eventID)}>
                                        <Box className="image">
                                            <img src={`https://d2ap19ik6wt59k.cloudfront.net/icons/${e.icon}s.png`} alt='icon' width="100%" />
                                        </Box>
                                        <Box>
                                            <Typography className="MainTitle" > {e.eventName} </Typography>
                                            <Typography className="subTitle">{e.room}</Typography>
                                            <Typography className="subTitle"><span style={{ fontWeight: 600 }}>Time: </span>{e.startTime} - {e.endTime}</Typography>
                                        </Box>
                                    </Box>
                                )
                            }) : <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%" }}>
                                <img src={require('../Assets/images/no-list-found.png')} width={"30%"} alt='nodata' />
                                <Typography sx={{ textAlign: "center", fontWeight: 600 }} variant='body2'>No events scheduled for the selected date.</Typography>
                            </Box>}

                    </Box>
                </DialogContent>

            </DialogBox>
            {/* popup roster */}
            <DialogBox
                open={stateOne.rosterDialog}
                onClose={() => setStateOne({ ...stateOne, rosterDialog: false })}
                aria-labelledby="logout-alert-title"
                aria-describedby="logout-alert-description"
            >

                <DialogTitle id="logout-alert-title" sx={{ fontSize: "20px", paddingBottom: "8px", paddingLeft: "15px", paddingRight: "10px" }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", }}>
                        <Typography variant='subtitle1'>Rosters</Typography>
                    </Box>
                </DialogTitle>
                <DialogContent sx={{ paddingBottom: "10x", paddingLeft: "15px" }}>
                    <Backdrop
                        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                        open={loader}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Box sx={{ hright: "100%" }} className="content">
                        {data?.roster?.length ?
                            data.roster?.map((e, i) => {
                                return (
                                    <Box key={i} sx={{ margin: "4px 10px", cursor: 'pointer' }} >
                                        <Box className="infoCard">
                                            <Box className="image">
                                                <img onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null;
                                                    currentTarget.src = getSeniorNameOrImgByID(e.SeniorID, "img");
                                                }} src={Helpers.addSuffixToUrl(getSeniorNameOrImgByID(e.SeniorID, "img"))} alt='icon' width="100%" />
                                            </Box>
                                            <Box className="routeText">
                                                <Typography className="subTitle"><span style={{ fontWeight: 600 }}>{getSeniorNameOrImgByID(e.SeniorID)} </span></Typography>
                                                {/* <Typography className="subTitle" ><span style={{ fontWeight: 600 }}>Status: </span>  {e.CheckinStatus} </Typography>
                                    <Typography className="subTitle"><span style={{ fontWeight: 600 }}>Checkin Time: </span>{e.CheckinTime} </Typography> */}
                                            </Box>
                                        </Box>
                                    </Box>)
                            }) : <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%" }}>
                                <img src={require('../Assets/images/no-list-found.png')} width={"30%"} alt='nodata' />
                                <Typography sx={{ textAlign: "center", fontWeight: 600 }} variant='body2'>No events scheduled for the selected date.</Typography>
                            </Box>}

                    </Box>
                </DialogContent>

            </DialogBox>
            {/* PopUp Welcom */}
            <DialogBox
                disablebackdropclick={true}
                fullWidth
                open={stateOne.welcomePopup}
                onClose={handleWelcomClose}
                aria-labelledby="logout-alert-title"
                aria-describedby="logout-alert-description"
            >
                <DialogContent sx={{ padding: 0 }}>
                    <Box className="content" sx={{ borderBottom: 0, }}>
                        <Box className='infoCard' sx={{ borderBottom: "0px", marginBottom: "0px !important", }}>
                            <Box className="image">
                                <img src={seniorData?.photoURL} sx={{}} alt="imge" width="80%" height="80%" />
                            </Box>
                            <Box>
                                <Typography className="MainTitle" > Welcome </Typography>
                                <Typography className="MainTitle1" > {seniorData?.firstName} {seniorData?.lastName} </Typography>
                                <Typography className="subTitle">Check In :{seniorData?.city}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ paddingLeft: "15px", paddingRight: "12px", display: 'flex', justifyContent: "end" }}>
                    <Button sx={{ backgroundColor: "#000524", color: "white" }} size='small' variant="contained" onClick={handleWelcomClose} >Ok</Button>
                </DialogActions>
            </DialogBox>

            {/* PopUp Assign Senior to CardID*/}
            <DialogBox
                fullScreen
                open={stateOne.assignSeniorPopup}
                onClose={() => setStateOne({ ...stateOne, assignSeniorPopup: false })}
                aria-labelledby="logout-alert-title"
                aria-describedby="logout-alert-description"
            >

                <DialogTitle id="logout-alert-title" sx={{ fontSize: "20px", paddingBottom: "8px", paddingLeft: "15px", paddingRight: "10px" }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", }}>
                        <Typography variant='subtitle1'>Assign Senior</Typography>
                        <Box display='flex' gap={1}>
                            <SearchOn onClick={handleToggler} />
                            <CloseIcon onClick={handleCloseAssign} />
                        </Box>
                    </Box>

                    {showEventSearch ? <input className='input' onChange={handleFilterSeniorByName} inputprops={{ style: { fontSize: 12, fontWeight: 600, } }} type='text' placeholder='Search Seniors' /> : ""}
                </DialogTitle>
                <DialogContent sx={{ paddingBottom: "10x", paddingLeft: "15px" }}>
                    <Backdrop
                        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                        open={loader}
                    // onClick={handleClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Box sx={{ hright: "100%" }} className="content" >
                        {filteredSeniorData?.length ?
                            filteredSeniorData?.slice(0, visibleSeniors).map((item, index) => (
                                <Box key={index} sx={{ margin: "4px 10px", cursor: 'pointer' }} onClick={() => handleOpenConf(`${item.firstName} ${item.lastName}`, item.seniorID)}>
                                    <Box className="routeBox">
                                        <Box className="image">
                                            <img width={"100%"} onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = item.photoURL;
                                            }} src={Helpers.addSuffixToUrl(item.photoURL)} alt="senior" />
                                        </Box>
                                        <Box className="routeText">
                                            <Typography className='routetextColor' variant='h1'>{item.firstName} {item.lastName}</Typography>
                                            <Typography className='textColor' variant='h5'>{item.city}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            )) :
                            <Box className="routeTextNull">
                                <Typography className='routetextColor' variant='h1'>
                                    No Seniors Found
                                </Typography>
                            </Box>}

                    </Box>
                </DialogContent>

            </DialogBox>

            {/* PopUp Conformation*/}
            <DialogBox
                open={stateOne.conformation}
                onClose={() => setStateOne({ ...stateOne, conformation: false })}
                aria-labelledby="logout-alert-title"
                aria-describedby="logout-alert-description"
            >
                <DialogContent sx={{ paddingBottom: "10x", paddingLeft: "15px" }}>
                    <Box sx={{ hright: "100%" }} className="content">
                        <Box className="routeTextNull">
                            <Typography variant='subtitle1'>
                                Are you sure you want to assign {seniorName.current} to {stateOne.tag}.
                            </Typography>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConformation} sx={{ backgroundColor: "red", color: "white" }} size='small' variant="contained" >Cancel</Button>
                    <Button sx={{ color: "white" }} onClick={handleAssignSeniors} color='success' size='small' variant="contained" >Assign</Button>
                </DialogActions>
            </DialogBox>

            {/* PopUp settings*/}
            <DialogBox
                open={stateOne.setting}
                onClose={() => setStateOne({ ...stateOne, setting: false })}
                aria-labelledby="logout-alert-title"
                aria-describedby="logout-alert-description"
            >
                <DialogContent sx={{ paddingBottom: "10x", paddingLeft: "15px" }}>
                    <Box sx={{ hright: "100%" }} className="content">
                        <Typography variant='subtitle1'>Barcode Type:</Typography>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="code_128"
                                        checked={selectedFormats.includes("code_128")}
                                        onChange={handleCheckboxChange}
                                        color="success"
                                    />
                                }
                                label={<Typography variant='body2'>Code 128</Typography>}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="code_39"
                                        checked={selectedFormats.includes("code_39")}
                                        onChange={handleCheckboxChange}
                                        color="success"
                                    />
                                }
                                label={<Typography variant='body2'>CODE 39</Typography>}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="code_93"
                                        checked={selectedFormats.includes("code_93")}
                                        onChange={handleCheckboxChange}
                                        color="success"
                                    />
                                }
                                label={<Typography variant='body2'>Code 93</Typography>}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="ean_13"
                                        checked={selectedFormats.includes("ean_13")}
                                        onChange={handleCheckboxChange}
                                        color="success"
                                    />
                                }
                                label={<Typography variant='body2'>EAN-13</Typography>}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="UPC_A"
                                        checked={selectedFormats.includes("UPC_A")}
                                        onChange={handleCheckboxChange}
                                        color="success"
                                    />
                                }
                                label={<Typography variant='body2'>UPC_A</Typography>}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="CODABAR"
                                        checked={selectedFormats.includes("CODABAR")}
                                        onChange={handleCheckboxChange}
                                        color="success"
                                    />
                                }
                                label={<Typography variant='body2'>CODABAR</Typography>}
                            />
                        </FormGroup>
                        <FormControl sx={{ marginBottom: 0, marginTop: 2 }} component="fieldset">
                            <Typography variant='subtitle1'>Zoom Level:</Typography>
                            <RadioGroup
                                row
                                value={zoom}
                                onChange={(e) => setZoom(parseInt(e.target.value))}
                            >
                                <FormControlLabel
                                    value={1}
                                    control={<Radio color="success" />}
                                    label={<Typography variant='body2'>1</Typography>}
                                />
                                <FormControlLabel
                                    value={2}
                                    control={<Radio color="success" />}
                                    label={<Typography variant='body2'>2</Typography>}
                                />
                                {/* <FormControlLabel
                                    value={3}
                                    control={<Radio color="success" />}
                                    label={<Typography variant='body2'>3</Typography>}
                                /> */}
                            </RadioGroup>
                        </FormControl>
                        <FormControl sx={{ marginBottom: 0, marginTop: 2 }} component="fieldset">
                            <Typography variant='subtitle1'>Auto Focuss:</Typography>
                            <RadioGroup
                                row
                                value={focusMode}
                                onChange={(e) => setFocusMode(e.target.value)}
                            >
                                <FormControlLabel
                                    value={"continuous"}
                                    control={<Radio color="success" />}
                                    label={<Typography variant='body2'>Enable</Typography>}
                                />
                                <FormControlLabel
                                    value={"single-shot"}
                                    control={<Radio color="success" />}
                                    label={<Typography variant='body2'>Disable</Typography>}
                                />

                            </RadioGroup>
                        </FormControl>

                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSetting} sx={{ backgroundColor: "red", color: "white" }} size='small' variant="contained" >Cancel</Button>
                    <Button sx={{ color: "white" }} onClick={handleApplySettings} color='success' size='small' variant="contained" >Apply</Button>
                </DialogActions>
            </DialogBox>
        </Root>

    );
}
export default NewScanner;


const SeniorInformation = (props) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    useEffect(() => {
        setAnchorEl(null);
    }, [props.state.assignSeniorPopup])
    const buttonText = ["Pay", "Info", "Message", "Delete",]
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleAssign = (tag) => {
        props.setState({ ...props.state, assignSeniorPopup: true, tag: tag });
    }
    const handleRemoveFromLocal = (id) => {
        let scannedSeniors = JSON.parse(localStorage.getItem("scannedSeniors"))
        let updatedSenior = scannedSeniors?.filter(x => x.seniorID !== id)
        localStorage.setItem("scannedSeniors", JSON.stringify(updatedSenior))
        props.setData({ ...props.data, seniors: updatedSenior });
        handleClose()
    }
    return (


        <Box className='infoCard'>
            <Box className="image">
                <img width={"100%"} onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = props?.data?.photoURL;
                }} src={Helpers.addSuffixToUrl(props?.data?.photoURL)} alt="senior" />
            </Box>
            <Box sx={{ width: "100%" }} display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                    <Typography sx={{ color: props.data.isSync ? "" : "#ccc" }} className="MainTitle" > {props?.data?.firstName} {props?.data?.lastName} </Typography>
                    <Typography sx={{ color: props.data.isSync ? "" : "#ccc" }} className="subTitle">{props?.data?.city}</Typography>
                </Box>
                {props.syncLoader && !props.data.isSync ? <Typography sx={{ color: "green" }} className="subTitle">Syncing</Typography> :
                    props.data.city !== "Unknown" ?
                        <> <IconButton
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                        >
                            <MoreVertIcon />
                        </IconButton>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                {buttonText.map((el, ind) => (<MenuItem key={ind} sx={{ fontWeight: 400 }} onClick={() => handleRemoveFromLocal(props?.data?.seniorID)}>{el}</MenuItem>))}
                            </Menu>
                        </>
                        : <>
                            <IconButton
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem sx={{ fontWeight: 400 }} onClick={() => handleAssign(props?.data?.seniorID)}>Assign</MenuItem>
                                <MenuItem sx={{ fontWeight: 400 }} onClick={() => handleRemoveFromLocal(props?.data?.seniorID)}>Remove</MenuItem>
                            </Menu></>}
            </Box>
        </Box>
    );
}