import React, { useEffect, useRef, useState } from 'react'
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';

import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { GetSeniorsBySite, GetAllKeytags } from '../../Services/Api/AppService';
import { AppBar, Box, CircularProgress, IconButton, InputAdornment, LinearProgress, TextField, Toolbar, Typography, } from '@mui/material';

import Helpers from '../../Shell/Helper';

const Root = styled(Box)(({ theme, loader }) => ({
    height: '100%',
    padding: 0,
    backgroundColor: "#f8e9f7",
    '& .header-cont': {
        // width: "100%",
        width: "device-width",
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        "& header": {
            display: "flex",
            height: 48,
            background: "white",
            fontSize: 12,
            alignItems: "center",
            '& img[alt="Logo"]': {
                width: 34,
                filter: 'invert(1)',
            },
            "& .headerNav": {
                // marginRight: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                "& .fuel": {
                    color: "red",
                    position: "absolute",
                    width: "26px",
                    right: "62px",
                    top: "12px",
                    cursor: "pointer"
                },
                "& .pageMainTitle": {
                    fontSize: 16,
                    fontWeight: 600,
                    whiteSpace: "nowrap",
                    width: "260px",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                },
                "& .headerText": {
                    display: "flex",
                    color: '#1f0757',
                    fontSize: 13,
                    textShadow: '#1f0757',
                    fontWeight: 600,
                    whiteSpace: "nowrap"
                },
                "& .refreshingIcon": {
                    color: "Grey"
                },
                "& .reloadBtn": {
                    padding: "0px 0px 4px 0px"
                }
            }
        },
    },
    '& .mainContainer': {
        backgroundImage: `url(${require('../../Assets/loginBackgroundImg/img1.png')})`,
        backgroundSize: "cover",
        overflow: "hidden",
        backgroundPosition: "center",
        backgroundColor: "#f8e9f7",
        height: "calc(100vh - 56px)",
        marginTop: loader ? 50 : 56,
        overflowY: "auto",
        "& .routeTextNull": {
            height: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"
        },
        "& .routeBox": {
            margin: "20px auto ",
            display: "flex",
            width: "100",
            minWidth: 310,
            gap: 10,
            backgroundColor: theme.palette.primary.contrastText,
            padding: "10px",
            boxShadow: "0px 3px 15px -6px rgb(0 0 0 / 50%)",
            borderRadius: 10,
            "& .image": {
                height: "50px",
                width: "50px",
                borderRadius: "50%",
                overflow: "hidden",
                "& img": {
                    objectFit: "contain",
                    width: "100%",
                    height: "100%"
                }
            },
            "& .routeText": {
                display: "flex",
                flexDirection: "column",
            },
            "& .routetextColor": {
                fontSize: 20,
                fontWeight: 400,
                color: theme.palette.other.main
            },
            "& .textColor": {
                fontSize: 16,
                fontWeight: 300,
                color: theme.palette.primary.dark
            }
        },
    },
    '& img[alt="heartpng"]': {
        width: 50,
    },
}))
function SeniorList() {
    const [searchTerm, setSearchTerm] = useState('');
    const [appInfo, setAppInfo] = useState({});
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
    const [progress, setProgress] = React.useState(10);
    const [loader, setLoader] = useState(false);
    const [seniorListBySite, setSeniorListBySite] = useState([]);
    const [filteredData, setFilterData] = useState([]);
    const [visibleSeniors, setVisibleSeniors] = useState(50);

    const containerRef = useRef(null);
    let navigate = useNavigate();

    //Initial calls
    useEffect(() => {
        let appInfo = Helpers.getAppData()
        if (appInfo) {
            setAppInfo(appInfo);
        } else {
            setAppInfo({ centerName: "My Senior Center", centerLogURI: "/Assets/icons/msc_heart.png" });
        }
    }, [])

    useEffect(() => {
        let isAlreadyVisited = JSON.parse(localStorage.getItem('alreadyVisited'));
        if (!isAlreadyVisited) {
            localStorage.setItem('alreadyVisited', true)
            getSeniorsList()
            getAllKey()
        }
    }, [])

    useEffect(() => {
        const seniors = JSON.parse(localStorage.getItem('Seniors'));
        setSeniorListBySite(seniors);
        setFilterData(seniors);
    }, []);

    //Scroll function
    useEffect(() => {
        const handleScroll = () => {
            if (containerRef.current) {
                const bottom = containerRef.current.scrollHeight - containerRef.current.scrollTop === containerRef.current.clientHeight;
                if (bottom && visibleSeniors < seniorListBySite.length) {
                    setVisibleSeniors(prevVisibleSeniors => prevVisibleSeniors + 10);
                }
            }
        };

        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll); // Cleanup on unmount
            }
        };
    }, [visibleSeniors, seniorListBySite]);

    useEffect(() => {
        let currentSearch = localStorage.getItem('searchTerm');
        if (currentSearch) {
            setSearchTerm(currentSearch);
        }
    }, [])
    useEffect(() => {
        let currentSearch = localStorage.getItem('searchTerm');
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(currentSearch);
        }, 700);

        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);
    useEffect(() => {
        if (!debouncedSearchTerm) {
            setFilterData(seniorListBySite);
            return;
        }
        const filteredValues = seniorListBySite?.filter(({ firstName, lastName }) => {
            const fullName = `${firstName} ${lastName}`.toLowerCase();
            const searchTerms = debouncedSearchTerm.toLowerCase().split(" ");
            return searchTerms.every(term => fullName.includes(term));
        });
        setFilterData(filteredValues);
    }, [debouncedSearchTerm, seniorListBySite]);

    //Handle Change'
    const handleSearchChange = (event) => {
        localStorage.setItem("searchTerm", event.target.value);
        setSearchTerm(event.target.value);
    };

    //Get Seniors List and KeyTags
    const getSeniorsList = async (isRefresh = null) => {
        setLoader(true)
        setProgress(0)
        const timer = setInterval(() => {
            if (progress >= 80) {
                setProgress((prevProgress) => (prevProgress >= 100 ? 80 : prevProgress + 10));
            }

        }, 800);
        try {
            let res = await GetSeniorsBySite()
            // setSeniorListBySite(res.data)
            localStorage.setItem('Seniors', JSON.stringify(res.data))
            setProgress(100)
        } catch (error) {
            console.log(error)
            setLoader(false)
        } finally {
            clearInterval(timer);
            const seniors = JSON.parse(localStorage.getItem('Seniors'));
            setSeniorListBySite(seniors);
            setFilterData(seniors);
            setLoader(false)
            if (isRefresh) {
                getAllKey()
            }
        }
    }
    const getAllKey = async () => {
        try {
            let res = await GetAllKeytags();
            // setKeyTags(res.data)
            localStorage.setItem('KayTags', JSON.stringify(res.data))
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false)
        }
    }
    const openDetail = (id) => {
        navigate("/senior-detail", { state: id })
    }

    return (
        <Root p={2} loader={loader}>
            {/* Header */}

            <Box className="header-cont">
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static">
                        <Toolbar
                            sx={{ width: "100%", maxWidth: "100%", padding: "0px 15px 0px 45px !important" }}>
                            <Box className="headerNav">
                                <Box
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2, display: "inline-flex", alignItems: "center" }}>
                                    {appInfo?.centerLogURI ?
                                        <img src={appInfo.centerLogURI}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = require("../../Assets/icons/msc_heart.png");
                                            }}
                                            alt="Logo" style={{ marginRight: 10 }}
                                        /> : <img src={require(`../../Assets/icons/msc_heart.png`)} alt="Logo" style={{ marginRight: 10 }}
                                        />}
                                    <div>
                                        <Typography color="black" className="pageMainTitle" id="pageTitle">
                                            {appInfo.centerName}
                                        </Typography>
                                        <Typography color="black" className="headerText" id="pageTitle">
                                            Seniors
                                        </Typography>
                                    </div>

                                </Box>
                                <IconButton className="reloadBtn" onClick={() => getSeniorsList("refresh")} >
                                    {loader ? <CircularProgress size={20} className='refreshingIcon' /> : <RefreshIcon size={20} />}
                                </IconButton>
                            </Box>
                        </Toolbar>
                    </AppBar>
                </Box>
            </Box>
            {/* search bar */}
            {!loader ? <Box mx={2} sx={{ zIndex: "0" }} height={50} >
                <TextField
                    sx={{
                        marginTop: "56px",
                        color: 'red',
                        zIndex: 2, fontSize: 16, fontWeight: 200,
                        "& .MuiOutlinedInput-root": {
                            color: "black !important",
                            borderRadius: "40px",
                            fontSize: "16px",
                            fontWeight: "600",
                            backgroundColor: "#fff",
                            "& Mui-focused": {
                                border: "1px solid grey",
                                outline: "1px solid grey",
                            },
                            "& fieldSet": {

                            },
                            "& .MuiInputBase-input": {
                                padding: "14px 0px"
                            }
                        },
                    }}
                    className='searchInput'
                    InputProps={{
                        startAdornment: <InputAdornment sx={{ cursor: 'pointer' }} position='start'> <SearchIcon sx={{ color: "#c4c4c4", fontSize: "30px", marginTop: "4px" }} /> </InputAdornment>
                    }}
                    placeholder='Search'
                    onChange={handleSearchChange}
                    value={searchTerm}
                />
            </Box> :
                ""}
            {/* Senior List */}
            <Box className="mainContainer" ref={containerRef}>
                {loader ?
                    <>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ width: '100%', mr: 1, mt: 1 }}>
                                <LinearProgress variant="determinate" value={progress} color='secondary' />
                            </Box>
                        </Box>
                        <Box className="routeTextNull">
                            <Box sx={{ minWidth: 35 }}>
                                <Typography variant="subtitle1" sx={{ color: '#3aa7f7' }}>
                                    {`${Math.round(progress)}%`}
                                </Typography>
                            </Box>
                            <Typography variant='subtitle1'>
                                Loading Seniors
                            </Typography>
                        </Box>
                    </>
                    :
                    filteredData?.length ?
                        filteredData?.slice(0, visibleSeniors).map((item, index) => (
                            <Box key={index} sx={{ margin: "4px 10px", cursor: 'pointer' }} onClick={() => { openDetail(item.seniorID) }}>
                                <Box className="routeBox">
                                    <Box className="image">
                                        <img width={"100%"} onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = item.photoURL;
                                        }} src={Helpers.addSuffixToUrl(item.photoURL)} alt="senior" />
                                    </Box>
                                    <Box className="routeText">
                                        <Typography className='routetextColor' variant='h1'>{item.firstName} {item.lastName}</Typography>
                                        <Typography className='textColor' variant='h5'>{item.city}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        )) :
                        <Box className="routeTextNull">
                            <Typography className='routetextColor' variant='h1'>
                                No Seniors Found
                            </Typography>
                        </Box>
                }
            </Box>
        </Root>
    )
}

export default SeniorList