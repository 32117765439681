import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import DefaultLayout from '../Layouts/DefaultLayout';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import SettingsIcon from '@mui/icons-material/Settings';
import SupportIcon from '@mui/icons-material/Support';
import LogoutIcon from '@mui/icons-material/Logout';
import CachedIcon from '@mui/icons-material/Cached';
import { useNavigate } from 'react-router-dom';
import AuthService from '../Services/AuthService';

const Root = styled(Box)(({ theme }) => ({
  height: '100%',
  padding: 0,
  '& .mainContainer': {
    backgroundImage: `url(${require('../Assets/loginBackgroundImg/img1.png')})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: "#f8e9f7",
    overflowY: "auto",
    display: "flex",
    justifyContent: "center",
    height: "calc(100vh - 48px)",
    marginTop: 48,
    "& .content": {
      display: "inline-flex",
      flexDirection: 'column',
      alignItems: "center",
      gap: 20,
      height: "100%",
      justifyContent: "center",

      "& .text": {
        textAlign: 'center',
        "& .header": {
          fontSize: 52,
          color: theme.palette.other.text,
        }
      },
      "& .btn": {
        fontSize: 18,
        borderRadius: 20,
        borderColor: theme.palette.button.main,
        maxWidth: "70%",
        color: theme.palette.button.main,
      },
      "& .btn:hover": {
        backgroundColor: theme.palette.button.main,
        borderColor: theme.palette.button.main,
        color: theme.palette.primary.contrastText,
        borderRadius: 20,
        maxWidth: "70%",
      },
      "& .LogOutBtn": {
        fontSize: 18,
        borderRadius: 20,
        borderColor: theme.palette.error.main,
        maxWidth: "70%",
        color: theme.palette.error.main
      },
      "& .LogOutBtn:hover": {
        borderRadius: 20,
        borderColor: theme.palette.error.main,
        maxWidth: "70%",
        color: theme.palette.error.contrastText,
        backgroundColor: theme.palette.error.main,
      },
      "& .versionBtn": {
        fontSize: 18,
        backgroundColor: '#00807C',
        "&:hover": {
          fontSize: 18,
          backgroundColor: '#048b86',
        },
      },
      '& img[alt="heartpng"] ': {
        display: "flex",
        width: 105,
        animation: " heartbeat 1.2s infinite",
      },

      "@keyframes heartbeat": {
        "0%": {
          transform: "scale(1)",
        },
        "25%": {
          transform: "scale(0.9)",
        },
        "60%": {
          transform: "scale(1.1)",
        },
        "100%": {
          transform: "scale(1)",
        }
      }
    },

  },

}));
export default function MSC() {
  let navigate = useNavigate();
  const [state, setState] = useState({
    PageTitle: 'My Senior Center',
  })
  useEffect(() => {
    let Token = AuthService.getAuthDataByName("token") || "";
    if (Token === "") {
      navigate("/");
    }
    else {
      // Set CenterInfo call here
      setPageInfo();
    }
    // eslint-disable-next-line 
  }, [navigate])

  const setPageInfo = () => {
    setState({ ...state, PageTitle: "My Senior Center" })
  }

  const clearAllCache = () => {
    if (window.confirm("You are about to clear the whole application cache. Are you sure you want to continue")) {
      caches.keys().then(cacheNames => {
        cacheNames.forEach(cacheName => {
          caches.delete(cacheName);
        });
      }).then(() => {
        return navigator.serviceWorker.getRegistrations();
      }).then(registrations => {
        registrations.forEach(registration => {
          registration.unregister();
          window.location.reload();
        });
      }).catch(error => {
        console.log(`Cache and service worker cleanup failed: ${error}`);
      });
    }
  }

  return (
    <DefaultLayout>
      <Root p={2} >
        <Box className="mainContainer">
          <Box className="content">
            <Box > <img src={`${window.location.origin}/images/logo.png`} alt="heartpng" /> </Box>
            <Box className="text"> <Typography className='header' variant="h2">{state.PageTitle}</Typography> </Box>
            <Button className="btn" fullWidth variant="outlined" >Settings <SettingsIcon sx={{ marginLeft: 1 }} /></Button>
            <Button className="btn" fullWidth variant="outlined">Help <SupportIcon sx={{ marginLeft: 1 }} /></Button>
            <Button className="LogOutBtn" fullWidth variant="outlined" onClick={() => clearAllCache()}>Clear Cache <CachedIcon sx={{ marginLeft: 1 }} /></Button>
            <Button className="LogOutBtn" fullWidth variant="outlined" onClick={AuthService.signOut}>Logout <LogoutIcon sx={{ marginLeft: 1 }} /></Button>
            <Button className="versionBtn" variant="contained" >Version 1.2.1</Button>
          </Box>
        </Box>
      </Root>
    </DefaultLayout>
  )
}