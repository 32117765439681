import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Divider, IconButton, Typography, } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import AuthService from '../Services/AuthService';
import seniorIcon from '../Assets/images/senior-icon.png'
import scannerIcon from '../Assets/images/scan-icon.png'
import activitiesIcon from '../Assets/images/activities-icon.png'
import mealsIcon from '../Assets/images/meal-icon.png'
import ridesIcon from '../Assets/images/ride-icon.png'
import volunteerIcon from '../Assets/images/volunteer.png'
import photoIcon from '../Assets/images/photos-icon.png'
// Dialog
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import constant from '../Shell/Constant';
import Helpers from '../Shell/Helper';
const Root = styled(Box)(({ theme }) => ({
    "& .drawerIcon": {
        position: "absolute",
        top: 5,
        left: 5,
        zIndex: "9",
        "& .MuiPaper-root": {
            height: "100% !importan",
        },
        "& .applogo": {
            display: "flex",
        },
    },
}))
export default function SwipeableTemporaryDrawer(props) {
    const [state, setState] = React.useState({
        left: false,
        logoutAlert: false,
    });
    const [activeIndex, setActiveIndex] = React.useState(null);
    const [mode, setMode] = React.useState();
    const [appName, setAppName] = React.useState('');
    const [logo, setLogo] = React.useState('');
    const [appInfo, setAppInfo] = React.useState({});
    const hostName = window.location.hostname;
    // let PagesRoutesOne = ['Switch to Seniors', 'Switch to Scanner', 'Switch to Activities', 'Switch to Meals', 'Switch to Rides', 'Mini Kiosk Mode', 'Volunteer']
    let PagesRoutesOne = [
        { icon: seniorIcon, title: 'Switch to Seniors' },
        { icon: scannerIcon, title: 'Switch to Scanner' },
        { icon: activitiesIcon, title: 'Switch to Activities' },
        { icon: mealsIcon, title: 'Switch to Meals' },
        { icon: ridesIcon, title: 'Switch to Rides' },
        { icon: scannerIcon, title: 'Mini Kiosk Mode' },
        { icon: volunteerIcon, title: 'Volunteer' },
        { icon: photoIcon, title: 'Photos' },]
    // let PagesRoutesOne = ['Volunteer']
    React.useEffect(() => {
        let darkMode = localStorage.getItem("darkMode");
        setMode(darkMode)
    }, [mode])
    React.useEffect(() => {
        let appInfo = Helpers.getAppData()
        if (appInfo) {
            setAppInfo(appInfo);
        } else {
            setAppInfo({ centerName: "My Senior Center", centerLogURI: "/Assets/icons/msc_heart.png" });
        }
    }, [])
    React.useEffect(() => {
        setLogo(AuthService.getAppDataByName("Logo"));
        let applicationName = AuthService.getAppDataByName("AppName");
        if (applicationName === null || applicationName === '') {
            setAppName("My Senior Center");
        } else {
            setAppName(AuthService.getAppDataByName("AppName"));
        }
        // eslint-disable-next-line
    }, [])
    React.useEffect(() => {
        getActiveIndex();
    }, [window.location.pathname])

    setTimeout(() => {
        setAppName(AuthService.getAppDataByName("AppName"));
        setLogo(AuthService.getAppDataByName("Logo"));
    }, 1000)
    let navigate = useNavigate()



    const handleSwitchOne = async (index) => {
        setState({ left: false });
        props.handlestateFromDrawer(false);

        const routes = [
            { path: '/app-senior', name: 'Senior', domain: [constant.driversDomain, constant.localDomain, constant.appDomain] },
            { path: '/scanner', name: 'Scanner', domain: [constant.driversDomain, constant.localDomain, constant.appDomain] },
            { path: '/activties', name: 'Activities', domain: [constant.driversDomain, constant.localDomain, constant.appDomain] },
            { path: '/meal', name: 'Meals', domain: [constant.mealsDomain, constant.localDomain, constant.appDomain] },
            { path: '/ride', name: 'Rides', domain: [constant.driversDomain, constant.localDomain,] },
            { path: '/newscan', name: 'Mini Kiosk Mode', domain: [constant.appDomain, constant.localDomain, constant.qaDomain] },
            { path: '/volunteer', name: 'Volunteer', domain: [constant.volunteerDomain, constant.volunteerDomain2, constant.localDomain, constant.appDomain] },
            { path: '/photos', name: 'Photos', domain: [constant.appDomain, constant.localDomain, constant.qaDomain] },
            { path: '/settings', name: 'Settings', domain: null } // Settings can be accessed from any domain
        ];

        if (index < 0 || index >= routes.length) {
            navigate('/settings');
            return;
        }
        const { path, name, domain } = routes[index];

        if (!domain || domain.includes(hostName)) {
            navigate(path);
        } else {
            navigate('/noaccess', { state: { name } });
        }
    };
    const getActiveIndex = () => {
        let path = window.location.pathname;
        switch (path) {
            case '/app-senior':
                setActiveIndex(0);
                break;
            case '/info':
                setActiveIndex(0);
                break;
            case '/scanner':
                setActiveIndex(1);
                break;
            case '/activties':
                setActiveIndex(2);
                break;
            case '/activity-details':
                setActiveIndex(2);
                break;
            case '/meal':
                setActiveIndex(3);
                break;
            case '/routesenior':
                setActiveIndex(3);
                break;
            case '/signature':
                setActiveIndex(3);
                break;
            case '/vehicle':
                setActiveIndex(4);
                break;
            case '/driver':
                setActiveIndex(4);
                break;
            case '/ride':
                setActiveIndex(4);
                break;
            case '/newscan':
                setActiveIndex(5);
                break;
            case '/volunteer':
                setActiveIndex(6);
                break;
            case '/photos':
                setActiveIndex(7);
                break;
            default:
                setActiveIndex(8);
                break;
        }
    }



    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setState({ left: open });
        props.handlestateFromDrawer(open)
    };

    const gotoLogout = (index) => {
        if (index === 0) {
            setState({ left: false });
            props.handlestateFromDrawer(false)
            navigate('/settings')

        } else if (index === 1) {
            setState({ ...state, logoutAlert: true });
        }
    }
    const handleCloseDrawer = () => {
        setState({ left: false })
        props.handlestateFromDrawer(false)
    }
    const handleRefresh = () => {
        setState({ ...state, logoutAlert: false });
        window.location.reload();
    }
    const handleLogOut = () => {
        setState({ ...state, logoutAlert: false });
        AuthService.signOut();
    }
    return (
        <Root >
            <div className='drawerIcon'>
                <React.Fragment key="left"  >
                    <IconButton onClick={toggleDrawer(true)}>
                        <MenuIcon />
                    </IconButton>

                    <SwipeableDrawer
                        anchor="left"
                        open={state.left}
                        onClose={toggleDrawer(false)}
                        onOpen={toggleDrawer(true)}
                    >
                        <Box className="mainContainer" sx={{ height: "100vh", backgroundImage: `url(${require("../Assets/loginBackgroundImg/img1.png")})`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover" }}>
                            <Box
                                sx={{ width: 250, }}
                                role="presentation"
                            >
                                <Box className="applogo" style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", gap: 12, margin: "15px 4px 15px 8px" }}>
                                    <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                        {appInfo?.centerLogURI ?
                                            <img src={appInfo.centerLogURI}
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null;
                                                    currentTarget.src = require("../Assets/icons/msc_heart.png");
                                                }}
                                                alt="Logo" width={"35px"} style={{ marginRight: 10 }}
                                            /> : <img src={require(`../Assets/icons/msc_heart.png`)} width={"35px"} alt="Logo" style={{ filter: "invert(1)", marginRight: 10 }}
                                            />}
                                        <Typography variant='subtitle' color="black" id="pageTitle">
                                            {appInfo.centerName}
                                        </Typography>
                                    </Box>
                                    <CloseIcon onClick={handleCloseDrawer} />
                                </Box>
                                <Divider />
                                <Box sx={{ display: "flex", flexDirection: "column", height: "90vh" }}>
                                    <Box sx={{ flex: "95%", }}>
                                        <List
                                        >
                                            {PagesRoutesOne.map((text, index) => (
                                                <React.Fragment key={text.title}>
                                                    <ListItem disablePadding
                                                        sx={{
                                                            backgroundColor: activeIndex === index ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
                                                            boxShadow: activeIndex === index ? '1px 0px 11px 16px rgba(236, 220, 230, 1.75)' : '',
                                                        }}
                                                    >
                                                        <ListItemButton onClick={() => handleSwitchOne(index)} >
                                                            <ListItemIcon sx={{ minWidth: "35px" }}>
                                                                <img src={text.icon} width="28px" alt={text.title} />
                                                            </ListItemIcon>
                                                            <ListItemText sx={{
                                                                padding: 1,
                                                                color: (hostName === constant.appDomain && index === 4)
                                                                    ? "#ccc" // Apply color if hostName is appDomain and index is 4
                                                                    : (hostName === constant.volunteerDomain || hostName === constant.volunteerDomain2) && index !== 6
                                                                        ? "#ccc"
                                                                        : hostName === constant.driversDomain && index === 6
                                                                            ? "#ccc"
                                                                            : hostName === constant.mealsDomain && index !== 3
                                                                                ? "#ccc"
                                                                                : ""
                                                            }} primary={text.title} />
                                                        </ListItemButton>
                                                    </ListItem>
                                                    {index < 6 && <Divider sx={{ margin: "0px 20px" }} />}
                                                </React.Fragment>
                                            ))}

                                        </List>

                                    </Box>
                                    <Box sx={{ flex: "5%" }}>
                                        <List >
                                            {['Settings', 'Logout'].map((text, index) => (
                                                <React.Fragment key={text}>
                                                    <ListItem disablePadding
                                                        sx={{
                                                            backgroundColor: (activeIndex === 8 && text == "Settings") ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
                                                            boxShadow: (activeIndex === 8 && text == "Settings") ? '1px 0px 11px 16px rgba(236, 220, 230, 1.75)' : '',
                                                        }}
                                                    >
                                                        <ListItemButton onClick={() => gotoLogout(index)}>
                                                            <ListItemIcon sx={{ minWidth: "35px" }}>
                                                                {index === 0 ? (
                                                                    <img src={require('../Assets/images/settingsicon.png')} width="28px" alt="Settings" />

                                                                ) : (
                                                                    <img src={require('../Assets/images/logout-icon.png')} width="30px" alt="Logout" />

                                                                )}

                                                            </ListItemIcon>
                                                            <ListItemText
                                                                onKeyDown={toggleDrawer(false)}
                                                                sx={{ padding: 1, }} primary={text} />
                                                        </ListItemButton>
                                                    </ListItem>
                                                    <Divider sx={{ margin: "0px 20px" }} />
                                                </React.Fragment>
                                            ))}
                                        </List>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </SwipeableDrawer>
                </React.Fragment>
            </div>

            <Dialog
                open={state.logoutAlert}
                onClose={() => setState({ ...state, logoutAlert: false })}
                aria-labelledby="logout-alert-title"
                aria-describedby="logout-alert-description"
            >

                <DialogTitle id="logout-alert-title" sx={{ fontSize: "20px", paddingBottom: "8px", paddingLeft: "15px", paddingRight: "10px" }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", }}>
                        <Typography sx={{}}>Are you sure?</Typography>
                        <CloseIcon onClick={() => setState({ ...state, logoutAlert: false, left: false })} />
                    </Box>

                </DialogTitle>
                <DialogContent sx={{ paddingBottom: "10x", paddingLeft: "15px" }}>
                    <Typography variant='body2' id="logout-alert-description">
                        Logging out means you will need an activation key which could take 10 minutes or several days depending on your center's availability
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ paddingLeft: "15px", paddingRight: "15px", borderTop: "1px solid #ccc", display: 'flex', justifyContent: "space-between" }}>
                    <Button sx={{ backgroundColor: "#000524", borderRadius: 30, color: "white" }} variant="contained" onClick={handleLogOut} autoFocus>Logout</Button>

                    <Button sx={{ backgroundColor: "#4caf50", borderRadius: 30, color: "white", "&:hover": { backgroundColor: "#4caf50" } }} variant="contained" onClick={handleRefresh} autoFocus>Refresh Application</Button>
                </DialogActions>
            </Dialog>

        </Root>
    );
}