import React, { useEffect, useRef, useState } from 'react';
import { AppBar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, getToolbarUtilityClass, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Helpers from '../Shell/Helper';
import CloseIcon from '@mui/icons-material/Close';

const Root = styled(Box)(({ theme }) => ({
    height: '100%',
    padding: 0,
    '& .header-cont': {
        // width: "100%",
        width: "device-width",
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        "& header": {
            display: "flex",
            height: 48,
            background: "white",
            fontSize: 12,
            alignItems: "center",
            '& img[alt="Logo"]': {
                width: 34,
                filter: 'invert(1)',
            },
            "& .headerNav": {
                // marginRight: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                "& .fuel": {
                    color: "red",
                    position: "absolute",
                    width: "26px",
                    right: "62px",
                    top: "12px",
                    cursor: "pointer"
                },
                "& .pageMainTitle": {
                    fontSize: 15,
                    fontWeight: 600,
                    whiteSpace: "nowrap"
                },
                "& .headerText": {
                    display: "flex",
                    color: '#1f0757',
                    fontSize: 13,
                    textShadow: '#1f0757',
                    fontWeight: 600,
                    whiteSpace: "nowrap"
                }
            }
        },
    },
    '& .mainContainer': {
        backgroundSize: "cover",
        overflow: "hidden",
        backgroundPosition: "center",
        // backgroundColor: "#f8e9f7",
        height: "calc(100vh - 48px)",
        marginTop: 48,
        "& .content": {
            padding: "10px",
            width: "100%",
            marginTop: "10px",
            "& .infoCard": {
                padding: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: "fit-content",
                border: "1px solid #ccc",
                borderRadius: "5px",
                gap: "10px",
                marginBottom: "10px",
                "& .image": {
                    maxWidth: "50px",
                    maxHeight: "50px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "& img": {
                        filter: "invert(1)",
                    }
                },
                "& .themeChanger": {
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                },
                "& .clear_cache_icon": {
                    cursor: "pointer",
                }
            },


        },
    },
    '& img[alt="heartpng"]': {
        width: 50,
    },
    "& .MainTitle": {
        fontSize: 15,
        fontWeight: 600,
        whiteSpace: "nowrap"
    },
    "& .subTitle": {
        fontSize: 15,
        fontWeight: 500,
        whiteSpace: "nowrap"
    },
}));


export default function Cache() {
    const [cachePopup, setCachePopup] = useState(false);
    const [totalSize, setTotalSize] = useState(0);
    let data = ["Seniors", "USER_DATA", "KayTags", "scannedSeniors", "PWA_SENIORS", "scannerSettings"]
    let cacheName = useRef('')
    useEffect(() => {
        getTotalCacheSize()
    }, [])
    const handleCache = () => {
        localStorage.removeItem(cacheName.current)
        if (cacheName.current === "Seniors" || cacheName.current === "KayTags") {
            localStorage.setItem('alreadyVisited', false)
        }
        alert("Cache cleared successfully");
        setCachePopup(false)
    }
    const handleOpenDialog = (name) => {
        cacheName.current = name
        setCachePopup(true)
    }
    const getTotalCacheSize = () => {
        let size = 0;
        data.forEach((el) => {
            size += Number(Helpers.getLocalStorageSize(el).kb)
        })
        setTotalSize(size)
    }
    return (

        <Root p={2} >
            {/* Header */}
            <Box className="header-cont">
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static">
                        <Toolbar
                            sx={{ width: "100%", maxWidth: "100%", padding: "0px 15px 0px 45px !important" }}>
                            <Box className="headerNav">
                                <Box
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2, display: "inline-flex", alignItems: "center" }}>

                                    <img src={require('../Assets/icons/msc_heart.png')} alt="Logo" style={{ marginRight: 10 }} />
                                    <div>
                                        <Typography color="black" className="pageMainTitle" id="pageTitle">
                                            My Senior Center
                                        </Typography>
                                        <Typography color="black" className="headerText" id="pageTitle">
                                            Cache Details
                                        </Typography>
                                    </div>
                                </Box>

                            </Box>
                        </Toolbar>
                    </AppBar>
                </Box>
            </Box>
            <Box className="mainContainer" px={2}>
                <Box className="content">
                    <Box display='flex' justifyContent="space-between">
                        <Typography className="MainTitle">Storage Used</Typography>
                        <Typography className="subTitle">{totalSize?.toFixed(2)} KB/5 MB</Typography>
                    </Box>
                    <Box className='infoCard' sx={{ cursor: "pointer" }}  >
                        <Box className="themeChanger">
                            <Box>
                                <Typography className="MainTitle">User Info</Typography>
                                <Typography className="subTitle"><span style={{ fontWeight: 600 }}>Size : </span>{Helpers.getLocalStorageSize("USER_DATA").kb} KB</Typography>
                            </Box>
                            <Box>
                                <img className='clear_cache_icon' onClick={() => handleOpenDialog("USER_DATA")} src={require("../Assets/icons/clearCache.png")} alt='icon' width={30} />
                            </Box>
                        </Box>
                    </Box>
                    <Box className='infoCard' sx={{ cursor: "pointer" }}  >
                        <Box className="themeChanger">
                            <Box>
                                <Typography className="MainTitle">Senior Data</Typography>
                                <Typography className="subTitle"><span style={{ fontWeight: 600 }}>Size : </span>{Helpers.getLocalStorageSize("Seniors").kb} KB</Typography>
                            </Box>
                            <Box>
                                <img className='clear_cache_icon' onClick={() => handleOpenDialog("Seniors")} src={require("../Assets/icons/clearCache.png")} alt='icon' width={30} />
                            </Box>
                        </Box>
                    </Box>
                    <Box className='infoCard' sx={{ cursor: "pointer" }}  >
                        <Box className="themeChanger">
                            <Box>
                                <Typography className="MainTitle">Keytag</Typography>
                                <Typography className="subTitle"><span style={{ fontWeight: 600 }}>Size : </span>{Helpers.getLocalStorageSize("KayTags").kb} KB</Typography>
                            </Box>
                            <Box>
                                <img className='clear_cache_icon' onClick={() => handleOpenDialog("KayTags")} src={require("../Assets/icons/clearCache.png")} alt='icon' width={30} />
                            </Box>
                        </Box>
                    </Box>
                    <Box className='infoCard' sx={{ cursor: "pointer" }}  >
                        <Box className="themeChanger">
                            <Box>
                                <Typography className="MainTitle">Scanned Seniors</Typography>
                                <Typography className="subTitle"><span style={{ fontWeight: 600 }}>Size : </span>{Helpers.getLocalStorageSize("scannedSeniors").kb} KB</Typography>
                            </Box>
                            <Box>
                                <img className='clear_cache_icon' onClick={() => handleOpenDialog("scannedSeniors")} src={require("../Assets/icons/clearCache.png")} alt='icon' width={30} />
                            </Box>
                        </Box>
                    </Box>
                    <Box className='infoCard' sx={{ cursor: "pointer" }}  >
                        <Box className="themeChanger">
                            <Box>
                                <Typography className="MainTitle">Meal Seniors</Typography>
                                <Typography className="subTitle"><span style={{ fontWeight: 600 }}>Size : </span>{Helpers.getLocalStorageSize("PWA_SENIORS").kb} KB</Typography>
                            </Box>
                            <Box>
                                <img className='clear_cache_icon' onClick={() => handleOpenDialog("PWA_SENIORS")} src={require("../Assets/icons/clearCache.png")} alt='icon' width={30} />
                            </Box>
                        </Box>
                    </Box>
                    <Box className='infoCard' sx={{ cursor: "pointer" }}  >
                        <Box className="themeChanger">
                            <Box>
                                <Typography className="MainTitle">Scanner Settings</Typography>
                                <Typography className="subTitle"><span style={{ fontWeight: 600 }}>Size : </span>{Helpers.getLocalStorageSize("scannerSettings").kb} KB</Typography>
                            </Box>
                            <Box>
                                <img className='clear_cache_icon' onClick={() => handleOpenDialog("scannerSettings")} src={require("../Assets/icons/clearCache.png")} width={30} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Dialog
                open={cachePopup}
                onClose={() => setCachePopup(false)}
                aria-labelledby="logout-alert-title"
                aria-describedby="logout-alert-description"
            >

                <DialogTitle id="logout-alert-title" sx={{ fontSize: "20px", paddingBottom: "8px", paddingLeft: "15px", paddingRight: "10px" }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", }}>
                        <Typography sx={{}}>Cache Clear Confirmation</Typography>
                        <CloseIcon onClick={() => setCachePopup(false)} />
                    </Box>

                </DialogTitle>
                <DialogContent sx={{ paddingBottom: "10x", paddingLeft: "15px" }}>
                    <Typography variant='body2' id="logout-alert-description">
                        Are you sure you want to clear the cache?
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ paddingLeft: "15px", paddingRight: "15px", borderTop: "1px solid #ccc", display: 'flex', justifyContent: "space-between" }}>
                    <Button sx={{ backgroundColor: "#000524", borderRadius: 30, color: "white" }} variant="contained" onClick={() => setCachePopup(false)} autoFocus>Cancel</Button>
                    <Button sx={{ backgroundColor: "#4caf50", borderRadius: 30, color: "white", "&:hover": { backgroundColor: "#4caf50" } }} variant="contained" onClick={handleCache} autoFocus>Yes, Clear Cache</Button>
                </DialogActions>
            </Dialog>
        </Root>

    )
}