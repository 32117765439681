import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import LeftIcon from '@mui/icons-material/West';
import RightIcon from '@mui/icons-material/East';
import CalendarIcon from '@mui/icons-material/CalendarMonth';
import DatePicker from "react-datepicker";
//Date Picker Style
import "react-datepicker/dist/react-datepicker.css";

const Root = styled(Box)(({ theme }) => ({
    "& .react-datepicker__input-container": {
        "& input": {
            width: "0px",
            position: "absolute",
            left: "50px",
            top: "-19px",
            zIndex: -1
        }
    },
    "& .calendar": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        // padding: "0 20px",
        overflow: "hidden"
    },
    "& .month-navigation": {
        display: "flex",
        alignItems: "center",

    },
    "& .month-navigation button": {
        border: "1px solid #000",
        borderRadius: "5px",
        cursor: "pointer",
        margin: "0 5px",
        backgroundColor: "white",
        color: "black",
        "& svg": {
            fontSize: "1.2rem"
        },

    },
    "& .month-navigation .date": {
        border: "1px solid #000",
        borderRadius: "5px",
        margin: "0 10px",
        backgroundColor: "white",
        padding: "4px",
        display: "flex",
        alignItems: "center",
        gap: "5px",
        cursor: "pointer"
    },





}));

const SwipeableCalendar = ({ handleChangeSelectedDate, selectedDate }) => {
    console.log(selectedDate, "===>swaip")
    const date = new Date()
    const today = date.getDate()
    const [currentDate, setCurrentDate] = useState(selectedDate || new Date());
    const [isOpen, setIsOpen] = useState(false);
    const dateInputRef = useRef(null);
    const day = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat",]
    const handleNextDate = () => {
        setCurrentDate(prevDate => new Date(prevDate.setDate(prevDate.getDate() + 1)));
        handleChangeSelectedDate(currentDate)
    };

    const handlePreviousDate = () => {
        setCurrentDate(prevDate => new Date(prevDate.setDate(prevDate.getDate() - 1)));
        handleChangeSelectedDate(currentDate)
    };
    const handleClick = () => {
        setIsOpen(true);
    };
    const handleDateChange = (date) => {
        setCurrentDate(date)
        handleChangeSelectedDate(date)
        setIsOpen(false)
    };
    return (
        <Root>
            <Box className="calendar">
                <div className="month-navigation">
                    <IconButton onClick={handlePreviousDate}>
                        <LeftIcon />
                    </IconButton>
                    <Box className="date" >
                        <CalendarIcon onClick={handleClick} />
                        <DatePicker
                            selected={currentDate}
                            onCalendarClose={() => setIsOpen(false)}
                            open={isOpen}
                            shouldCloseOnSelect={true}
                            onChange={handleDateChange}
                        />
                        <Typography variant='body2'>
                            {currentDate.getDate() === today ?
                                `Today(${day[currentDate.getDay()]})`
                                :
                                `${day[currentDate.getDay()]} - ${currentDate.getDate()} ${currentDate.toLocaleString('default', { month: 'short' })} `}

                        </Typography>
                    </Box>
                    <IconButton onClick={handleNextDate}>
                        <RightIcon />
                    </IconButton>
                </div>
            </Box>
        </Root>
    )
}
export default SwipeableCalendar;