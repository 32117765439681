import AuthService, { GetAuthToken, handleUnauthorized } from "../AuthService";
import AppSettings from "../AppSettings";
const hostName = window.location.hostname;
let baseUrl = AppSettings.serverUrl;
let GetRequest = async (endpoint, authorization = false, gsidRequired = false, newAuth = false) => {
    let auth = AuthService.getAuthDataByName("gsid");
    let maxRetries = 4;

    for (let attempt = 0; attempt < maxRetries; attempt++) {
        try {
            let headers = new Headers();

            // Set gsid if required
            if (gsidRequired) {
                let userToken = newAuth ? AuthService.getAuthDataByName("userToken") : null;
                headers.append('gsid', newAuth ? userToken?.centerInfo?.[0]?.gsid : auth);
            }

            // Set Authorization and other headers if needed
            if (authorization) {

                let authToken = await GetAuthToken(newAuth);
                headers.append('Authorization', authToken);
            }

            // Set common headers
            headers.append('Content-Type', 'application/json');
            headers.append('appVersion', AppSettings.dynamicCacheName);

            // Make the fetch request
            let response = await fetch(baseUrl + endpoint, {
                method: "GET",
                headers: headers
            });

            if (response.status === 401) {
                if (hostName === "drivers.myseniorcenter.net") {
                    if (attempt === 4) {
                        window.location.href = '/token-expired'
                    }
                } else {
                    window.location.href = '/token-expired'
                }

                await handleUnauthorized();
                // AuthService.signOut();
                continue; // Retry on unauthorized
            }

            return await response.json();

        } catch (err) {
            console.error(`Error fetching ${baseUrl + endpoint}:`, err);
        }
    }
};
let PostRequest = async (endpoint, data = null, authorization = false, gsidRequired = false, newAuth = false) => {
    let auth = AuthService.getAuthDataByName("gsid");
    var i = 0;
    while (i++ < 4) {
        try {
            let headers = {}
            if (data && data?.isRegister) {
                headers['Authorization'] = data.token;
                headers['Content-Type'] = 'application/json';
                headers['appVersion'] = AppSettings.dynamicCacheName;
                if (gsidRequired) {
                    let userToken = AuthService.getAuthDataByName("userToken")
                    if (userToken) {
                        headers['gsid'] = userToken?.centerInfo?.[0]?.gsid
                    }
                }
            } else {
                if (gsidRequired) {
                    if (newAuth) {

                        let userToken = AuthService.getAuthDataByName("userToken")
                        if (userToken) {
                            headers['gsid'] = userToken?.centerInfo?.[0]?.gsid
                        }
                    } else {
                        headers['gsid'] = auth
                    }
                }
                if (authorization) {
                    headers['Authorization'] = await GetAuthToken(newAuth);
                    headers['Content-Type'] = 'application/json';
                    headers['appVersion'] = AppSettings.dynamicCacheName;
                }
            }
            var response = await fetch(baseUrl + endpoint, {
                method: "POST",
                headers: new Headers(headers),
                body: data ? JSON.stringify(data) : null
            })
            if (response.status === 401) {
                if (hostName === "drivers.myseniorcenter.net") {
                    if (i === 4) {
                        window.location.href = '/token-expired'
                    }
                } else {
                    window.location.href = '/token-expired'
                }

                await handleUnauthorized();
                continue; // Retry on unauthorized
            }
            else {
                return await response.json();
            }
        } catch (err) {
            console.log(baseUrl + endpoint, err)
            // alert("something went wrong please refresh")
        }
    }
}

let PostFormsRequest = async (endpoint, data = null, authorization = false, gsidRequired = false, newAuth = false) => {
    var i = 0;
    while (i++ < 4) {
        try {
            let auth = AuthService.getAuthDataByName("gsid");
            let headers = authorization ? new Headers({ 'Authorization': await GetAuthToken(newAuth), 'appVersion': AppSettings.dynamicCacheName }) : {};

            if (gsidRequired) {
                if (newAuth) {
                    let userToken = AuthService.getAuthDataByName("userToken")
                    if (userToken) {
                        headers.gsid = userToken?.centerInfo?.[0]?.gsid
                    }
                } else {
                    headers.gsid = auth
                }
            }

            console.log('request post object: ', {
                method: "POST",
                headers: headers,
                body: data ? data : null
            });
            for (var pair of data.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
            }
            var response = await fetch(baseUrl + endpoint, {
                method: "POST",
                headers: headers,
                body: data
            })
            if (response.status === 401) {
                if (hostName === "drivers.myseniorcenter.net") {
                    if (i === 4) {
                        window.location.href = '/token-expired'
                    }
                } else {
                    window.location.href = '/token-expired'
                }

                await handleUnauthorized();
                continue; // Retry on unauthorized
            }
            else {
                return await response.json();
            }
        } catch (err) {
            console.log(baseUrl + endpoint, err)
        }
    }
}
let AuthRequest = async (endpoint) => {
    try {
        const response = await fetch(baseUrl + endpoint);
        return await response.json();
    } catch (error) {
        console.log(baseUrl + endpoint, error)
    }
}



export { GetRequest, PostRequest, PostFormsRequest, AuthRequest }