import React, { useEffect, useState } from 'react';
import { Box, Button, DialogContent, Divider, IconButton, Typography, TextField, AppBar, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { CancelSignature } from '../../Services/Api/MealService';
import DefaultLayout from '../../Layouts/DefaultLayout';
import AuthService from '../../Services/AuthService';
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
const Root = styled(Box)(({ theme }) => ({
    height: '100%',
    padding: 0,
    '& .header-cont': {
        // width: "100%",
        width: "device-width",
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        "& header": {
            display: "flex",
            height: 48,
            background: "white",
            fontSize: 12,
            alignItems: "center",
            '& img[alt="Logo"]': {
                width: 34,
                filter: 'invert(1)',
            },
            "& .headerNav": {
                // marginRight: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                "& .fuel": {
                    color: "red",
                    position: "absolute",
                    width: "26px",
                    right: "62px",
                    top: "12px",
                    cursor: "pointer"
                },
                "& .pageMainTitle": {
                    fontSize: 15,
                    fontWeight: 600,
                    whiteSpace: "nowrap"
                },
                "& .headerText": {
                    display: "flex",
                    color: '#1f0757',
                    fontSize: 13,
                    textShadow: '#1f0757',
                    fontWeight: 600,
                    whiteSpace: "nowrap"
                }
            }
        },
    },
    '& .mainContainer': {
        backgroundSize: "cover",
        overflow: "hidden",
        backgroundPosition: "center",
        height: "calc(100vh - 48px)",
        marginTop: 48,
        "& .formContent": {
            marginTop: 12,
            padding: 16,
            "& .textSize": {
                fontSize: "16px !important",
                fontWeight: "100 !important"
            },
            "& .btn": {
                "& .MuiFormLabel-root ": {
                    color: "#726a6a"
                },
                '& .MuiInputBase-root': {
                    border: "1px solid #ccc",
                    '& .MuiInputBase-input': {
                        fontSize: 16,
                        fontWeight: 300,
                        color: "black",
                    }

                }
            }
        }
    },

    "& .outlineButton": {
        fontSize: 14,
        color: "white",
        backgroundColor: theme.palette.error.light,

        "&:hover": {
            color: "white",
            backgroundColor: theme.palette.error.light,

        }
    },
    "& .containedButton": {
        color: "white",
        borderColor: theme.palette.button.main,
        backgroundColor: theme.palette.success.light,
        "&:hover": {
            color: "white",
            borderColor: theme.palette.button.main,
            backgroundColor: theme.palette.success.light,
        }
    },

}));
const DialogBox = styled(Dialog)(({ theme }) => ({
    "& .titleBoxDialogTwo": {
        display: "flex",
        justifyContent: "end",
        padding: "4px 8px",
        "& .MuiSvgIcon-root": {
            color: theme.palette.background.black,
            fontSize: 30,
        },
    },
    "& .active": {
        backgroundColor: "#edaeae",
    },

    "& .routeTextDialogTwo": {
        textAlign: "center",
        fontSize: 20,
        fontStyle: "italic",
    },
}));
export default function SubmitForm() {
    let location = useLocation();
    let navigate = useNavigate();
    const [state, setState] = useState({
        reason: '',
        note: '',
        followUp: '',
    });
    const [openDialogTwo, setOpenDialogTwo] = React.useState(false);
    // useEffect(() => {
    //     let Token = AuthService.getAuthDataByName("token") || "";
    //     if (Token === "") {
    //         navigate("/");
    //     }
    // }, [navigate])
    const handleSubmit = async () => {
        if (state.reason.length < 2 || state.note.length < 2 || state.followUp.length < 2) {
            setOpenDialogTwo(true)
        } else {
            let { routeID, mealID, seniorID } = location.state;
            let reason = state.reason;
            let note = state.note;
            let followUp = state.followUp;
            let data = { reason, note, followUp, routeID, seniorID, mealID }
            let response = await CancelSignature(data)
            if (response.success) {
                navigate(-1)
            }
        }
    }
    // ======== Dialog  =======
    const handleCross2 = () => {
        setOpenDialogTwo(false);
    };
    return (
        // <DefaultLayout>
        <Root p={2} >
            {/* Header */}
            <Box className="header-cont">
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static">
                        <Toolbar
                            sx={{ width: "100%", maxWidth: "100%", padding: "0px 15px 0px 45px !important" }}>
                            <Box className="headerNav">
                                <Box
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2, display: "inline-flex", alignItems: "center" }}>

                                    <img src={require('../../Assets/icons/msc_heart.png')} alt="Logo" style={{ marginRight: 10 }} />
                                    <div>
                                        <Typography color="black" className="pageMainTitle" id="pageTitle">
                                            My Senior Center
                                        </Typography>
                                        <Typography color="black" className="headerText" id="pageTitle">
                                            Meals
                                        </Typography>
                                    </div>
                                </Box>

                            </Box>
                        </Toolbar>
                    </AppBar>
                </Box>
            </Box>
            <Box className="mainContainer">
                <Box className="formContent">
                    <Box>
                        <TextField id="outlined-basic" className="btn" label="Reason" onChange={(e) => { setState({ ...state, reason: e.target.value }) }} placeholder='Type in Text' variant="outlined" />
                    </Box>
                    <Box >
                        <TextField sx={{}} className="btn" placeholder="Notes" label="Note" multiline rows={6} onChange={(e) => { setState({ ...state, note: e.target.value }) }} />
                    </Box>
                    <Box>
                        <TextField id="outlined-basic" label="Follow up" className="btn" placeholder='Type in Text' onChange={(e) => { setState({ ...state, followUp: e.target.value }) }} variant="outlined" />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: 1 }}>
                        <Button className='outlineButton' onClick={() => { navigate(-1) }} variant='outlined' >Cancel</Button>
                        <Button className='containedButton' variant='contained' onClick={handleSubmit}>Submit</Button>
                    </Box>
                </Box>
            </Box>
            {/* Dialog  */}
            <DialogBox
                open={openDialogTwo}
                onClose={handleCross2}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box className="titleBoxDialogTwo">
                    <IconButton onClick={handleCross2}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Divider />
                <DialogContent>
                    <Box className="routeBoxInner">
                        <Box className="routeTextDialogTwo"> <Typography variant="p"> Fill The Form</Typography> </Box>
                    </Box>
                </DialogContent>
            </DialogBox>
        </Root>
        // </DefaultLayout>
    )
}