import React, { useEffect, useRef, useState } from 'react';
import { BrowserMultiFormatReader, NotFoundException, BarcodeFormat } from '@zxing/library';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { AppBar, Toolbar, Typography } from '@mui/material';
import AuthService from '../Services/AuthService';
import { useNavigate } from 'react-router-dom';

const Root = styled(Box)(({ theme }) => ({
    height: '100%',
    padding: 0,

    '& .header-cont': {
        // width: "100%",
        width: "device-width",
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        "& header": {
            display: "flex",
            height: 48,
            background: "white",
            fontSize: 12,
            alignItems: "center",
            '& img[alt="Logo"]': {
                width: 34,
                filter: 'invert(1)',
            },
            "& .headerNav": {
                // marginRight: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                "& .fuel": {
                    color: "red",
                    position: "absolute",
                    width: "26px",
                    right: "62px",
                    top: "12px",
                    cursor: "pointer"
                },
                "& .pageMainTitle": {
                    fontSize: 15,
                    fontWeight: 600,
                    whiteSpace: "nowrap"
                },
                "& .headerText": {
                    display: "flex",
                    color: '#1f0757',
                    fontSize: 13,
                    textShadow: '#1f0757',
                    fontWeight: 600,
                    whiteSpace: "nowrap"
                }
            }
        },
    },
    '& .mainContainer': {
        backgroundImage: `url(${require('../Assets/loginBackgroundImg/img1.png')})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor: "#f8e9f7",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 48px)",
        marginTop: 48,
        alignItems: "center",
        justifyContent: "center",
        "& .resultText": {
            display: "flex",
            justifyContent: "center",
            marginTop: 10
        },
        "& .CodeScanner": {
            width: "100%",
            height: "100%",
            objectFit: "fill",
        },
        "& .StopScanButton": {
            margintop: "20px"
        },
        "& .loader": {
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
            marginTop: '48px',
        },
    },
}));
const { GS1_DATABAR, MAXICODE } = BarcodeFormat;
function Scanner() {
    const videoRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    let scanning = true;
    let navigate = useNavigate()
    // useEffect(() => {
    //     let Token = AuthService.getAuthDataByName("token") || "";
    //     if (Token === "") {
    //         navigate("/");
    //     }// eslint-disable-next-line
    // }, [])
    useEffect(() => {
        let codeReader;

        async function setupScanner() {
            try {

                await navigator.mediaDevices.getUserMedia({ video: true });
                setIsLoading(false)
                if (scanning) {
                    codeReader = new BrowserMultiFormatReader();
                    if (!videoRef.current.paused) {
                        videoRef.current.pause();
                    }
                    codeReader.decodeFromVideoDevice(null, videoRef.current.id, (result, error) => {
                        if (result) {
                            // handle scan data
                            // setResult(result.getText());
                        }
                        if (error instanceof NotFoundException) {
                            // console.log('No barcode found.');
                        } else if (error) {
                            console.error(error);
                        }
                    }, ['upc_a', 'upc_e', 'ean_8', 'ean_13', 'code_39', 'code_93', 'code_128', 'codabar', 'itf', 'rss14', 'rss_expanded', 'qr_code', 'data_matrix', 'aztec', 'pdf_417', GS1_DATABAR, MAXICODE]);
                }
            } catch (err) {
                setIsLoading(false)
                if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
                    alert("Please allow camera permission to use the scanner.");
                } else {
                    alert(err);
                }
            }
        }

        setupScanner();

        return () => {
            if (codeReader) {
                codeReader.reset();
            }
        };
    }, [scanning]);

    return (
        <Root >
            {/* Header */}

            <Box className="header-cont">

                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static">
                        <Toolbar
                            sx={{ width: "100%", maxWidth: "100%", padding: "0px 15px 0px 45px !important" }}>
                            <Box className="headerNav">
                                <Box
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2, display: "inline-flex", alignItems: "center" }}>

                                    <img src={require('../Assets/icons/msc_heart.png')} alt="Logo" style={{ marginRight: 10 }} />
                                    <div>
                                        <Typography color="black" className="pageMainTitle" id="pageTitle">
                                            My Senior Center
                                        </Typography>
                                        <Typography color="black" className="headerText" id="pageTitle">
                                            Scanner
                                        </Typography>
                                    </div>
                                </Box>

                            </Box>
                        </Toolbar>
                    </AppBar>
                </Box>
            </Box>
            <Box className="mainContainer">
                {isLoading ? <Box className="loader">
                    <img src={require('../Assets/images/videoLoader.gif')} alt='logo' />
                </Box> : ""}
                <video id="video" playsInline className='CodeScanner' ref={videoRef} />
            </Box>
        </Root>

    );
}
export default Scanner;
