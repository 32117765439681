import { Alert, AppBar, Avatar, Badge, Box, Button, CircularProgress, Snackbar, Toolbar, Typography, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { AssignWorkRequests } from '../../Services/Api/VolunteerService';
const Root = styled(Box)(({ theme }) => ({
    '& .header-cont': {
        width: "100%",
        width: "device-width",
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        "& header": {
            display: "flex",
            height: 48,
            background: "white",
            fontSize: 12,
            alignItems: "center",
            '& img[alt="Logo"]': {
                width: 28,
                // filter: 'invert(1)',
            },
            "& .HeaderScan": {
                width: "100%",
                maxWidth: "100%",
                paddingLeft: "38px",
                paddingRight: "8px",
                [theme.breakpoints.down('md')]: {
                    padding: "0px 8px 0 40px",
                },
            },
            "& .headerNav": {
                // marginRight: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                position: "relative",
                "& .fuel": {
                    color: "red",
                    position: "absolute",
                    width: "26px",
                    right: "62px",
                    top: "12px",
                    cursor: "pointer"
                },
                "& .pageMainTitle": {
                    fontSize: 16,
                    fontWeight: 600,
                    whiteSpace: "nowrap"
                },
                "& .calenderIcon": {
                    [theme.breakpoints.down('sm')]: {
                        display: "none"
                    },
                },
                "& .headerText": {
                    display: "flex",
                    color: 'grey',
                    fontSize: 13,
                    textShadow: '#1f0757',
                    fontWeight: 500,
                    whiteSpace: "nowrap"
                },
                "& .searchInput": {

                },

            }
        },
    },
    '& .main_container': {
        minHeight: "100vh",
        marginTop: "40px",
        padding: "10px 20px",
        backgroundColor: theme.palette.type == 'dark' ? '#343945' : "#FBFBFD",

    },
    "& .backIcon": {
        color: theme.palette.type == 'dark' ? '#FBFBFD' : "black",
        fontSize: "30px",
        marginLeft: "-5px"

    },
    "& .avatarContainer": {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        "& .name": {
            color: theme.palette.type == 'dark' ? '#fff' : "#000",
            fontWeight: 500
        },
        "& .cameraIcon": {
            width: "30px",
            height: "30px",
            backgroundColor: theme.palette.type == 'dark' ? '#2196f3' : "grey",
            color: "#fff",
            borderRadius: '50%',
            padding: "5px"
        }
    },
    "& .detail_container": {
        backgroundColor: "#FFFFFF",
        padding: "8px",
        border: "1px solid #c4c4c4",
        borderRadius: "5px",
        "& .items": {
            display: "flex",
            justifyContent: "space-between",
            padding: "8px 0px"
        },
        "& .typo_text": {
            fontSize: "16px"
        }
    },
    "& .actionButtonContainer": {
        width: "100%",
        // border: "2px solid black",
        borderRadius: '10px 10px 0px 0px',
        backgroundColor: theme.palette.type == 'dark' ? '#343945' : "#FBFBFD",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "26px",

        "& .actionBtn": {
            width: "100%",
            padding: "10px 10px",
            fontSize: "20px",
            borderRadius: "100px"

        },
        "& .danger": {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.other.text,
        },
        "& .success": {
            backgroundColor: theme.palette.type == "dark" ? "#149C48" : "#149C48",
            color: "#ffff",
        },
    }
}))

export const SeniorProfile = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [loader, setLoader] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const getCustomRelativeTime = (pickupDateTime) => {
        const now = moment();
        const diff = pickupDateTime.diff(now);

        if (diff < 0) {
            return `Left ${moment.duration(-diff).humanize()} ago`;
        }
        return `Leave in ${moment.duration(diff).humanize()}`;
    };

    const pickupDateTime = moment(`${state?.dateKey} ${state?.data?.metaData?.PickupTime}`, 'YYYY-MM-DD hh:mm A');
    const relativePickupTime = getCustomRelativeTime(pickupDateTime)

    const handleAcceptRides = async () => {
        let WorkRequestIDs = [state?.data?.metaData?.ID];
        try {
            setLoader(true)
            const response = await AssignWorkRequests({ WorkRequestIDs });
            if (response?.success) {
                setOpenAlert(true)
                navigate("/volunteer")
                setLoader(false)
            } else {
                alert(response?.message)

                setLoader(false)
            }
        } catch (error) {
            console.log(error);
            setLoader(false)
        }
    }
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    };
    return (
        <>
            <Root>
                <Box className="header-cont" >
                    <AppBar position="static">
                        <Toolbar
                            className='HeaderScan'
                            sx={{}}>
                            <Box className="headerNav">
                                <Box
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <img src={require('../../Assets/heartBlack.png')} alt="Logo" style={{ marginRight: 10 }} />
                                        {/* <Typography color="black" className="pageMainTitle" id="pageTitle"> Volunteer </Typography> */}
                                        <div>
                                            <Typography color="black" className="pageMainTitle" id="pageTitle">
                                                Acton Community Center
                                            </Typography>

                                            <Typography variant="h6" component="div" className="headerText">Bill Henderson</Typography>
                                        </div>
                                    </Box>

                                </Box>
                            </Box>
                        </Toolbar>
                    </AppBar>
                </Box>
                <Box className="main_container">
                    <Box mt={2}>
                        <ArrowBackIosIcon className='backIcon' onClick={() => navigate(-1)} />
                    </Box>
                    <Box className="avatarContainer" mt={0}>
                        <Badge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}

                        >
                            <Avatar sx={{ width: '140px', height: '140px' }} alt="Travis Howard" src={require('../../Assets/images/greyCircle.png')} />
                        </Badge>
                        <Typography mt={1} className='name' variant={'h2'}>{state?.data?.metaData?.SeniorName}</Typography>
                        {
                            state?.data?.status == "accepted" &&
                            <Typography mt={0.5} className='name' variant={'body2'}>{state?.isToday ? relativePickupTime : ""}</Typography>
                        }
                        <Typography mt={0} className='name' variant={'body2'}>{state?.dateKey ? moment(state?.dateKey).format('MMMM D, YYYY') : "----"}</Typography>
                    </Box>
                    <Box mt={3} className='detail_container'>
                        <Box className="items"><Typography variant='body2' className='typo_text'>Pick Up Time</Typography><Typography variant='body2' className='typo_text'>{state?.data?.metaData?.PickupTime ? state?.data?.metaData?.PickupTime : "----"}</Typography></Box>

                        <Box className="items"><Typography variant='body2' className='typo_text'>Drop Off Time</Typography><Typography variant='body2' className='typo_text'>{state?.data?.metaData?.DropOffTime ? state?.data?.metaData?.DropOffTime : "----"}</Typography></Box>

                        <Box className="items"><Typography variant='body2' className='typo_text'>Pick Up</Typography><Typography variant='body2' className='typo_text'>{state?.data?.metaData?.PickUpLocationName ? state?.data?.metaData?.PickUpLocationName : "----"}</Typography></Box>

                        <Box className="items"><Typography variant='body2' className='typo_text'>Drop Off</Typography><Typography variant='body2' className='typo_text'>{state?.data?.metaData?.DropOffLocationName ? state?.data?.metaData?.DropOffLocationName : "----"}</Typography></Box>

                        <Box className="items"><Typography variant='body2' className='typo_text'>Ride Duration Minutes</Typography><Typography variant='body2' className='typo_text'>{state?.data?.metaData?.RideDurationMinutes ? state?.data?.metaData?.RideDurationMinutes + " Minutes" : "----"} </Typography></Box>
                    </Box>

                    <Box className="actionButtonContainer">
                        {
                            state?.data?.status == "accepted" ?
                                "" :
                                <Button onClick={handleAcceptRides} className='actionBtn success' startIcon={loader ? <CircularProgress size={20} sx={{ color: "white" }} /> : ""} >
                                    Accept Ride
                                </Button>
                        }
                    </Box>
                </Box>
                <Snackbar
                    open={openAlert}
                    autoHideDuration={5000}
                    onClose={handleCloseAlert}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    sx={{ marginTop: "41px" }}
                >
                    <Alert
                        severity="success"
                        variant="filled"
                        sx={{ width: '100%', backgroundColor: '#232425' }}
                        icon={false} // Remove the tick icon
                    >
                        Ride Accepted Successfully!
                    </Alert>
                </Snackbar>
            </Root>

        </>
    )
}
