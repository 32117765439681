import React, { useEffect, useState } from 'react';
import { GetRoutes, GetAllSeniors, GetSeniorCount } from '../../Services/Api/MealService';
import { AppBar, Box, CircularProgress, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import Helpers from '../../Shell/Helper';
const Root = styled(Box)(({ theme }) => ({
  height: '100%',
  padding: 0,
  '& .header-cont': {
    // width: "100%",
    width: "device-width",
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    "& header": {
      display: "flex",
      height: 48,
      background: "white",
      fontSize: 12,
      alignItems: "center",
      '& img[alt="Logo"]': {
        width: 34,
        filter: 'invert(1)',
      },
      "& .headerNav": {
        // marginRight: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        "& .fuel": {
          color: "red",
          position: "absolute",
          width: "26px",
          right: "62px",
          top: "12px",
          cursor: "pointer"
        },
        "& .pageMainTitle": {
          fontSize: 16,
          fontWeight: 600,
          whiteSpace: "nowrap",
          width: "260px",
          overflow: "hidden",
          textOverflow: "ellipsis"
        },
        "& .headerText": {
          display: "flex",
          color: '#1f0757',
          fontSize: 13,
          textShadow: '#1f0757',
          fontWeight: 600,
          whiteSpace: "nowrap"
        }
      }
    },
  },
  '& .mainContainer': {
    backgroundImage: `url(${require('../../Assets/loginBackgroundImg/img1.png')})`,
    backgroundSize: "cover",
    overflow: "hidden",
    backgroundPosition: "center",
    backgroundColor: "#f8e9f7",
    height: "calc(100vh - 48px)",
    marginTop: 48,
    overflowY: "auto",
    "& .routeTextNull": {
      height: "80vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    "& .routeBox": {
      margin: "20px auto ",
      display: "flex",
      width: "100",
      minWidth: 310,
      gap: 10,
      backgroundColor: theme.palette.primary.contrastText,
      padding: "10px",
      boxShadow: "0px 3px 15px -6px rgb(0 0 0 / 50%)",
      borderRadius: 10,
      "& .routeText": {
        display: "flex",
        flexDirection: "column",
      },
      "& .routetextColor": {
        fontSize: 20,
        fontWeight: 400,
        color: theme.palette.other.main
      },
      "& .textColor": {
        fontSize: 16,
        fontWeight: 300,
        color: theme.palette.primary.dark
      }
    },
  },
  '& img[alt="heartpng"]': {
    width: 50,
  },
}));
export default function Meal() {
  const [appInfo, setAppInfo] = useState({});
  const [state, setState] = useState({
    routes: [],
    seniorId: [],
    seniorsList: null,
  })
  const [loader, setLoader] = useState(false);
  const [countLoader, setCountLoader] = useState(true)
  let navigate = useNavigate();
  useEffect(() => {
    if (state.seniorsList === null) {
      GetInitialDetails();
    }
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    let appInfo = Helpers.getAppData()
    if (appInfo) {
      setAppInfo(appInfo);
    } else {
      setAppInfo({ centerName: "My Senior Center", centerLogURI: "/Assets/icons/msc_heart.png" });
    }
  }, [])
  const AddDetail = (id) => {
    localStorage.setItem("MealId", id);
    navigate("/routesenior", { state: id })
  }
  const GetInitialDetails = async () => {
    // debugger
    setLoader(true)
    let routes = await GetRoutes();
    let routesData = routes.data;
    // let seniorsList = GetAllSeniors();
    // let seniorsListData = seniorsList.data;
    // setState({ ...state, routes: routesData, seniorsList: seniorsListData })
    setState({ ...state, routes: routesData })
    setLoader(false)

    // setCountLoader(true)
    await GetSeniorCount(routesData);
    let seniorsList = GetAllSeniors();
    let seniorsListData = seniorsList.data;
    setState({ ...state, routes: routesData, seniorsList: seniorsListData })
    setCountLoader(false)

  }
  useEffect(() => {
    // let Token = AuthService.getAuthDataByName("token") || "";
    // if (Token === "") {
    //   navigate("/");
    // }// eslint-disable-next-line
  }, [])
  const getSeniorsCount = (routeId) => {
    let count = 0;
    count = state.seniorsList?.filter((f) => f.RouteID === routeId)?.length;
    console.log(state.seniorsList, "<-- senior lists")
    return count;
  };
  // const getSeniorsCount = useCallback((routeId) => {
  //   return state.seniorsList?.filter((f) => f.RouteID === routeId)?.length || 0;
  // }, [state.seniorsList]);

  return (

    <Root p={2} >
      {/* Header */}
      <Box className="header-cont">
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static">
            <Toolbar
              sx={{ width: "100%", maxWidth: "100%", padding: "0px 15px 0px 45px !important" }}>
              <Box className="headerNav">
                <Box
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2, display: "inline-flex", alignItems: "center" }}>
                  {appInfo?.centerLogURI ?
                    <img src={appInfo.centerLogURI}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = require("../../Assets/icons/msc_heart.png");
                      }}
                      alt="Logo" style={{ marginRight: 10 }}
                    /> : <img src={require(`../../Assets/icons/msc_heart.png`)} alt="Logo" style={{ marginRight: 10 }}
                    />}
                  <div>
                    <Typography color="black" className="pageMainTitle" id="pageTitle">
                      {appInfo.centerName}
                    </Typography>
                    <Typography color="black" className="headerText" id="pageTitle">
                      Meals
                    </Typography>
                  </div>

                </Box>

              </Box>
            </Toolbar>
          </AppBar>
        </Box>
      </Box>
      <Box className="mainContainer">
        {loader ?
          <Box className="routeTextNull">
            <Typography className='routetextColor' variant='h1'>
              <CircularProgress sx={{ color: "black" }} />
            </Typography>
          </Box>

          :
          (state?.routes?.length > 0) ? (
            state.routes.map((item, index) => (
              <Box key={index} sx={{ margin: "4px 10px", cursor: 'pointer' }} onClick={() => { AddDetail(item.RouteID) }}>
                <Box className="routeBox">
                  <Box className="image">
                    <img width={50} src={require('../../Assets/images/route-icon.png')} alt="heartpng" />
                  </Box>
                  <Box className="routeText">
                    <Typography className='routetextColor' variant='h1'>{item.Name}</Typography>
                    {
                      countLoader ? <Box mt={0.6}><PulseLoader margin={5} size={8} color='grey' /></Box> :
                        <Typography className='textColor' variant='h5'>{(getSeniorsCount(item?.RouteID) ? getSeniorsCount(item?.RouteID) : 0) + (getSeniorsCount(item?.RouteID) > 1 ? ' seniors' : ' senior')}</Typography>
                    }
                  </Box>
                </Box>
              </Box>
            ))
          ) : (
            <Box className="routeTextNull">
              <Typography className='routetextColor' variant='h1'>No Routes Found</Typography>
            </Box>
          )
        }



      </Box>
    </Root>

  )
}