import React, { useEffect } from 'react';
import { Box, Fab, Typography } from '@mui/material';
import DefaultLayout from '../Layouts/DefaultLayout';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import MailIcon from '@mui/icons-material/Mail';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { useNavigate } from 'react-router-dom';
import AuthService from '../Services/AuthService';

const Root = styled(Box)(({ theme }) => ({
  height: '100%',
  padding: 0,
  '& .mainContainer': {
    backgroundImage: `url(${require('../Assets/loginBackgroundImg/img1.png')})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: "#f8e9f7",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    height: "calc(100vh - 104px)",
    overflowY: "auto",
    marginTop: "48px",
    "& .content": {
      display: "inline-flex",
      flexDirection: 'column',
      gap: "34px",
      alignItems: "center",
      height: "100%",
      justifyContent: "center",
      "& .text": {
        padding: "0 5px",
        textAlign: 'center',
        "& .header": {
          fontSize: 52,
          color: theme.palette.other.text,
        }
      },
      "& .btn2": {
        borderRadius: 20,
        fontSize: 18,
        background: theme.palette.success.light,
        borderColor: theme.palette.success.light,
        maxWidth: "70%",
      },
      "& .btn": {
        borderRadius: 20,
        fontSize: 18,
        background: theme.palette.button.main,
        borderColor: theme.palette.success.light,
        maxWidth: "70%",
      },
      "& .versionBtn": {
        fontSize: 18,
        backgroundColor: '#00807C',
        "&:hover": {
          fontSize: 18,
          backgroundColor: '#048b86',
        },
      },
      '& img[alt="heartpng"] ': {
        display: "flex",
        width: 105,
        animation: " heartbeat 1.2s infinite",
      },

      "@keyframes heartbeat": {
        "0%": {
          transform: "scale(1)",
        },
        "25%": {
          transform: "scale(0.9)",
        },
        "60%": {
          transform: "scale(1.1)",
        },
        "100%": {
          transform: "scale(1)",
        }
      }
    },
    "& .cameraIcon": {
      position: "absolute",
      bottom: 75,
      right: 10,
      background: '#00807C',
      height: 56,
      width: 56
    }
  }
}))

export default function Home() {
  let navigate = useNavigate();
  const HandleMail = () => {
    window.location.href = "mailto:support@myseniorcenter.com?subject=Feedback - Meals 2022 - PWA App&amp;body=Dear MySeniorCenter Support,%0D %0D";
  }
  useEffect(() => {
    let Token = AuthService.getAuthDataByName("token") || "";
    if (Token === "") {
      navigate("/");
    }
  }, [navigate])
  return (
    <DefaultLayout>
      <Root p={2} >
        <Box className="mainContainer">
          <Box className="content">
            <Box > <img src={`${window.location.origin}/images/logo.png`} alt="heartpng" /> </Box>
            <Box className="text">
              <Typography className='header' variant="h2">Welcome!</Typography>
              <Typography sx={{ fontSize: 18 }} variant="p">My Senior Center - Meal Signature Beta Access! Don't let this app suck; give us feedback.</Typography>
            </Box>
            <Button onClick={HandleMail} className="btn" fullWidth variant="contained" startIcon={<MailIcon />}>Give Feedback</Button>
            <Button className="btn2" fullWidth variant="contained" startIcon={<AccessibilityNewIcon />}>Event Register</Button>
            <Button className='versionBtn' variant="contained">Version 1.2.1</Button>
          </Box>
          <Fab className='cameraIcon' color="primary" aria-label="add">
            <CameraAltIcon />
          </Fab>
        </Box>
      </Root>
    </DefaultLayout>
  )
}