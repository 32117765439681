import React from 'react'
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
const Root = styled(Box)(({ theme }) => ({}))
function Photos() {
    return (
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <img src={require('../../Assets/images/no-list-found.png')} width={"30%"} alt='nodata' />
            <Typography sx={{ textAlign: "center", fontWeight: 600 }} variant='body2'>Check back soon; images will be shown here.</Typography>
        </Box>
    )
}

export default Photos