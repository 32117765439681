import React, { useEffect, useState } from 'react'
import { AppBar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Toolbar, Typography } from '@mui/material'
import { styled } from '@mui/material/styles';
import AuthService, { GetAppName } from '../Services/AuthService';
import { useAppContext } from '../Shell/AppContext';
import { useNavigate } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CloseIcon from '@mui/icons-material/Close';
import constant from '../Shell/Constant';
const Root = styled(Box)(({ theme }) => ({
    height: '100%',
    padding: 0,
    "& .MainTitle": {
        fontSize: 15,
        fontWeight: 600,
        whiteSpace: "nowrap"
    },
    "& .subTitle": {
        fontSize: 15,
        fontWeight: 500,
        whiteSpace: "nowrap"
    },
    '& .header-cont': {
        // width: "100%",
        width: "device-width",
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        background: "white",
        "& header": {
            display: "flex",
            height: 48,
            background: "white",
            fontSize: 12,
            alignItems: "center",
            "& .headerNav": {
                marginRight: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",


            }
        },
    },
    '& .mainContainer': {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflowY: "auto",
        height: "calc(100vh - 48px)",
        marginTop: 48,
        "& .content": {
            padding: "10px",
            width: "100%",
            "& .infoCard": {
                padding: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: "fit-content",
                border: "1px solid #ccc",
                borderRadius: "5px",
                gap: "10px",
                marginBottom: "10px",
                "& .image": {
                    maxWidth: "50px",
                    maxHeight: "50px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "& img": {
                        filter: "invert(1)",
                    }
                },
                "& .themeChanger": {
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                },
                "& .clear_cache_icon": {
                    cursor: "pointer",
                }
            },


        },
        "& .version": {
            textAlign: "center",
            marginBottom: "20px"

        }
    },

}));

function Settings() {
    const [appName, setAppName] = useState('');
    const [logo, setLogo] = useState('');
    const [version, setVersion] = useState("1.1.42");
    const [tagId, setTagId] = useState("XAVUS_CHRISCROSSV6");
    const [mode, setMode] = useState("");
    const [cachePopup, setCachePopup] = useState(false);
    const host = window.location.hostname;
    const { data } = useAppContext();
    let navigate = useNavigate()

    const senioNotification = [
        {
            name: "Beep",
            path: `${require('../Assets/audio/beep.mp3')}`
        },
        {
            name: "Wrong",
            path: `${require('../Assets/audio/bit-wrong.mp3')}`
        },
        {
            name: "animation",
            path: `${require('../Assets/audio/animation.mp3')}`
        },
        {
            name: "bouncy-sound",
            path: `${require('../Assets/audio/bouncy-sound.mp3')}`
        },
        {
            name: "error1",
            path: `${require('../Assets/audio/error1.mp3')}`
        },
        {
            name: "error-2",
            path: `${require('../Assets/audio/error-2.mp3')}`
        },
    ]
    const mealNotification = [
        {
            name: "greanpatch",
            path: `${require('../Assets/audio/greanpatch.mp3')}`
        },
        {
            name: "invalid-selection",
            path: `${require('../Assets/audio/invalid-selection.mp3')}`
        },
        {
            name: "menu-button",
            path: `${require('../Assets/audio/menu-button.mp3')}`
        },
        {
            name: "menu-button1",
            path: `${require('../Assets/audio/menu-button1.mp3')}`
        },
        {
            name: "menu-change",
            path: `${require('../Assets/audio/menu-change.mp3')}`
        },
        {
            name: "menu-click",
            path: `${require('../Assets/audio/menu-click.mp3')}`
        },
        {
            name: "message-incoming",
            path: `${require('../Assets/audio/message-incoming.mp3')}`
        },
        {
            name: "notifications",
            path: `${require('../Assets/audio/notifications.mp3')}`
        },
    ]
    const driverNotification = [
        {
            name: "ping",
            path: `${require('../Assets/audio/ping.mp3')}`
        },
        {
            name: "score-main",
            path: `${require('../Assets/audio/score-main.mp3')}`
        },
        {
            name: "select_denied",
            path: `${require('../Assets/audio/select_denied.mp3')}`
        },
        {
            name: "select_denied1",
            path: `${require('../Assets/audio/select_denied1.mp3')}`
        },
        {
            name: "select-sound",
            path: `${require('../Assets/audio/select-sound.mp3')}`
        },
        {
            name: "system-error",
            path: `${require('../Assets/audio/system-error.mp3')}`
        },
        {
            id: 21,
            name: "ui_correct",
            path: `${require('../Assets/audio/ui_correct.mp3')}`
        },

    ]
    useEffect(() => {
        if (data?.themeMode === undefined && localStorage.getItem("selectedMode") === null) {
            setMode("Auto")
            localStorage.setItem("selectedMode", "auto")
        } else {
            if (data?.themeMode === "auto" || localStorage.getItem("selectedMode") === "auto") {
                setMode("Auto")
            } else if (data?.themeMode === "dark" || localStorage.getItem("selectedMode") === "dark") {
                setMode("Dark")
            } else {
                setMode("Light")
            }
        }
    }, [])
    // app name
    useEffect(() => {
        setVersion(localStorage.getItem('APP_VERSION'))
        setTagId(AuthService.getAuthDataByName("deviceTagID"));
        let tagid = AuthService.getAuthDataByName("deviceTagID")
        if (appName === "My Senior Center" && tagid !== null) {
            GetApplicationName(tagid);
        }
        setLogo(AuthService.getAppDataByName("Logo"));
        let applicationName = AuthService.getAppDataByName("AppName");
        if (applicationName === null || applicationName === "") {
            setAppName("My Senior Center");

        } else {
            setAppName(AuthService.getAppDataByName("AppName"));
        }
    }, [])
    const GetApplicationName = async (id) => {
        await GetAppName(id)
            .then((res) => {
                let data = res.data
                if (Object.keys(data).length && data !== null) {
                    AuthService.setAppData("AppName", res.data.Name)
                    AuthService.setAppData("Logo", res.data.Logo)
                } else {
                    AuthService.setAppData("AppName", "My Senior center")
                }
            })
            .catch((err) => {
                console.log(err)
                AuthService.setAppData("AppName", "My Senior center")
            })
    }
    setTimeout(() => {
        setLogo(AuthService.getAppDataByName("Logo"));
        setAppName(AuthService.getAppDataByName("AppName"));
    }, 1000)

    const handleThemeChange = () => {
        navigate("/theme")
    }
    const handleNotification = (id) => {
        navigate("/notify", { state: id })
    }
    const handleCache = () => {
        localStorage.removeItem("handleCache")
        localStorage.removeItem("selectedSchedule")
        localStorage.removeItem("selectedItems")
        alert("Cache cleared successfully");
        setCachePopup(false)
    }

    return (
        <Root p={2} >
            <Box className="header-cont">
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static">
                        <Toolbar sx={{ width: "100%", maxWidth: "100%", padding: "0px 15px 0px 45px !important" }}>
                            <Box className="headerNav">
                                <Box
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2, display: "inline-flex", alignItems: "center", color: "black" }}>
                                    <Typography className="MainTitle"  >Settings</Typography>
                                </Box>
                            </Box>
                        </Toolbar>
                    </AppBar>
                </Box>
            </Box>
            <Box className="mainContainer">
                <Box className="content">
                    <Box className='infoCard'>
                        <Box display={"flex"} alignItems={"center"}>
                            <Box className="image">
                                {logo ? <img src={logo} alt="Logo" width="100%" height="100%" />
                                    :
                                    <img src={require('../Assets/icons/msc_heart.png')} sx={{}} alt="Logo" width="80%" height="80%" />}
                            </Box>
                            <Box>
                                {appName ? <Typography className="MainTitle" id="pageTitle">
                                    {appName}
                                </Typography> : <Typography className="MainTitle" id="pageTitle">
                                    My Senior Center
                                </Typography>}
                                <Typography className="subTitle">{tagId}</Typography>
                            </Box>
                        </Box>
                        {constant.mealsProtectedDomains.includes(host) &&
                            <Box>
                                <img className='clear_cache_icon' onClick={() => setCachePopup(true)} src={require("../Assets/icons/clearCache.png")} width={30} />
                            </Box>
                        }
                    </Box>
                    <Box className='infoCard' sx={{ cursor: "pointer" }} onClick={handleThemeChange} >
                        <Box className="themeChanger">
                            <Typography className="MainTitle">Theme</Typography>
                            <Typography className="subTitle">{mode}</Typography>
                        </Box>
                    </Box>
                    {(constant.driverProtectedDomains.includes(host) || constant.appDomain.includes(host)) &&

                        <Box className='infoCard' sx={{ cursor: "pointer" }} onClick={() => handleNotification("Driver")} >
                            <Box className="themeChanger">
                                <Typography className="MainTitle">Driver Notification</Typography>
                                <Typography className="subTitle"><NavigateNextIcon /></Typography>
                            </Box>
                        </Box>

                    }
                    {(constant.mealsProtectedDomains.includes(host) || constant.appDomain.includes(host)) &&
                        <Box className='infoCard' sx={{ cursor: "pointer" }} onClick={() => handleNotification("Meal")} >
                            <Box className="themeChanger">
                                <Typography className="MainTitle">Meal Notification</Typography>
                                <Typography className="subTitle"><NavigateNextIcon /></Typography>
                            </Box>
                        </Box>
                    }
                    {(constant.volunteerProtectedDomains.includes(host) || constant.appDomain.includes(host)) &&
                        <Box className='infoCard' sx={{ cursor: "pointer" }} onClick={() => handleNotification("Senior")} >
                            <Box className="themeChanger">
                                <Typography className="MainTitle">Senior Notification</Typography>
                                <Typography className="subTitle"><NavigateNextIcon /></Typography>
                            </Box>
                        </Box>
                    }
                    {(constant.mealsProtectedDomains.includes(host) || constant.appDomain.includes(host)) &&
                        <Box className='infoCard' sx={{ cursor: "pointer" }} onClick={() => navigate("/select-center")} >
                            <Box className="themeChanger">
                                <Typography className="MainTitle">Select Center</Typography>
                                <Typography className="subTitle"><NavigateNextIcon /></Typography>
                            </Box>
                        </Box>
                    }
                    {constant.appProtectedDomains.includes(host) &&
                        <Box className='infoCard' sx={{ cursor: "pointer" }} onClick={() => navigate("/cache")} >
                            <Box className="themeChanger">
                                <Typography className="MainTitle">Cache</Typography>
                                <Typography className="subTitle"><NavigateNextIcon /></Typography>
                            </Box>
                        </Box>
                    }
                </Box>
                <Box className='version'>
                    <Typography variant="h6">Version: {version}</Typography>
                </Box>
            </Box>

            <Dialog
                open={cachePopup}
                onClose={() => setCachePopup(false)}
                aria-labelledby="logout-alert-title"
                aria-describedby="logout-alert-description"
            >

                <DialogTitle id="logout-alert-title" sx={{ fontSize: "20px", paddingBottom: "8px", paddingLeft: "15px", paddingRight: "10px" }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", }}>
                        <Typography sx={{}}>Cache Clear Confirmation</Typography>
                        <CloseIcon onClick={() => setCachePopup(false)} />
                    </Box>

                </DialogTitle>
                <DialogContent sx={{ paddingBottom: "10x", paddingLeft: "15px" }}>
                    <Typography variant='body2' id="logout-alert-description">
                        Are you sure you want to clear the cache?
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ paddingLeft: "15px", paddingRight: "15px", borderTop: "1px solid #ccc", display: 'flex', justifyContent: "space-between" }}>
                    <Button sx={{ backgroundColor: "#000524", borderRadius: 30, color: "white" }} variant="contained" onClick={() => setCachePopup(false)} autoFocus>Cancel</Button>

                    <Button sx={{ backgroundColor: "#4caf50", borderRadius: 30, color: "white", "&:hover": { backgroundColor: "#4caf50" } }} variant="contained" onClick={handleCache} autoFocus>Yes, Clear Cache</Button>
                </DialogActions>
            </Dialog>
        </Root>
    )
}

export default Settings