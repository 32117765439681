import React, { useEffect, useState } from 'react';
import AuthService, { AuthByPhone } from '../../Services/AuthService';
import { Box, Button, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import phoneIcon from '../../Assets/icons/phone2.png'
import Helpers from '../../Shell/Helper';
import CircularProgress from '@mui/material/CircularProgress';
import Mask from '../../Components/Mask';
const Root = styled(Box)(({ theme }) => ({
    height: '100%',
    padding: 0,
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: "#c8c8c8",
        borderWidth: "1px",
    },
    '& .MuiInputBase-input': {
        fontWeight: "normal !important",
        fontSize: "18px",
        padding: "13.5px 14px"
    },
    '& .header-cont': {
        // width: "100%",
        width: "device-width",
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        "& header": {
            display: "flex",
            height: 48,
            background: "white",
            fontSize: 12,
            alignItems: "center",
            '& img[alt="Logo"]': {
                width: 34,
                filter: 'invert(1)',
            },
            "& .headerNav": {
                // marginRight: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                "& .fuel": {
                    color: "red",
                    position: "absolute",
                    width: "26px",
                    right: "62px",
                    top: "12px",
                    cursor: "pointer"
                },
                "& .pageMainTitle": {
                    fontSize: 15,
                    fontWeight: 600,
                    whiteSpace: "nowrap"
                },
                "& .headerText": {
                    display: "flex",
                    color: '#1f0757',
                    fontSize: 13,
                    textShadow: '#1f0757',
                    fontWeight: 600,
                    whiteSpace: "nowrap"
                }
            }
        },
    },
    '& .mainContainer': {
        backgroundImage: `url(${require('../../Assets/loginBackgroundImg/img1.png')})`,
        backgroundSize: "cover",
        overflow: "hidden",
        backgroundPosition: "center",
        backgroundColor: "#f8e9f7",
        height: "calc(100vh - 0px)",
        marginTop: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflowY: "auto",

        "& .heading": {
            fontSize: '28px',
            textAlign: "center",
            marginTop: "20px",
            fontWeight: "600",
            '@media (min-width:475px)': {
                fontSize: '32px'
            },
        },
        "& .content": {
            // border: "1px solid red",
            width: "100%",
            maxWidth: "560px",
            margin: "auto",
            position: "relative"
        },
        "& .routeTextNull": {
            height: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        "& .continueBtn": {
            borderRadius: "40px",
            padding: "10px 14px",
            margin: "20px 0px",
            background: "#4caf50",
            borderColor: "#4caf50",
            color: "#000000",
            fontSize: "18px"
        },
        "& .testBtn": {
            borderRadius: "40px",
            padding: "5px 8px",
            margin: "20px 0px",
            background: "#4caf50",
            borderColor: "#4caf50",
            color: "#000000",
            fontSize: "14px",
            position: "absolute",
            bottom: "10px",
            width: "60px",
        },
        "& .routeBox": {
            margin: "20px auto ",
            display: "flex",
            width: "100",
            minWidth: 310,
            gap: 10,
            backgroundColor: theme.palette.primary.contrastText,
            padding: "10px",
            boxShadow: "0px 3px 15px -6px rgb(0 0 0 / 50%)",
            borderRadius: 10,
            "& .routeText": {
                display: "flex",
                flexDirection: "column",
            },
            "& .routetextColor": {
                fontSize: 20,
                fontWeight: 400,
                color: theme.palette.other.main
            },
            "& .textColor": {
                fontSize: 16,
                fontWeight: 300,
                color: theme.palette.primary.dark
            }
        },
    },
    '& img[alt="heartpng"] ': {
        display: "flex",
        width: 105,
        animation: " heartbeat 1.2s infinite",
    },

    "@keyframes heartbeat": {
        "0%": {
            transform: "scale(1)",
        },
        "25%": {
            transform: "scale(0.9)",
        },
        "60%": {
            transform: "scale(1.1)",
        },
        "100%": {
            transform: "scale(1)",
        }
    },
}));
export default function PhoneVerification() {
    const hostName = window.location.hostname;
    const [phoneNumber, setPhoneNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [validNumber, setValidNumber] = useState("");
    let navigate = useNavigate();
    let datares = [
        {
            "tokenUID": "6bfcabe2-d03b-4652-a0b1-e48ba484af0e",
            "expiresUTC": "2024-11-05T11:05:42.507",
            "centerInfo": [
                {
                    "gsid": 705,
                    "centerName": "North Reading Department of Elder Affairs",
                    "centerLogURI": null,
                    "centerPhone": "978-664-5600"
                }
            ]
        },
        {
            "tokenUID": "510b7a5d-e90d-4a0b-a852-ecfaae926b85",
            "expiresUTC": "2024-11-06T13:00:55.527",
            "centerInfo": [
                {
                    "gsid": 1292,
                    "centerName": "Acton Community Center",
                    "centerLogURI": "https://myseniorcenter-tenants.s3.amazonaws.com/xavus-chriscross/media/c3b421ac-072c-499f-96de-b482d9d50329/Acton-Town-Hall-new-colors128.jpg",
                    "centerPhone": "419-959-5661"
                }
            ]
        },
        {
            "tokenUID": "13c058d7-00ac-482b-a61e-7060ae5c1853",
            "expiresUTC": "2024-10-28T15:02:45.24",
            "centerInfo": [
                {
                    "gsid": 570,
                    "centerName": "Hanover Township Aging Services",
                    "centerLogURI": "https://myseniorcenter-tenants.s3.amazonaws.com/il-bartlett/media/c846bfe7-629f-4222-ab91-0946e1dbbf80/IL_Bartlett128.jpg",
                    "centerPhone": "630-483-5600"
                }
            ]
        }
    ]

    useEffect(() => {
        AuthService.setUserData("userToken", datares[0]);
        AuthService.setUserData("gsid", datares[0].centerInfo[0]?.gsid);
        localStorage.setItem("centerData", JSON.stringify(datares))
    }, [])
    useEffect(() => {
        window.addEventListener('keypress', keypressHandler)
        if (window.location.href?.split("?")[1]?.includes("t=")) {
            AuthService.setUserData("userToken", { tokenUID: window.location.href.split("t=")[1], expiresUTC: "2024-12-28T16:15:52.887" });
            if (hostName === "mow.myseniorcenter.net") {
                let url = Helpers.trackWelcome();
                navigate(url)
            }
            else if (hostName === "app.myseniorcenter.net") {
                navigate("/app-senior")
            }
            else {

                navigate("/volunteer")
            }
        }
        handleAutoLogin();
        return () => {
            window.removeEventListener('keypress', keypressHandler)
        }

    }, [])
    const handleAutoLogin = () => {
        const userToken = AuthService.getAuthDataByName("userToken")
        if (userToken) {
            const expireDate = userToken?.expiresUTC;
            const token = userToken?.tokenUID
            if (new Date() > new Date(expireDate)) {
                localStorage.clear();
            } else {
                if (token) {
                    if (hostName === "mow.myseniorcenter.net") {
                        let url = Helpers.trackWelcome();
                        navigate(url)
                    }
                    else if (hostName === "app.myseniorcenter.net") {
                        navigate("/app-senior")
                    }
                    else {

                        navigate("/volunteer")
                    }
                }
            }
        }
    }
    const keypressHandler = (event) => {
        if (event.key === "Enter") {
            handleClick()
        }
    }
    const handleChange = (event) => {
        setPhoneNumber(event.target.value);
        // console.log(phoneNumber)
        const pattern = /^\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
        if (pattern.test(event.target.value)) {
            setValidNumber(true)
        } else {
            setValidNumber(false)
        }
    };

    const handleClick = async () => {
        // console.log(phoneNumber, "<-- phone number")
        // -----
        const number = Helpers.FormatToInternational(phoneNumber)
        // alert(`Phone Number: ${number}`)
        // console.log(number);
        // navigate("/otpverify")

        if (verifyPhoneNumber()) {
            setLoading(true)
            try {
                const response = await AuthByPhone(number);
                // alert(JSON.stringify(response))
                console.log(response, "<---response")
                if (response.success) {
                    localStorage.setItem("phoneNumber", number);
                    navigate("/otpverify")
                    setLoading(false)
                } else {

                    // navigate("/otpverify") //for testing purpose
                    alert("Invalid phone number. Please contact support for assistance..");
                    setLoading(false)

                }
            } catch (error) {
                console.log(error.message)
                setLoading(false)
            }
        } else {
            alert("Invalid phone number. Please contact support for assistance.");

        }

    }
    // const handleClickTest = async () => {
    //     console.log(phoneNumber, "<-- phone number")
    //     // -----
    //     const number = Helpers.FormatToInternationalTest(phoneNumber)
    //     alert(`Phone Number: ${number}`)
    //     console.log(number);


    //     if (verifyPhoneNumber()) {
    //         setLoading(true)
    //         try {
    //             const response = await AuthByPhone(number);
    //             alert(JSON.stringify(response))
    //             if (response.success) {
    //                 localStorage.setItem("phoneNumber", number);
    //                 navigate("/otpverify")
    //                 setLoading(false)
    //             } else {
    //                 alert("Please double-check the number and try again.");
    //                 setLoading(false)

    //             }
    //         } catch (error) {
    //             console.log(error.message)
    //             setLoading(false)
    //         }
    //     } else {
    //         alert("Please double-check the number and try again.");

    //     }

    // }
    function verifyPhoneNumber() {
        const pattern = /^\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
        return pattern.test(phoneNumber);
    }

    const getFallbackImage = (e) => {
        console.log(e);
    }
    return (

        <Root p={2} >
            {/* Header */}
            {/* <Box className="header-cont">
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static">
                        <Toolbar
                            sx={{ width: "100%", maxWidth: "100%", padding: "0px 15px 0px 45px !important" }}>
                            <Box className="headerNav">
                                <Box
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2, display: "inline-flex", alignItems: "center" }}>

                                    <img src={require('../../Assets/icons/msc_heart.png')} alt="Logo" style={{ marginRight: 10 }} />
                                    <div>
                                        <Typography color="black" className="pageMainTitle" id="pageTitle">
                                            My Senior Center
                                        </Typography>
                                        <Typography color="black" className="headerText" id="pageTitle">
                                            Meals
                                        </Typography>
                                    </div>
                                </Box>

                            </Box>
                        </Toolbar>
                    </AppBar>
                </Box>
            </Box> */}
            <Box className="mainContainer" p={5}>
                {/* <Button startIcon={loading ? <CircularProgress size={15} sx={{ color: "black" }} /> : ""} variant='contained' className='testBtn' sx={{ width: "100%", padding: "10px" }} onClick={handleClickTest}>Test</Button> */}

                <Box>
                    {/* <Stack> <ArrowBackRoundedIcon sx={{ cursor: "pointer" }} onClick={() => navigate(-1)} /> </Stack> */}

                    <Box sx={{ display: "flex", justifyContent: "center" }}> <img src={require("../../Assets/heartBlack.png")} alt="heartpng" onError={getFallbackImage} /> </Box>

                    <Typography color="black" className='heading' variant='h1' onClick={() => navigate("/settings")}>
                        Login
                    </Typography>
                    <Typography color="black" variant='subtitle2' textAlign={"center"} pt={1} >
                        Please enter your phone number to receive a verification code.
                    </Typography>
                    <Stack className='content'>
                        <Stack direction={'row'} alignItems={'center'} pt={5} spacing={2}>
                            <Box sx={{ padding: "7px 9px 3px 8px", borderRadius: "5px", backgroundColor: "#fff", border: "1px solid #c8c8c8" }}>
                                <img width={34} src={phoneIcon} alt="phone icon" />
                            </Box>


                            <TextField
                                type='text'
                                sx={{
                                    color: 'red',
                                    zIndex: 2, fontSize: 12, fontWeight: 200,
                                    "& .MuiOutlinedInput-root": {
                                        color: "black !important",
                                        borderRadius: "5px",
                                        backgroundColor: "#fff",
                                        "& fieldSet": {
                                            outline: "none !important",
                                        },
                                        "& .MuiInputLabel-outlined": {
                                            outline: "none !important",
                                            top: "3px !important",
                                        }
                                    },
                                }}
                                label={<Typography variant='body2' sx={{ color: "black" }} >Phone Number</Typography>}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            {validNumber ? (
                                                <DoneIcon sx={{ zIndex: 2, fontSize: "25px", fontWeight: 200 }} color='success' />
                                            ) : (
                                                phoneNumber && <CloseIcon sx={{ zIndex: 2, fontSize: "25px", fontWeight: 200 }} color='error' />
                                            )}
                                        </InputAdornment>
                                    ),
                                    inputComponent: Mask,
                                }}
                                value={phoneNumber}
                                onChange={handleChange}
                            />
                        </Stack>

                        <Button
                            startIcon={loading ? <CircularProgress size={20} sx={{ color: "black" }} /> : ""}
                            disabled={loading ? true : false}
                            variant='contained'
                            className='continueBtn'
                            sx={{ width: "100%", padding: "10px" }}
                            onClick={handleClick}
                        >Continue
                        </Button>

                    </Stack>
                </Box>

            </Box>
        </Root >

    )
}


// const Mask = (props) => {
//     const { value, onChange, ...otherProps } = props;

//     return (
//         <InputMask alwaysShowMask={false} mask="(999) 999-9999" value={value} onChange={onChange} {...otherProps} />
//     );
// };