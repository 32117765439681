import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import welcome from '../../Assets/images/Welcome.png';
import { useNavigate } from 'react-router-dom';
const Root = styled(Box)(({ theme }) => ({
    height: '100%',
    padding: 0,
    '& .mainContainer': {
        backgroundImage: `url(${require('../../Assets/loginBackgroundImg/img1.png')})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor: "#f8e9f7",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        height: "100vh",
        overflowY: "auto",
        padding: "20px",
        "& .content": {
            display: "inline-flex",
            flexDirection: 'column',
            gap: "34px",
            alignItems: "center",
            height: "100%",
            justifyContent: "center",
            "& .text": {
                padding: "0 5px",
                textAlign: 'center',
            },


        },
    },
    "& .nextBtn": {
        borderRadius: "40px",
        padding: "16px 14px",
        width: "88%",
        marginBottom: "24px",
        background: theme.palette.button.main,
        borderColor: theme.palette.button.main,
        color: theme.palette.background.black,
        fontSize: 18,
        fontWeight: 600,
        "&:hover": {
            background: theme.palette.button.main,
            borderColor: theme.palette.button.main,
            color: theme.palette.background.black,
        },
    },
}))
function Welcome() {
    const navigate = useNavigate();
    const handleNext = () => {
        navigate('/item')
    }
    return (
        <Root p={2} >
            <Box className="mainContainer">
                <Box className="content">
                    <Box > <img src={welcome} alt="welcome.png" width={"100%"} /> </Box>
                    <Box className="text">
                        <Typography sx={{}} variant="h3">"Welcome We're excited to have you on board."</Typography>
                    </Box>
                </Box>
                {/* <Button onClick={handleNext} fullWidth className="nextBtn" variant="contained" >Next</Button> */}
                <Box sx={{ position: "stick", display: "flex", justifyContent: "center" }}>
                    <Button className="nextBtn" onClick={handleNext} > Next </Button>
                </Box>
            </Box>
        </Root>
    )
}

export default Welcome