const AppSettings = {
    serverUrl: "https://api-mobile.myseniorcenter.net",
    // serverUrl: "https://api-test.myseniorcenter.net",
    // serverUrl: "https://core-mac-isitbroken.myactivecenter.com",
    // serverUrl: "https://core-kiosk-alpha.myseniorcenter.net",
    // serverUrl: "https://api-alpha.myseniorcenter.net",
    dynamicCacheName: "runtimeCache-rides-v0.1.5",
    version: "0.1",
};
export default AppSettings;
